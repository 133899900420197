import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation MyMutation(
    $email: String!
    $password: String!
    $captchaToken: String!
    $userGroup: RoleName!
  ) {
    signIn(
      signInInput: {
        email: $email
        password: $password
        captchaToken: $captchaToken
        userGroup: $userGroup
      }
    ) {
      expiredAt
      refreshToken
      token
      groups
    }
  }
`;

export const SET_PASSWORD = gql`
  mutation SetPassword($password: String!, $token: String!) {
    setUserPassword(password: $password, token: $token) {
      dynamicLink
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshTokenInput: { refreshToken: $refreshToken }) {
      token
      expiredAt
    }
  }
`;

export const CREATE_USER = gql`
  mutation CompanyCreateEndUser($data: CompanyCreateEndUserInput!) {
    companyCreateEndUser(data: $data)
  }
`;

export const CREATE_MEMBER = gql`
  mutation CompanyCreateMember($data: CompanyCreateMemberInput!) {
    companyCreateMember(data: $data)
  }
`;

export const CREATE_COMPANY = gql`
  mutation CompanyCreateMember($data: CreateCompanyInput!) {
    createCompany(data: $data)
  }
`;

export const RESEND_EMAIL = gql`
  mutation ResendEmail($ids: [String]) {
    resendVerifyEmail(ids: $ids)
  }
`;

export const UPDATE_USER = gql`
  mutation CompanyUpdateUser($data: UpdateUserInput!) {
    updateUser(data: $data)
  }
`;

export const UPDATE_COMPANY = gql`
  mutation UpdateCompany($data: UpdateCompanyInput!) {
    updateCompany(data: $data) {
      id
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($id: String!) {
    deleteUser(id: $id)
  }
`;

export const SEND_MAIL_FORGOT_PASSWORD = gql`
  mutation SendMailForgotPassword($email: String!) {
    sendMailForgotPassword(email: $email)
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword(
    $confirmPassword: String!
    $newPassword: String!
    $token: String!
  ) {
    forgotPassword(
      confirmPassword: $confirmPassword
      newPassword: $newPassword
      token: $token
    )
  }
`;

export const UPDATE_MEMBER = gql`
  mutation CompanyUpdateMember($data: CompanyUpdateMemberInput!) {
    companyUpdateMember(data: $data)
  }
`;

export const CREATE_DEPARTMENT = gql`
  mutation CreateCompanyDepartment($name: String!) {
    createCompanyDepartment(name: $name) {
      id
    }
  }
`;

export const UPDATE_DEPARTMENT = gql`
  mutation UpdateCompanyDepartment($data: UpdateCompanyDepartmentInput!) {
    updateCompanyDepartment(data: $data) {
      id
    }
  }
`;

export const DELETE_DEPARTMENT = gql`
  mutation DeleteCompanyDepartment($id: String!) {
    deleteCompanyDepartment(id: $id) {
      id
    }
  }
`;

export const CREATE_MET_TYPE = gql`
  mutation CreateMetType($data: MetTypeInput!) {
    createMetType(data: $data)
  }
`;

export const UPDATE_MET_TYPE = gql`
  mutation UpdateMetType($data: MetTypeInput!, $id: String!) {
    updateMetType(data: $data, id: $id)
  }
`;

export const CREATE_NOTIFICATION = gql`
  mutation CreateNotification(
    $target: NotificationTarget!
    $data: NotificationInput!
  ) {
    createNotification(target: $target, data: $data)
  }
`;

export const DELETE_NOTIFICATION = gql`
  mutation Mutation($ids: [String]!) {
    deleteNotification(ids: $ids)
  }
`;

export const UPDATE_NOTIFICATION = gql`
  mutation Mutation($id: String!, $data: NotificationInput!) {
    updateNotification(id: $id, data: $data)
  }
`;

export const CREATE_SERVICE = gql`
  mutation CreateService($data: CreateServiceInput!) {
    createService(data: $data)
  }
`;
export const DELETE_EXERCISE_MANAGEMENT = gql`
  mutation DeleteExerciseManagement($id: String!) {
    deleteExerciseManagement(id: $id)
  }
`;

export const DELETE_AGE_GLYCATION = gql`
  mutation DeleteAgeGlycation($id: String!) {
    deleteAgeGlycation(id: $id)
  }
`;

export const DELETE_BLOOD_GLUCOSE = gql`
  mutation DeleteBloodGlucose($id: String!) {
    deleteBloodGlucose(id: $id)
  }
`;

export const DELETE_PULSWAVE = gql`
  mutation DeletePulseWave($id: String!) {
    deletePulseWave(id: $id)
  }
`;

export const DELETE_CUSTOM_SERVICE = gql`
  mutation DeleteUserServiceRecord($id: String!) {
    deleteUserServiceRecord(id: $id)
  }
`;

export const DELETE_GRIP_STRENGTH = gql`
  mutation DeleteGripStrength($id: String!) {
    deleteGripStrength(id: $id)
  }
`;

export const DELETE_BLOOD_PRESSURE = gql`
  mutation DeleteBloodPressure($id: String!) {
    deleteBloodPressure(id: $id)
  }
`;

export const DELETE_CAPILLARY_TYPE = gql`
  mutation DeleteCapillaryType($id: String!) {
    deleteCapillaryType(id: $id)
  }
`;

export const CREATE_EXERCISE_MANAGEMENT = gql`
  mutation CreateExerciseManagement($data: CreateExerciseManagementInput!) {
    createExerciseManagement(data: $data) {
      id
    }
  }
`;

export const CREATE_AGE_GLYCATION = gql`
  mutation CreateAgeGlycation($data: CreateAgeGlycationInput!) {
    createAgeGlycation(data: $data) {
      id
    }
  }
`;

export const CREATE_BLOOD_GLUCOSE = gql`
  mutation CreateBloodGlucose($data: CreateBloodGlucoseInput!) {
    createBloodGlucose(data: $data) {
      id
    }
  }
`;

export const CREATE_PULSWAVE = gql`
  mutation CreatePulseWave($data: CreatePulseWaveInput!) {
    createPulseWave(data: $data) {
      id
    }
  }
`;

export const UPDATE_SERVICE = gql`
  mutation UpdateService($data: UpdateServiceInput!) {
    updateService(data: $data)
  }
`;

export const DELETE_MET_TYPE = gql`
  mutation DeleteMetType($id: String!) {
    deleteMetType(id: $id)
  }
`;
export const CREATE_GRIP_STRENGTH = gql`
  mutation CreateGripStrength($data: CreateGripStrengthInput!) {
    createGripStrength(data: $data) {
      id
    }
  }
`;

export const CREATE_BLOOD_PRESSURE = gql`
  mutation CreateBloodPressure($data: CreateBloodPressureInput!) {
    createBloodPressure(data: $data) {
      id
    }
  }
`;

export const CREATE_CAPILLARY_TYPE = gql`
  mutation CreateCapillaryType($data: CreateCapillaryTypeInput!) {
    createCapillaryType(data: $data) {
      id
    }
  }
`;

export const CREATE_CUSTOM_SERVICE = gql`
  mutation CreateUserServiceRecord($data: UserServiceRecordInput!) {
    createUserServiceRecord(data: $data)
  }
`;

export const UPDATE_PROFILE = gql`
  mutation UserUpdateProfile($data: UserUpdateProfileInput!) {
    userUpdateProfile(data: $data) {
      id
    }
  }
`;

export const CHANGE_USER_STATUS = gql`
  mutation ModifyUserStatus($id: String!, $status: UserStatus!) {
    modifyUserStatus(id: $id, status: $status)
  }
`;

export const CHANGE_EMAIL = gql`
  mutation ChangeEmail($newEmail: String!, $password: String!) {
    changeEmail(newEmail: $newEmail, password: $password)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword(
    $oldPassword: String!
    $newPassword: String!
    $confirmPassword: String!
  ) {
    changePassword(
      oldPassword: $oldPassword
      newPassword: $newPassword
      confirmPassword: $confirmPassword
    )
  }
`;

export const CREATE_EVENT = gql`
  mutation CreateEvent($data: CreateEventInput!) {
    createEvent(data: $data)
  }
`;

export const UPDATE_EVENT = gql`
  mutation UpdateEvent($data: UpdateEventInput!) {
    updateEvent(data: $data)
  }
`;

export const DELETE_EVENT = gql`
  mutation DeleteEvent($eventId: String!) {
    deleteEvent(eventId: $eventId)
  }
`;

export const CREATE_QUESTIONNAIRE = gql`
  mutation CreateQuestionnaire($data: CreateQuestionnaireInput!) {
    createQuestionnaire(data: $data)
  }
`;
export const UPDATE_QUESTIONNAIRE = gql`
  mutation UpdateQuestionnaire($data: UpdateQuestionnaireInput!) {
    updateQuestionnaire(data: $data)
  }
`;

export const DELETE_SERVICE_TARGET = gql`
  mutation DeleteServiceTarget($id: String!) {
    deleteServiceTarget(id: $id)
  }
`;

export const CREATE_SERVICE_TARGET = gql`
  mutation CreateServiceTarget($data: ServiceTargetInput!) {
    createServiceTarget(data: $data)
  }
`;

export const UPDATE_SERVICE_TARGET = gql`
  mutation UpdateServiceTarget($data: ServiceTargetInput!) {
    updateServiceTarget(data: $data)
  }
`;

export const DETAIL_USER_ANSWER_QUESTIONNAIRE = gql`
  query DetailUserAnswerQuestionnaire(
    $data: DetailUserAnswerQuestionnaireInput!
  ) {
    detailUserAnswerQuestionnaire(data: $data) {
      id
      title
      description
      questions {
        id
        content
        isRequired
        type
        explain
        answers {
          id
          content
          isSelected
          order
        }
        groupAnswers {
          id
          title
          order
          answers {
            id
            content
            isSelected
            order
          }
        }
        highlyRatingLabel
        isShowExplain
        lowRatingLabel
      }
      createdAt
      startAt
      finishedAt
      status
      createdBy
      category
    }
  }
`;

export const DELETE_QUESTIONNAIRE = gql`
  mutation Mutation($data: DeleteQuestionnaireInput) {
    deleteQuestionnaire(data: $data)
  }
`;

export const IMPORT_CSV_END_USER = gql`
  mutation ImportCSVEndUser($fileUrl: String!) {
    importCSVEndUser(fileUrl: $fileUrl) {
      error {
        link
        total
      }
      success
    }
  }
`;

export const DELETE_MANY_USERS = gql`
  mutation DeleteManyUsers($ids: [String], $isSoftDelete: Boolean) {
    deleteManyUsers(ids: $ids, isSoftDelete: $isSoftDelete)
  }
`;
