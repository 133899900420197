import {
  GraphQLResolveInfo,
  GraphQLScalarType,
  GraphQLScalarTypeConfig,
} from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type RequireFields<T, K extends keyof T> = Omit<T, K> & {
  [P in K]-?: NonNullable<T[P]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: Date;
  AWSDateTime: Date;
  AWSEmail: string;
  AWSIPAddress: string;
  AWSJSON: string;
  AWSPhone: string;
  AWSTime: string;
  AWSTimestamp: string;
  AWSURL: string;
};

export type AwsDateTimeFilter = {
  equals?: InputMaybe<Scalars['AWSDateTime']>;
  gt?: InputMaybe<Scalars['AWSDateTime']>;
  gte?: InputMaybe<Scalars['AWSDateTime']>;
  in?: InputMaybe<Array<Scalars['AWSDateTime']>>;
  lt?: InputMaybe<Scalars['AWSDateTime']>;
  lte?: InputMaybe<Scalars['AWSDateTime']>;
  not?: InputMaybe<AwsDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['AWSDateTime']>>;
};

export type AgeGlycationCursorInput = {
  id?: InputMaybe<Scalars['String']>;
  userId_recordedAt?: InputMaybe<CursorUserIdAndRecordedAt>;
};

export type AgeGlycationListRelationFilter = {
  every?: InputMaybe<AgeGlycationWhereInput>;
  none?: InputMaybe<AgeGlycationWhereInput>;
  some?: InputMaybe<AgeGlycationWhereInput>;
};

export type AgeGlycationOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  recordedAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByInput>;
  userId?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type AgeGlycationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type AgeGlycationRecord = {
  __typename?: 'AgeGlycationRecord';
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  id?: Maybe<Scalars['String']>;
  recordedAt?: Maybe<Scalars['AWSDateTime']>;
  userId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type AgeGlycationRelationFilter = {
  is?: InputMaybe<AgeGlycationWhereInput>;
  isNot?: InputMaybe<AgeGlycationWhereInput>;
};

export type AgeGlycationWhereInput = {
  AND?: InputMaybe<Array<AgeGlycationWhereInput>>;
  NOT?: InputMaybe<Array<AgeGlycationWhereInput>>;
  OR?: InputMaybe<Array<AgeGlycationWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  recordedAt?: InputMaybe<AwsDateTimeFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  value?: InputMaybe<FloatFilter>;
};

export type AnswerInput = {
  content?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
};

export type AnswerListRelationFilter = {
  every?: InputMaybe<AnswerWhereInput>;
  none?: InputMaybe<AnswerWhereInput>;
  some?: InputMaybe<AnswerWhereInput>;
};

export type AnswerOrderByInput = {
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  groupAnswer?: InputMaybe<GroupAnswerOrderByInput>;
  groupAnswerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  question?: InputMaybe<QuestionOrderByInput>;
  questionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userQuestionnaireAnswers?: InputMaybe<UserQuestionnaireAnswerOrderByRelationAggregateInput>;
};

export type AnswerOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type AnswerRelationFilter = {
  is?: InputMaybe<AnswerWhereInput>;
  isNot?: InputMaybe<AnswerWhereInput>;
};

export type AnswerResponse = {
  __typename?: 'AnswerResponse';
  content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isSelected?: Maybe<Scalars['Boolean']>;
  numOfUser?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

export type AnswerWhereInput = {
  AND?: InputMaybe<Array<AnswerWhereInput>>;
  NOT?: InputMaybe<Array<AnswerWhereInput>>;
  OR?: InputMaybe<Array<AnswerWhereInput>>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  groupAnswer?: InputMaybe<GroupAnswerRelationFilter>;
  groupAnswerId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  question?: InputMaybe<QuestionRelationFilter>;
  questionId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  userQuestionnaireAnswers?: InputMaybe<UserQuestionnaireAnswerListRelationFilter>;
};

export type AppVersionListRelationFilter = {
  every?: InputMaybe<AppVersionWhereInput>;
  none?: InputMaybe<AppVersionWhereInput>;
  some?: InputMaybe<AppVersionWhereInput>;
};

export type AppVersionOrderByInput = {
  androidVersion?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  iosVersion?: InputMaybe<SortOrder>;
  minAndroidVersion?: InputMaybe<SortOrder>;
  minIosVersion?: InputMaybe<SortOrder>;
};

export type AppVersionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type AppVersionRelationFilter = {
  is?: InputMaybe<AppVersionWhereInput>;
  isNot?: InputMaybe<AppVersionWhereInput>;
};

export type AppVersionResponse = {
  __typename?: 'AppVersionResponse';
  androidVersion: Scalars['String'];
  iosVersion: Scalars['String'];
  minAndroidVersion?: Maybe<Scalars['String']>;
  minIosVersion?: Maybe<Scalars['String']>;
};

export type AppVersionWhereInput = {
  AND?: InputMaybe<Array<AppVersionWhereInput>>;
  NOT?: InputMaybe<Array<AppVersionWhereInput>>;
  OR?: InputMaybe<Array<AppVersionWhereInput>>;
  androidVersion?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  iosVersion?: InputMaybe<StringFilter>;
  minAndroidVersion?: InputMaybe<StringFilter>;
  minIosVersion?: InputMaybe<StringFilter>;
};

export type BloodGlucoseCursorInput = {
  id?: InputMaybe<Scalars['String']>;
  userId_recordedAt?: InputMaybe<CursorUserIdAndRecordedAt>;
};

export type BloodGlucoseListRelationFilter = {
  every?: InputMaybe<BloodGlucoseWhereInput>;
  none?: InputMaybe<BloodGlucoseWhereInput>;
  some?: InputMaybe<BloodGlucoseWhereInput>;
};

export type BloodGlucoseOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  recordedAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByInput>;
  userId?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type BloodGlucoseOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BloodGlucoseRecord = {
  __typename?: 'BloodGlucoseRecord';
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  id?: Maybe<Scalars['String']>;
  recordedAt?: Maybe<Scalars['AWSDateTime']>;
  userId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type BloodGlucoseRelationFilter = {
  is?: InputMaybe<BloodGlucoseWhereInput>;
  isNot?: InputMaybe<BloodGlucoseWhereInput>;
};

export type BloodGlucoseWhereInput = {
  AND?: InputMaybe<Array<BloodGlucoseWhereInput>>;
  NOT?: InputMaybe<Array<BloodGlucoseWhereInput>>;
  OR?: InputMaybe<Array<BloodGlucoseWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  recordedAt?: InputMaybe<AwsDateTimeFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  value?: InputMaybe<FloatFilter>;
};

export type BloodPressureCursorInput = {
  id?: InputMaybe<Scalars['String']>;
  userId_recordedAt?: InputMaybe<CursorUserIdAndRecordedAt>;
};

export type BloodPressureListRelationFilter = {
  every?: InputMaybe<BloodPressureWhereInput>;
  none?: InputMaybe<BloodPressureWhereInput>;
  some?: InputMaybe<BloodPressureWhereInput>;
};

export type BloodPressureOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  diastolic?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  recordedAt?: InputMaybe<SortOrder>;
  systolic?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByInput>;
  userId?: InputMaybe<SortOrder>;
};

export type BloodPressureOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BloodPressureRecord = {
  __typename?: 'BloodPressureRecord';
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  diastolic?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  recordedAt?: Maybe<Scalars['AWSDateTime']>;
  systolic?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['String']>;
};

export type BloodPressureRelationFilter = {
  is?: InputMaybe<BloodPressureWhereInput>;
  isNot?: InputMaybe<BloodPressureWhereInput>;
};

export type BloodPressureWhereInput = {
  AND?: InputMaybe<Array<BloodPressureWhereInput>>;
  NOT?: InputMaybe<Array<BloodPressureWhereInput>>;
  OR?: InputMaybe<Array<BloodPressureWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  diastolic?: InputMaybe<FloatFilter>;
  id?: InputMaybe<StringFilter>;
  recordedAt?: InputMaybe<AwsDateTimeFilter>;
  systolic?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type BooleanFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<BooleanFilter>;
};

export enum BusinessForm {
  Corporate = 'CORPORATE',
  Individual = 'INDIVIDUAL',
}

export type CalorieListRelationFilter = {
  every?: InputMaybe<CalorieWhereInput>;
  none?: InputMaybe<CalorieWhereInput>;
  some?: InputMaybe<CalorieWhereInput>;
};

export type CalorieOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  recordedAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByInput>;
  userId?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type CalorieOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CalorieRelationFilter = {
  is?: InputMaybe<CalorieWhereInput>;
  isNot?: InputMaybe<CalorieWhereInput>;
};

export type CalorieWhereInput = {
  AND?: InputMaybe<Array<CalorieWhereInput>>;
  NOT?: InputMaybe<Array<CalorieWhereInput>>;
  OR?: InputMaybe<Array<CalorieWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  recordedAt?: InputMaybe<AwsDateTimeFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  value?: InputMaybe<FloatFilter>;
};

export type CapillaryTypeCursorInput = {
  id?: InputMaybe<Scalars['String']>;
  userId_recordedAt?: InputMaybe<CursorUserIdAndRecordedAt>;
};

export type CapillaryTypeListRelationFilter = {
  every?: InputMaybe<CapillaryTypeWhereInput>;
  none?: InputMaybe<CapillaryTypeWhereInput>;
  some?: InputMaybe<CapillaryTypeWhereInput>;
};

export type CapillaryTypeOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  recordedAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByInput>;
  userId?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type CapillaryTypeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CapillaryTypeRecord = {
  __typename?: 'CapillaryTypeRecord';
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  id?: Maybe<Scalars['String']>;
  recordedAt?: Maybe<Scalars['AWSDateTime']>;
  userId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type CapillaryTypeRelationFilter = {
  is?: InputMaybe<CapillaryTypeWhereInput>;
  isNot?: InputMaybe<CapillaryTypeWhereInput>;
};

export type CapillaryTypeWhereInput = {
  AND?: InputMaybe<Array<CapillaryTypeWhereInput>>;
  NOT?: InputMaybe<Array<CapillaryTypeWhereInput>>;
  OR?: InputMaybe<Array<CapillaryTypeWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  recordedAt?: InputMaybe<AwsDateTimeFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  value?: InputMaybe<IntFilter>;
};

export enum ChunkUnitOfTime {
  Days = 'days',
  Hours = 'hours',
  Months = 'months',
  Weeks = 'weeks',
}

export type CompanyCreateEndUserInput = {
  birthday: Scalars['AWSDateTime'];
  departmentId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  gender: Gender;
  height?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  occupation?: InputMaybe<Scalars['String']>;
  prefectureId?: InputMaybe<Scalars['String']>;
  serviceIds: Array<InputMaybe<Scalars['String']>>;
  weight?: InputMaybe<Scalars['Float']>;
};

export type CompanyCreateMemberInput = {
  authority: RoleName;
  email: Scalars['String'];
  name: Scalars['String'];
};

export type CompanyDepartmentListRelationFilter = {
  every?: InputMaybe<CompanyDepartmentWhereInput>;
  none?: InputMaybe<CompanyDepartmentWhereInput>;
  some?: InputMaybe<CompanyDepartmentWhereInput>;
};

export type CompanyDepartmentOrderByInput = {
  company?: InputMaybe<CompanyOrderByInput>;
  companyId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  eventConditions?: InputMaybe<EventConditionOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  users?: InputMaybe<UserOrderByRelationAggregateInput>;
};

export type CompanyDepartmentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CompanyDepartmentRelationFilter = {
  is?: InputMaybe<CompanyDepartmentWhereInput>;
  isNot?: InputMaybe<CompanyDepartmentWhereInput>;
};

export type CompanyDepartmentResponse = {
  __typename?: 'CompanyDepartmentResponse';
  _count?: Maybe<CompanyDepartmentUserCount>;
  companyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
};

export type CompanyDepartmentUserCount = {
  __typename?: 'CompanyDepartmentUserCount';
  users?: Maybe<Scalars['Int']>;
};

export type CompanyDepartmentWhereInput = {
  AND?: InputMaybe<Array<CompanyDepartmentWhereInput>>;
  NOT?: InputMaybe<Array<CompanyDepartmentWhereInput>>;
  OR?: InputMaybe<Array<CompanyDepartmentWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  eventConditions?: InputMaybe<EventConditionListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  users?: InputMaybe<UserListRelationFilter>;
};

export type CompanyListRelationFilter = {
  every?: InputMaybe<CompanyWhereInput>;
  none?: InputMaybe<CompanyWhereInput>;
  some?: InputMaybe<CompanyWhereInput>;
};

export type CompanyOrderByInput = {
  address1?: InputMaybe<SortOrder>;
  address1Kana?: InputMaybe<SortOrder>;
  address2?: InputMaybe<SortOrder>;
  address2Kana?: InputMaybe<SortOrder>;
  businessForm?: InputMaybe<SortOrder>;
  city?: InputMaybe<SortOrder>;
  cityKana?: InputMaybe<SortOrder>;
  companyDepartments?: InputMaybe<CompanyDepartmentOrderByRelationAggregateInput>;
  companyServices?: InputMaybe<CompanyServiceOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  devices?: InputMaybe<DeviceOrderByRelationAggregateInput>;
  email?: InputMaybe<SortOrder>;
  events?: InputMaybe<EventOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  notificationCompanies?: InputMaybe<NotificationCompanyOrderByRelationAggregateInput>;
  notifications?: InputMaybe<NotificationOrderByRelationAggregateInput>;
  personInCharge?: InputMaybe<SortOrder>;
  postalCode?: InputMaybe<SortOrder>;
  prefecture?: InputMaybe<PrefectureOrderByInput>;
  prefectureId?: InputMaybe<SortOrder>;
  serviceTargets?: InputMaybe<ServiceTargetOrderByRelationAggregateInput>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userServices?: InputMaybe<UserServiceOrderByRelationAggregateInput>;
  users?: InputMaybe<UserOrderByRelationAggregateInput>;
};

export type CompanyOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CompanyRelationFilter = {
  is?: InputMaybe<CompanyWhereInput>;
  isNot?: InputMaybe<CompanyWhereInput>;
};

export type CompanyResponse = {
  __typename?: 'CompanyResponse';
  address1?: Maybe<Scalars['String']>;
  address1Kana?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address2Kana?: Maybe<Scalars['String']>;
  adminEmail?: Maybe<Scalars['String']>;
  businessForm?: Maybe<BusinessForm>;
  city?: Maybe<Scalars['String']>;
  cityKana?: Maybe<Scalars['String']>;
  companyServices?: Maybe<Array<Maybe<CompanyServiceResponse>>>;
  createdAt: Scalars['AWSDateTime'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  personInCharge?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  prefecture?: Maybe<PrefectureResponse>;
  prefectureId?: Maybe<Scalars['String']>;
  status?: Maybe<UserStatus>;
  statusModifiedAt?: Maybe<Scalars['AWSDateTime']>;
  type?: Maybe<CompanyType>;
  updatedAt: Scalars['AWSDateTime'];
  userId?: Maybe<Scalars['String']>;
};

export type CompanyServiceListRelationFilter = {
  every?: InputMaybe<CompanyServiceWhereInput>;
  none?: InputMaybe<CompanyServiceWhereInput>;
  some?: InputMaybe<CompanyServiceWhereInput>;
};

export type CompanyServiceOrderByInput = {
  company?: InputMaybe<CompanyOrderByInput>;
  companyId?: InputMaybe<SortOrder>;
  contractEndAt?: InputMaybe<SortOrder>;
  contractStartAt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  service?: InputMaybe<ServiceOrderByInput>;
  serviceId?: InputMaybe<SortOrder>;
  serviceTarget?: InputMaybe<ServiceTargetOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CompanyServiceOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CompanyServiceRelationFilter = {
  is?: InputMaybe<CompanyServiceWhereInput>;
  isNot?: InputMaybe<CompanyServiceWhereInput>;
};

export type CompanyServiceResponse = {
  __typename?: 'CompanyServiceResponse';
  companyId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type CompanyServiceWhereInput = {
  AND?: InputMaybe<Array<CompanyServiceWhereInput>>;
  NOT?: InputMaybe<Array<CompanyServiceWhereInput>>;
  OR?: InputMaybe<Array<CompanyServiceWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  contractEndAt?: InputMaybe<AwsDateTimeFilter>;
  contractStartAt?: InputMaybe<AwsDateTimeFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  service?: InputMaybe<ServiceRelationFilter>;
  serviceId?: InputMaybe<StringFilter>;
  serviceTarget?: InputMaybe<ServiceTargetListRelationFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export enum CompanyType {
  MedicalCompany = 'MEDICAL_COMPANY',
  Normal = 'NORMAL',
}

export type CompanyUpdateMemberInput = {
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<RoleName>;
};

export type CompanyWhereInput = {
  AND?: InputMaybe<Array<CompanyWhereInput>>;
  NOT?: InputMaybe<Array<CompanyWhereInput>>;
  OR?: InputMaybe<Array<CompanyWhereInput>>;
  address1?: InputMaybe<StringFilter>;
  address1Kana?: InputMaybe<StringFilter>;
  address2?: InputMaybe<StringFilter>;
  address2Kana?: InputMaybe<StringFilter>;
  businessForm?: InputMaybe<EnumBusinessFormFilter>;
  city?: InputMaybe<StringFilter>;
  cityKana?: InputMaybe<StringFilter>;
  companyDepartments?: InputMaybe<CompanyDepartmentListRelationFilter>;
  companyServices?: InputMaybe<CompanyServiceListRelationFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  devices?: InputMaybe<DeviceListRelationFilter>;
  email?: InputMaybe<StringFilter>;
  events?: InputMaybe<EventListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  notificationCompanies?: InputMaybe<NotificationCompanyListRelationFilter>;
  notifications?: InputMaybe<NotificationListRelationFilter>;
  personInCharge?: InputMaybe<StringFilter>;
  postalCode?: InputMaybe<StringFilter>;
  prefecture?: InputMaybe<PrefectureRelationFilter>;
  prefectureId?: InputMaybe<StringFilter>;
  serviceTargets?: InputMaybe<ServiceTargetListRelationFilter>;
  type?: InputMaybe<EnumCompanyTypeFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  userServices?: InputMaybe<UserServiceListRelationFilter>;
  users?: InputMaybe<UserListRelationFilter>;
};

export type CreateAgeGlycationInput = {
  recordedAt: Scalars['AWSDateTime'];
  userId?: InputMaybe<Scalars['String']>;
  value: Scalars['Float'];
};

export type CreateBloodGlucoseInput = {
  recordedAt: Scalars['AWSDateTime'];
  userId?: InputMaybe<Scalars['String']>;
  value: Scalars['Float'];
};

export type CreateBloodPressureInput = {
  diastolic: Scalars['Float'];
  recordedAt: Scalars['AWSDateTime'];
  systolic: Scalars['Float'];
  userId?: InputMaybe<Scalars['String']>;
};

export type CreateCapillaryTypeInput = {
  recordedAt: Scalars['AWSDateTime'];
  userId?: InputMaybe<Scalars['String']>;
  value: Scalars['Float'];
};

export type CreateCompanyInput = {
  adminEmail: Scalars['String'];
  businessForm: BusinessForm;
  email: Scalars['String'];
  location?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  personInCharge?: InputMaybe<Scalars['String']>;
  serviceIds: Array<InputMaybe<Scalars['String']>>;
  type?: InputMaybe<CompanyType>;
};

export type CreateEventConditionInput = {
  enableUserAge?: InputMaybe<Scalars['Boolean']>;
  enableUserDepartment?: InputMaybe<Scalars['Boolean']>;
  enableUserGender?: InputMaybe<Scalars['Boolean']>;
  enableUserHistory?: InputMaybe<Scalars['Boolean']>;
  userAgeFrom?: InputMaybe<Scalars['Int']>;
  userAgeTo?: InputMaybe<Scalars['Int']>;
  userDepartmentId?: InputMaybe<Scalars['String']>;
  userGender?: InputMaybe<Gender>;
  userHistoryFrom?: InputMaybe<Scalars['AWSDateTime']>;
  userHistoryTo?: InputMaybe<Scalars['AWSDateTime']>;
};

export type CreateEventInput = {
  bannerKeyName?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate: Scalars['AWSDateTime'];
  evaluation?: InputMaybe<EventEvaluation>;
  eventCondition?: InputMaybe<CreateEventConditionInput>;
  format: EventFormat;
  mainMotionKeyName?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  startDate: Scalars['AWSDateTime'];
  subMotionKeyName?: InputMaybe<Scalars['String']>;
  targetValue?: InputMaybe<Scalars['Float']>;
  type: EventType;
};

export type CreateExerciseManagementInput = {
  calorie: Scalars['Float'];
  metTypeId: Scalars['String'];
  recordedAt: Scalars['AWSDateTime'];
  time: Scalars['Float'];
  userId?: InputMaybe<Scalars['String']>;
  weight: Scalars['Float'];
};

export type CreateGripStrengthInput = {
  recordedAt: Scalars['AWSDateTime'];
  userId?: InputMaybe<Scalars['String']>;
  value: Scalars['Float'];
};

export type CreatePulseWaveInput = {
  bulkModulus: Scalars['Float'];
  closePressure: Scalars['Float'];
  expansionRate: Scalars['Float'];
  openPressure: Scalars['Float'];
  recordedAt: Scalars['AWSDateTime'];
  userGender?: InputMaybe<Gender>;
  userId?: InputMaybe<Scalars['String']>;
};

export type CreatePutPresignedUrlResponse = {
  __typename?: 'CreatePutPresignedUrlResponse';
  fileName: Scalars['String'];
  url: Scalars['String'];
};

export type CreateQuestionnaireInput = {
  category?: InputMaybe<QuestionnaireCategory>;
  description?: InputMaybe<Scalars['String']>;
  questions?: InputMaybe<Array<InputMaybe<QuestionInput>>>;
  status?: InputMaybe<QuestionnaireStatus>;
  title?: InputMaybe<Scalars['String']>;
};

export type CreateServiceInput = {
  description?: InputMaybe<Scalars['String']>;
  items?: InputMaybe<Array<InputMaybe<ItemServiceInput>>>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ServiceStatus>;
};

export type CreateWeightLossInput = {
  eventId: Scalars['String'];
  recordedAt?: InputMaybe<Scalars['AWSDateTime']>;
  weight: Scalars['Float'];
};

export type CsvListUserResponse = {
  __typename?: 'CsvListUserResponse';
  fileUrl: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

export type CursorCompanyIdAndServiceId = {
  companyId?: InputMaybe<Scalars['String']>;
  serviceId?: InputMaybe<Scalars['String']>;
};

export type CursorUserIdAndEventIdAndRecordedAt = {
  eventId: Scalars['String'];
  recordedAt: Scalars['AWSDateTime'];
  userId: Scalars['String'];
};

export type CursorUserIdAndRecordedAt = {
  recordedAt: Scalars['AWSDateTime'];
  userId: Scalars['String'];
};

export type CursorUserIdStartTimeEndTime = {
  endTime?: InputMaybe<Scalars['AWSDateTime']>;
  startTime?: InputMaybe<Scalars['AWSDateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type DeleteQuestionnaireInput = {
  category?: InputMaybe<QuestionnaireCategory>;
  id?: InputMaybe<Scalars['String']>;
};

export type DetailEventResponse = {
  __typename?: 'DetailEventResponse';
  data?: Maybe<Event>;
  overallData?: Maybe<EventOverallData>;
  rankData?: Maybe<UserRankData>;
};

export type DetailUserAnswerQuestionnaireInput = {
  questionnaireId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type DeviceListRelationFilter = {
  every?: InputMaybe<DeviceWhereInput>;
  none?: InputMaybe<DeviceWhereInput>;
  some?: InputMaybe<DeviceWhereInput>;
};

export type DeviceOrderByInput = {
  company?: InputMaybe<CompanyOrderByInput>;
  companyId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  licenseCode?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userDevices?: InputMaybe<DeviceUserOrderByRelationAggregateInput>;
};

export type DeviceOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type DeviceRelationFilter = {
  is?: InputMaybe<DeviceWhereInput>;
  isNot?: InputMaybe<DeviceWhereInput>;
};

export type DeviceUserListRelationFilter = {
  every?: InputMaybe<DeviceUserWhereInput>;
  none?: InputMaybe<DeviceUserWhereInput>;
  some?: InputMaybe<DeviceUserWhereInput>;
};

export type DeviceUserOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  device?: InputMaybe<DeviceOrderByInput>;
  deviceId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByInput>;
  userId?: InputMaybe<SortOrder>;
};

export type DeviceUserOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type DeviceUserRelationFilter = {
  is?: InputMaybe<DeviceUserWhereInput>;
  isNot?: InputMaybe<DeviceUserWhereInput>;
};

export type DeviceUserWhereInput = {
  AND?: InputMaybe<Array<DeviceUserWhereInput>>;
  NOT?: InputMaybe<Array<DeviceUserWhereInput>>;
  OR?: InputMaybe<Array<DeviceUserWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  device?: InputMaybe<DeviceRelationFilter>;
  deviceId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type DeviceWhereInput = {
  AND?: InputMaybe<Array<DeviceWhereInput>>;
  NOT?: InputMaybe<Array<DeviceWhereInput>>;
  OR?: InputMaybe<Array<DeviceWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  licenseCode?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  userDevices?: InputMaybe<DeviceUserListRelationFilter>;
};

export type DynamicLinkResponse = {
  __typename?: 'DynamicLinkResponse';
  dynamicLink: Scalars['String'];
};

export type EnumBusinessFormFilter = {
  equals?: InputMaybe<BusinessForm>;
  in?: InputMaybe<Array<BusinessForm>>;
  not?: InputMaybe<EnumBusinessFormFilter>;
  notIn?: InputMaybe<Array<BusinessForm>>;
};

export type EnumCompanyTypeFilter = {
  equals?: InputMaybe<CompanyType>;
  in?: InputMaybe<Array<CompanyType>>;
  not?: InputMaybe<EnumCompanyTypeFilter>;
  notIn?: InputMaybe<Array<CompanyType>>;
};

export type EnumEventEvaluationFilter = {
  equals?: InputMaybe<EventEvaluation>;
  in?: InputMaybe<Array<EventEvaluation>>;
  not?: InputMaybe<EnumEventEvaluationFilter>;
  notIn?: InputMaybe<Array<EventEvaluation>>;
};

export type EnumEventFormatFilter = {
  equals?: InputMaybe<EventFormat>;
  in?: InputMaybe<Array<EventFormat>>;
  not?: InputMaybe<EnumEventFormatFilter>;
  notIn?: InputMaybe<Array<EventFormat>>;
};

export type EnumEventTypeFilter = {
  equals?: InputMaybe<EventType>;
  in?: InputMaybe<Array<EventType>>;
  not?: InputMaybe<EnumEventTypeFilter>;
  notIn?: InputMaybe<Array<EventType>>;
};

export type EnumGenderFilter = {
  equals?: InputMaybe<Gender>;
  in?: InputMaybe<Array<Gender>>;
  not?: InputMaybe<EnumGenderFilter>;
  notIn?: InputMaybe<Array<Gender>>;
};

export type EnumNotificationCreatedByFilter = {
  equals?: InputMaybe<NotificationCreatedBy>;
  in?: InputMaybe<Array<NotificationCreatedBy>>;
  not?: InputMaybe<EnumNotificationCreatedByFilter>;
  notIn?: InputMaybe<Array<NotificationCreatedBy>>;
};

export type EnumNotificationTargetFilter = {
  equals?: InputMaybe<NotificationTarget>;
  in?: InputMaybe<Array<NotificationTarget>>;
  not?: InputMaybe<EnumNotificationTargetFilter>;
  notIn?: InputMaybe<Array<NotificationTarget>>;
};

export type EnumNotificationTypeFilter = {
  equals?: InputMaybe<NotificationType>;
  in?: InputMaybe<Array<NotificationType>>;
  not?: InputMaybe<EnumNotificationTypeFilter>;
  notIn?: InputMaybe<Array<NotificationType>>;
};

export type EnumQuestionTypeFilter = {
  equals?: InputMaybe<QuestionType>;
  in?: InputMaybe<Array<QuestionType>>;
  not?: InputMaybe<EnumQuestionTypeFilter>;
  notIn?: InputMaybe<Array<QuestionType>>;
};

export type EnumQuestionnaireCategoryFilter = {
  equals?: InputMaybe<QuestionnaireCategory>;
  in?: InputMaybe<Array<QuestionnaireCategory>>;
  not?: InputMaybe<EnumQuestionnaireCategoryFilter>;
  notIn?: InputMaybe<Array<QuestionnaireCategory>>;
};

export type EnumQuestionnaireStatusFilter = {
  equals?: InputMaybe<QuestionnaireStatus>;
  in?: InputMaybe<Array<QuestionnaireStatus>>;
  not?: InputMaybe<EnumQuestionnaireStatusFilter>;
  notIn?: InputMaybe<Array<QuestionnaireStatus>>;
};

export type EnumRoleNameFilter = {
  equals?: InputMaybe<RoleName>;
  in?: InputMaybe<Array<RoleName>>;
  not?: InputMaybe<EnumRoleNameFilter>;
  notIn?: InputMaybe<Array<RoleName>>;
};

export type EnumServiceStatusFilter = {
  equals?: InputMaybe<ServiceStatus>;
  in?: InputMaybe<Array<ServiceStatus>>;
  not?: InputMaybe<EnumServiceStatusFilter>;
  notIn?: InputMaybe<Array<ServiceStatus>>;
};

export type EnumServiceTypeFilter = {
  equals?: InputMaybe<ServiceType>;
  in?: InputMaybe<Array<ServiceType>>;
  not?: InputMaybe<EnumServiceTypeFilter>;
  notIn?: InputMaybe<Array<ServiceType>>;
};

export type EnumSyncDataSessionStatusFilter = {
  equals?: InputMaybe<SyncDataSessionStatus>;
  in?: InputMaybe<Array<SyncDataSessionStatus>>;
  not?: InputMaybe<EnumSyncDataSessionStatusFilter>;
  notIn?: InputMaybe<Array<SyncDataSessionStatus>>;
};

export type EnumTokenTypeFilter = {
  equals?: InputMaybe<TokenType>;
  in?: InputMaybe<Array<TokenType>>;
  not?: InputMaybe<EnumTokenTypeFilter>;
  notIn?: InputMaybe<Array<TokenType>>;
};

export type EnumUserStatusFilter = {
  equals?: InputMaybe<UserStatus>;
  in?: InputMaybe<Array<UserStatus>>;
  not?: InputMaybe<EnumUserStatusFilter>;
  notIn?: InputMaybe<Array<UserStatus>>;
};

export type EnumUserTypeFilter = {
  equals?: InputMaybe<UserType>;
  in?: InputMaybe<Array<UserType>>;
  not?: InputMaybe<EnumUserTypeFilter>;
  notIn?: InputMaybe<Array<UserType>>;
};

export type Event = {
  __typename?: 'Event';
  bannerImageUrl?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['AWSDateTime']>;
  evaluation?: Maybe<EventEvaluation>;
  eventCondition?: Maybe<EventCondition>;
  eventUsers?: Maybe<Array<Maybe<EventUser>>>;
  format?: Maybe<EventFormat>;
  id?: Maybe<Scalars['String']>;
  isUserJoined?: Maybe<Scalars['Boolean']>;
  mainMotionImageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['AWSDateTime']>;
  subMotionImageUrl?: Maybe<Scalars['String']>;
  targetValue?: Maybe<Scalars['Float']>;
  totalUsers?: Maybe<Scalars['Int']>;
  type?: Maybe<EventType>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
};

export type EventCondition = {
  __typename?: 'EventCondition';
  department?: Maybe<CompanyDepartmentResponse>;
  enableUserAge?: Maybe<Scalars['Boolean']>;
  enableUserDepartment?: Maybe<Scalars['Boolean']>;
  enableUserGender?: Maybe<Scalars['Boolean']>;
  enableUserHistory?: Maybe<Scalars['Boolean']>;
  eventId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  userAgeFrom?: Maybe<Scalars['Int']>;
  userAgeTo?: Maybe<Scalars['Int']>;
  userDepartmentId?: Maybe<Scalars['String']>;
  userGender?: Maybe<Gender>;
  userHistoryFrom?: Maybe<Scalars['AWSDateTime']>;
  userHistoryTo?: Maybe<Scalars['AWSDateTime']>;
};

export type EventConditionListRelationFilter = {
  every?: InputMaybe<EventConditionWhereInput>;
  none?: InputMaybe<EventConditionWhereInput>;
  some?: InputMaybe<EventConditionWhereInput>;
};

export type EventConditionOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  department?: InputMaybe<CompanyDepartmentOrderByInput>;
  enableUserAge?: InputMaybe<SortOrder>;
  enableUserDepartment?: InputMaybe<SortOrder>;
  enableUserGender?: InputMaybe<SortOrder>;
  enableUserHistory?: InputMaybe<SortOrder>;
  event?: InputMaybe<EventOrderByInput>;
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userAgeFrom?: InputMaybe<SortOrder>;
  userAgeTo?: InputMaybe<SortOrder>;
  userDepartmentId?: InputMaybe<SortOrder>;
  userGender?: InputMaybe<SortOrder>;
  userHistoryFrom?: InputMaybe<SortOrder>;
  userHistoryTo?: InputMaybe<SortOrder>;
};

export type EventConditionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type EventConditionRelationFilter = {
  is?: InputMaybe<EventConditionWhereInput>;
  isNot?: InputMaybe<EventConditionWhereInput>;
};

export type EventConditionWhereInput = {
  AND?: InputMaybe<Array<EventConditionWhereInput>>;
  NOT?: InputMaybe<Array<EventConditionWhereInput>>;
  OR?: InputMaybe<Array<EventConditionWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  department?: InputMaybe<CompanyDepartmentRelationFilter>;
  enableUserAge?: InputMaybe<BooleanFilter>;
  enableUserDepartment?: InputMaybe<BooleanFilter>;
  enableUserGender?: InputMaybe<BooleanFilter>;
  enableUserHistory?: InputMaybe<BooleanFilter>;
  event?: InputMaybe<EventRelationFilter>;
  eventId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  userAgeFrom?: InputMaybe<IntFilter>;
  userAgeTo?: InputMaybe<IntFilter>;
  userDepartmentId?: InputMaybe<StringFilter>;
  userGender?: InputMaybe<EnumGenderFilter>;
  userHistoryFrom?: InputMaybe<AwsDateTimeFilter>;
  userHistoryTo?: InputMaybe<AwsDateTimeFilter>;
};

export type EventCursorInput = {
  id?: InputMaybe<Scalars['String']>;
};

export enum EventEvaluation {
  Pileup = 'PILEUP',
  Stamp = 'STAMP',
}

export enum EventFormat {
  Individual = 'INDIVIDUAL',
  Whole = 'WHOLE',
}

export type EventListRelationFilter = {
  every?: InputMaybe<EventWhereInput>;
  none?: InputMaybe<EventWhereInput>;
  some?: InputMaybe<EventWhereInput>;
};

export type EventOrderByInput = {
  bannerImageUrl?: InputMaybe<SortOrder>;
  company?: InputMaybe<CompanyOrderByInput>;
  companyId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  evaluation?: InputMaybe<SortOrder>;
  eventCondition?: InputMaybe<EventConditionOrderByInput>;
  eventUsers?: InputMaybe<EventUserOrderByRelationAggregateInput>;
  format?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainMotionImageUrl?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  subMotionImageUrl?: InputMaybe<SortOrder>;
  targetValue?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  weightLosses?: InputMaybe<WeightLossOrderByRelationAggregateInput>;
};

export type EventOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type EventOverallData = {
  __typename?: 'EventOverallData';
  firstValue?: Maybe<Scalars['Float']>;
  secondValue?: Maybe<Scalars['Float']>;
  thirdValue?: Maybe<Scalars['Float']>;
};

export type EventRelationFilter = {
  is?: InputMaybe<EventWhereInput>;
  isNot?: InputMaybe<EventWhereInput>;
};

export enum EventType {
  Momentum = 'MOMENTUM',
  Step = 'STEP',
  WeightLoss = 'WEIGHT_LOSS',
}

export type EventUser = {
  __typename?: 'EventUser';
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  eventId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type EventUserListRelationFilter = {
  every?: InputMaybe<EventUserWhereInput>;
  none?: InputMaybe<EventUserWhereInput>;
  some?: InputMaybe<EventUserWhereInput>;
};

export type EventUserOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  event?: InputMaybe<EventOrderByInput>;
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByInput>;
  userId?: InputMaybe<SortOrder>;
};

export type EventUserOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type EventUserRelationFilter = {
  is?: InputMaybe<EventUserWhereInput>;
  isNot?: InputMaybe<EventUserWhereInput>;
};

export type EventUserWhereInput = {
  AND?: InputMaybe<Array<EventUserWhereInput>>;
  NOT?: InputMaybe<Array<EventUserWhereInput>>;
  OR?: InputMaybe<Array<EventUserWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  event?: InputMaybe<EventRelationFilter>;
  eventId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type EventWhereInput = {
  AND?: InputMaybe<Array<EventWhereInput>>;
  NOT?: InputMaybe<Array<EventWhereInput>>;
  OR?: InputMaybe<Array<EventWhereInput>>;
  bannerImageUrl?: InputMaybe<StringFilter>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  endDate?: InputMaybe<AwsDateTimeFilter>;
  evaluation?: InputMaybe<EnumEventEvaluationFilter>;
  eventCondition?: InputMaybe<EventConditionRelationFilter>;
  eventUsers?: InputMaybe<EventUserListRelationFilter>;
  format?: InputMaybe<EnumEventFormatFilter>;
  id?: InputMaybe<StringFilter>;
  mainMotionImageUrl?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<AwsDateTimeFilter>;
  subMotionImageUrl?: InputMaybe<StringFilter>;
  targetValue?: InputMaybe<FloatFilter>;
  type?: InputMaybe<EnumEventTypeFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  weightLosses?: InputMaybe<WeightLossListRelationFilter>;
};

export type ExerciseListRelationFilter = {
  every?: InputMaybe<ExerciseWhereInput>;
  none?: InputMaybe<ExerciseWhereInput>;
  some?: InputMaybe<ExerciseWhereInput>;
};

export type ExerciseManagementCursorInput = {
  id?: InputMaybe<Scalars['String']>;
  userId_recordedAt?: InputMaybe<CursorUserIdAndRecordedAt>;
};

export type ExerciseManagementListRelationFilter = {
  every?: InputMaybe<ExerciseManagementWhereInput>;
  none?: InputMaybe<ExerciseManagementWhereInput>;
  some?: InputMaybe<ExerciseManagementWhereInput>;
};

export type ExerciseManagementOrderByInput = {
  calorie?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdOrder?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  metType?: InputMaybe<MetTypeOrderByInput>;
  metTypeId?: InputMaybe<SortOrder>;
  recordedAt?: InputMaybe<SortOrder>;
  time?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByInput>;
  userId?: InputMaybe<SortOrder>;
  weight?: InputMaybe<SortOrder>;
};

export type ExerciseManagementOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ExerciseManagementRecord = {
  __typename?: 'ExerciseManagementRecord';
  calorie?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  id?: Maybe<Scalars['String']>;
  isAutoCalorie?: Maybe<Scalars['Boolean']>;
  metType?: Maybe<MetTypeRecord>;
  metTypeId?: Maybe<Scalars['String']>;
  recordedAt?: Maybe<Scalars['AWSDateTime']>;
  time?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  userId?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
};

export type ExerciseManagementRelationFilter = {
  is?: InputMaybe<ExerciseManagementWhereInput>;
  isNot?: InputMaybe<ExerciseManagementWhereInput>;
};

export type ExerciseManagementWhereInput = {
  AND?: InputMaybe<Array<ExerciseManagementWhereInput>>;
  NOT?: InputMaybe<Array<ExerciseManagementWhereInput>>;
  OR?: InputMaybe<Array<ExerciseManagementWhereInput>>;
  calorie?: InputMaybe<FloatFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  createdOrder?: InputMaybe<IntFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  metType?: InputMaybe<MetTypeRelationFilter>;
  metTypeId?: InputMaybe<StringFilter>;
  recordedAt?: InputMaybe<AwsDateTimeFilter>;
  time?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  weight?: InputMaybe<FloatFilter>;
};

export type ExerciseOrderByInput = {
  calorieLastMinute?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  endTime?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isInExercise?: InputMaybe<SortOrder>;
  prevEndTime?: InputMaybe<SortOrder>;
  startTime?: InputMaybe<SortOrder>;
  sumValidTime?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByInput>;
  userId?: InputMaybe<SortOrder>;
};

export type ExerciseOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ExerciseRelationFilter = {
  is?: InputMaybe<ExerciseWhereInput>;
  isNot?: InputMaybe<ExerciseWhereInput>;
};

export type ExerciseWhereInput = {
  AND?: InputMaybe<Array<ExerciseWhereInput>>;
  NOT?: InputMaybe<Array<ExerciseWhereInput>>;
  OR?: InputMaybe<Array<ExerciseWhereInput>>;
  calorieLastMinute?: InputMaybe<FloatFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  endTime?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isInExercise?: InputMaybe<BooleanFilter>;
  prevEndTime?: InputMaybe<AwsDateTimeFilter>;
  startTime?: InputMaybe<AwsDateTimeFilter>;
  sumValidTime?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type ExportCsvEndUserInput = {
  from: Scalars['AWSDateTime'];
  serviceIdOrKey: Scalars['String'];
  to: Scalars['AWSDateTime'];
};

export type ExportCsvEndUserResponse = {
  __typename?: 'ExportCSVEndUserResponse';
  fileName: Scalars['String'];
  fileUrl: Scalars['String'];
};

export type ExportQuestionnaireInput = {
  category: QuestionnaireCategory;
  id: Scalars['String'];
};

export type ExportQuestionnaireResponse = {
  __typename?: 'ExportQuestionnaireResponse';
  fileUrl: Scalars['String'];
};

export type FatigueCursorInput = {
  id?: InputMaybe<Scalars['String']>;
  userId_recordedAt?: InputMaybe<CursorUserIdAndRecordedAt>;
};

export type FatigueListRelationFilter = {
  every?: InputMaybe<FatigueWhereInput>;
  none?: InputMaybe<FatigueWhereInput>;
  some?: InputMaybe<FatigueWhereInput>;
};

export type FatigueOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  recordedAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByInput>;
  userId?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type FatigueOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type FatigueRelationFilter = {
  is?: InputMaybe<FatigueWhereInput>;
  isNot?: InputMaybe<FatigueWhereInput>;
};

export type FatigueWhereInput = {
  AND?: InputMaybe<Array<FatigueWhereInput>>;
  NOT?: InputMaybe<Array<FatigueWhereInput>>;
  OR?: InputMaybe<Array<FatigueWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  recordedAt?: InputMaybe<AwsDateTimeFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  value?: InputMaybe<IntFilter>;
};

export type FilterCsvInput = {
  birthday?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['Boolean']>;
  gender?: InputMaybe<Scalars['Boolean']>;
  height?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  prefectures?: InputMaybe<Scalars['Boolean']>;
  services?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['Boolean']>;
  weight?: InputMaybe<Scalars['Boolean']>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<FloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  Unknown = 'UNKNOWN',
}

export type GraphAgeGlycationResponse = {
  __typename?: 'GraphAgeGlycationResponse';
  data?: Maybe<Array<Maybe<AgeGlycationRecord>>>;
  value?: Maybe<Scalars['Float']>;
};

export type GraphBloodGlucoseResponse = {
  __typename?: 'GraphBloodGlucoseResponse';
  data?: Maybe<Array<Maybe<BloodGlucoseRecord>>>;
  value?: Maybe<Scalars['Float']>;
};

export type GraphBloodPressureResponse = {
  __typename?: 'GraphBloodPressureResponse';
  data?: Maybe<Array<Maybe<BloodPressureRecord>>>;
  diastolic?: Maybe<Scalars['Float']>;
  systolic?: Maybe<Scalars['Float']>;
};

export type GraphCalorieResponse = {
  __typename?: 'GraphCalorieResponse';
  data?: Maybe<Array<Maybe<RecordCalorie>>>;
  value?: Maybe<Scalars['Float']>;
};

export type GraphCapillaryTypeResponse = {
  __typename?: 'GraphCapillaryTypeResponse';
  data?: Maybe<Array<Maybe<CapillaryTypeRecord>>>;
  value?: Maybe<Scalars['Float']>;
};

export type GraphExerciseManagementResponse = {
  __typename?: 'GraphExerciseManagementResponse';
  data?: Maybe<Array<Maybe<ExerciseManagementRecord>>>;
  value?: Maybe<ExerciseManagementRecord>;
};

export type GraphExerciseResponse = {
  __typename?: 'GraphExerciseResponse';
  data?: Maybe<Array<Maybe<RecordExercise>>>;
  value?: Maybe<Scalars['Float']>;
};

export type GraphFatigueResponse = {
  __typename?: 'GraphFatigueResponse';
  data?: Maybe<Array<Maybe<RecordFatigue>>>;
  value?: Maybe<Scalars['Float']>;
};

export type GraphGripStrengthResponse = {
  __typename?: 'GraphGripStrengthResponse';
  data?: Maybe<Array<Maybe<GripStrengthRecord>>>;
  value?: Maybe<Scalars['Float']>;
};

export type GraphHeartRateResponse = {
  __typename?: 'GraphHeartRateResponse';
  data?: Maybe<Array<Maybe<RecordHeartRate>>>;
  maxValue?: Maybe<Scalars['Float']>;
  minValue?: Maybe<Scalars['Float']>;
};

export type GraphPulseWaveResponse = {
  __typename?: 'GraphPulseWaveResponse';
  data?: Maybe<Array<Maybe<RecordPulseWave>>>;
  value?: Maybe<RecordPulseWave>;
};

export type GraphSleepResponse = {
  __typename?: 'GraphSleepResponse';
  avgSleepScore?: Maybe<Scalars['Float']>;
  avgTime?: Maybe<Scalars['Float']>;
  data?: Maybe<Array<Maybe<RecordGraphSleep>>>;
  rateTotalSleepMode?: Maybe<Array<Maybe<RateTotalSleepMode>>>;
  totalTime?: Maybe<Scalars['Float']>;
};

export type GraphStandResponse = {
  __typename?: 'GraphStandResponse';
  data?: Maybe<Array<Maybe<RecordStand>>>;
  value?: Maybe<Scalars['Float']>;
};

export type GraphStepInput = {
  from?: InputMaybe<Scalars['AWSDateTime']>;
  mode?: InputMaybe<ChunkUnitOfTime>;
  to?: InputMaybe<Scalars['AWSDateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type GraphStepRecord = {
  __typename?: 'GraphStepRecord';
  recordedAt?: Maybe<RecordedAt>;
  steps?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['String']>;
};

export type GraphStepResponse = {
  __typename?: 'GraphStepResponse';
  avgStep?: Maybe<Scalars['Float']>;
  data?: Maybe<Array<Maybe<GraphStepRecord>>>;
};

export type GraphUserServices = {
  __typename?: 'GraphUserServices';
  data?: Maybe<Array<Maybe<UserServiceItemDetail>>>;
  value?: Maybe<Scalars['Float']>;
};

export type GraphUserServicesInput = {
  from: Scalars['AWSDateTime'];
  mode: ChunkUnitOfTime;
  serviceItemId: Scalars['String'];
  to: Scalars['AWSDateTime'];
  userId?: InputMaybe<Scalars['String']>;
};

export type GripStrengthCursorInput = {
  id?: InputMaybe<Scalars['String']>;
  userId_recordedAt?: InputMaybe<CursorUserIdAndRecordedAt>;
};

export type GripStrengthListRelationFilter = {
  every?: InputMaybe<GripStrengthWhereInput>;
  none?: InputMaybe<GripStrengthWhereInput>;
  some?: InputMaybe<GripStrengthWhereInput>;
};

export type GripStrengthOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  recordedAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByInput>;
  userId?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type GripStrengthOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type GripStrengthRecord = {
  __typename?: 'GripStrengthRecord';
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  id?: Maybe<Scalars['String']>;
  recordedAt?: Maybe<Scalars['AWSDateTime']>;
  userId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type GripStrengthRelationFilter = {
  is?: InputMaybe<GripStrengthWhereInput>;
  isNot?: InputMaybe<GripStrengthWhereInput>;
};

export type GripStrengthWhereInput = {
  AND?: InputMaybe<Array<GripStrengthWhereInput>>;
  NOT?: InputMaybe<Array<GripStrengthWhereInput>>;
  OR?: InputMaybe<Array<GripStrengthWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  recordedAt?: InputMaybe<AwsDateTimeFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  value?: InputMaybe<FloatFilter>;
};

export type GroupAnswerInput = {
  answers?: InputMaybe<Array<InputMaybe<AnswerInput>>>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

export type GroupAnswerListRelationFilter = {
  every?: InputMaybe<GroupAnswerWhereInput>;
  none?: InputMaybe<GroupAnswerWhereInput>;
  some?: InputMaybe<GroupAnswerWhereInput>;
};

export type GroupAnswerOrderByInput = {
  answers?: InputMaybe<AnswerOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  question?: InputMaybe<QuestionOrderByInput>;
  questionId?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userQuestionnaireAnswer?: InputMaybe<UserQuestionnaireAnswerOrderByRelationAggregateInput>;
};

export type GroupAnswerOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type GroupAnswerRelationFilter = {
  is?: InputMaybe<GroupAnswerWhereInput>;
  isNot?: InputMaybe<GroupAnswerWhereInput>;
};

export type GroupAnswerResponse = {
  __typename?: 'GroupAnswerResponse';
  answers?: Maybe<Array<Maybe<AnswerResponse>>>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

export type GroupAnswerWhereInput = {
  AND?: InputMaybe<Array<GroupAnswerWhereInput>>;
  NOT?: InputMaybe<Array<GroupAnswerWhereInput>>;
  OR?: InputMaybe<Array<GroupAnswerWhereInput>>;
  answers?: InputMaybe<AnswerListRelationFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  question?: InputMaybe<QuestionRelationFilter>;
  questionId?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  userQuestionnaireAnswer?: InputMaybe<UserQuestionnaireAnswerListRelationFilter>;
};

export type HeartRateListRelationFilter = {
  every?: InputMaybe<HeartRateWhereInput>;
  none?: InputMaybe<HeartRateWhereInput>;
  some?: InputMaybe<HeartRateWhereInput>;
};

export type HeartRateOrderByInput = {
  avgValue?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  maxValue?: InputMaybe<SortOrder>;
  minValue?: InputMaybe<SortOrder>;
  recordedAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByInput>;
  userId?: InputMaybe<SortOrder>;
};

export type HeartRateOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type HeartRateRelationFilter = {
  is?: InputMaybe<HeartRateWhereInput>;
  isNot?: InputMaybe<HeartRateWhereInput>;
};

export type HeartRateWhereInput = {
  AND?: InputMaybe<Array<HeartRateWhereInput>>;
  NOT?: InputMaybe<Array<HeartRateWhereInput>>;
  OR?: InputMaybe<Array<HeartRateWhereInput>>;
  avgValue?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  maxValue?: InputMaybe<IntFilter>;
  minValue?: InputMaybe<IntFilter>;
  recordedAt?: InputMaybe<AwsDateTimeFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type HistoryAgeGlycationResponse = {
  __typename?: 'HistoryAgeGlycationResponse';
  data?: Maybe<Array<Maybe<AgeGlycationRecord>>>;
  pagination: PaginationPageInfo;
};

export type HistoryBloodGlucoseResponse = {
  __typename?: 'HistoryBloodGlucoseResponse';
  data?: Maybe<Array<Maybe<BloodGlucoseRecord>>>;
  pagination: PaginationPageInfo;
};

export type HistoryBloodPressureResponse = {
  __typename?: 'HistoryBloodPressureResponse';
  data?: Maybe<Array<Maybe<BloodPressureRecord>>>;
  pagination: PaginationPageInfo;
};

export type HistoryCalorieResponse = {
  __typename?: 'HistoryCalorieResponse';
  data?: Maybe<Array<Maybe<RecordCalorie>>>;
};

export type HistoryCapillaryTypeResponse = {
  __typename?: 'HistoryCapillaryTypeResponse';
  data?: Maybe<Array<Maybe<CapillaryTypeRecord>>>;
  pagination: PaginationPageInfo;
};

export type HistoryExerciseManagementResponse = {
  __typename?: 'HistoryExerciseManagementResponse';
  data?: Maybe<Array<Maybe<ExerciseManagementRecord>>>;
  pagination?: Maybe<PaginationPageInfo>;
};

export type HistoryExerciseResponse = {
  __typename?: 'HistoryExerciseResponse';
  data?: Maybe<Array<Maybe<RecordExercise>>>;
};

export type HistoryFatigueResponse = {
  __typename?: 'HistoryFatigueResponse';
  data?: Maybe<Array<Maybe<HistoryRecordFatigue>>>;
  pagination: PaginationPageInfo;
};

export type HistoryGripStrengthResponse = {
  __typename?: 'HistoryGripStrengthResponse';
  data?: Maybe<Array<Maybe<GripStrengthRecord>>>;
  pagination: PaginationPageInfo;
};

export type HistoryHeartRateResponse = {
  __typename?: 'HistoryHeartRateResponse';
  data?: Maybe<Array<Maybe<RecordHeartRate>>>;
};

export type HistoryPulseWaveResponse = {
  __typename?: 'HistoryPulseWaveResponse';
  data?: Maybe<Array<Maybe<RecordPulseWave>>>;
  pagination: PaginationPageInfo;
};

export type HistoryRecordFatigue = {
  __typename?: 'HistoryRecordFatigue';
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  id?: Maybe<Scalars['String']>;
  recordedAt?: Maybe<Scalars['AWSDateTime']>;
  userId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type HistoryRunningResponse = {
  __typename?: 'HistoryRunningResponse';
  data?: Maybe<Array<Maybe<RunningRecord>>>;
  pagination?: Maybe<PaginationPageInfo>;
  totalDistance?: Maybe<Scalars['Float']>;
  totalTime?: Maybe<Scalars['Float']>;
};

export type HistorySleepResponse = {
  __typename?: 'HistorySleepResponse';
  data?: Maybe<Array<Maybe<RecordHistorySleep>>>;
};

export type HistorySportResponse = {
  __typename?: 'HistorySportResponse';
  data?: Maybe<Array<Maybe<SportRecord>>>;
  pagination?: Maybe<PaginationPageInfo>;
  totalCalorie?: Maybe<Scalars['Float']>;
  totalDistance?: Maybe<Scalars['Float']>;
  totalTime?: Maybe<Scalars['Float']>;
};

export type HistoryStandResponse = {
  __typename?: 'HistoryStandResponse';
  data?: Maybe<Array<Maybe<RecordStand>>>;
};

export type HistoryStepResponse = {
  __typename?: 'HistoryStepResponse';
  data?: Maybe<Array<Maybe<GraphStepRecord>>>;
};

export type HistoryUserServicesInput = {
  cursor?: InputMaybe<UserServiceDetailCursorInput>;
  from?: InputMaybe<Scalars['AWSDateTime']>;
  orderBy?: InputMaybe<UserServiceDetailOrderByInput>;
  serviceId: Scalars['String'];
  to?: InputMaybe<Scalars['AWSDateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type HistoryUserServicesRecord = {
  __typename?: 'HistoryUserServicesRecord';
  data?: Maybe<Array<Maybe<UserServiceDetail>>>;
  pagination?: Maybe<PaginationPageInfo>;
};

export type HistoryWalkingResponse = {
  __typename?: 'HistoryWalkingResponse';
  data?: Maybe<Array<Maybe<WalkingRecord>>>;
  pagination?: Maybe<PaginationPageInfo>;
  totalDistance?: Maybe<Scalars['Float']>;
  totalTime?: Maybe<Scalars['Float']>;
};

export type HistoryWeightLossResponse = {
  __typename?: 'HistoryWeightLossResponse';
  data?: Maybe<Array<Maybe<WeightLossRecord>>>;
  pagination?: Maybe<PaginationPageInfo>;
};

export type HomeData = {
  __typename?: 'HomeData';
  badminton?: Maybe<Scalars['Float']>;
  basketball?: Maybe<Scalars['Float']>;
  calorie?: Maybe<Scalars['Float']>;
  cycling?: Maybe<Scalars['Float']>;
  dance?: Maybe<Scalars['Float']>;
  exercise?: Maybe<Scalars['Float']>;
  fatigue?: Maybe<Scalars['Float']>;
  football?: Maybe<Scalars['Float']>;
  gymExercise?: Maybe<Scalars['Float']>;
  heartRate?: Maybe<Scalars['Int']>;
  rockClimbing?: Maybe<Scalars['Float']>;
  running?: Maybe<Scalars['Float']>;
  skate?: Maybe<Scalars['Float']>;
  sleep?: Maybe<Scalars['Float']>;
  stand?: Maybe<Scalars['Float']>;
  step?: Maybe<Scalars['Int']>;
  swim?: Maybe<Scalars['Float']>;
  tableTennis?: Maybe<Scalars['Float']>;
  tennis?: Maybe<Scalars['Float']>;
  vascularAge?: Maybe<Scalars['Float']>;
  volleyball?: Maybe<Scalars['Float']>;
  walking?: Maybe<Scalars['Float']>;
  yoga?: Maybe<Scalars['Float']>;
};

export enum HomeDataKey {
  Aerobics = 'AEROBICS',
  Badminton = 'BADMINTON',
  Basketball = 'BASKETBALL',
  Calorie = 'CALORIE',
  Cycling = 'CYCLING',
  Dance = 'DANCE',
  Exercise = 'EXERCISE',
  Fatigue = 'FATIGUE',
  Football = 'FOOTBALL',
  GymExercise = 'GYM_EXERCISE',
  HeartRate = 'HEART_RATE',
  RockClimbing = 'ROCK_CLIMBING',
  Running = 'RUNNING',
  Skate = 'SKATE',
  Sleep = 'SLEEP',
  Stand = 'STAND',
  Step = 'STEP',
  Swim = 'SWIM',
  TableTennis = 'TABLE_TENNIS',
  Tennis = 'TENNIS',
  VascularAge = 'VASCULAR_AGE',
  VolleyBall = 'VOLLEY_BALL',
  Walking = 'WALKING',
  Yoga = 'YOGA',
}

export type HomeResponse = {
  __typename?: 'HomeResponse';
  data?: Maybe<HomeData>;
};

export type ImportCsvEndUserError = {
  __typename?: 'ImportCSVEndUserError';
  link?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type ImportCsvEndUserResponse = {
  __typename?: 'ImportCSVEndUserResponse';
  error?: Maybe<ImportCsvEndUserError>;
  success: Scalars['Boolean'];
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<IntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type ItemServiceInput = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
};

export type ListCompanyMemberResponse = {
  __typename?: 'ListCompanyMemberResponse';
  data?: Maybe<Array<Maybe<UserResponse>>>;
  pagination: PaginationPageInfo;
};

export type ListCompanyResponse = {
  __typename?: 'ListCompanyResponse';
  data: Array<Maybe<CompanyResponse>>;
  pagination: PaginationPageInfo;
};

export type ListDepartmentResponse = {
  __typename?: 'ListDepartmentResponse';
  data?: Maybe<Array<Maybe<CompanyDepartmentResponse>>>;
  pagination?: Maybe<PaginationPageInfo>;
};

export type ListEventsResponse = {
  __typename?: 'ListEventsResponse';
  events?: Maybe<Array<Maybe<Event>>>;
  pagination?: Maybe<PaginationPageInfo>;
};

export type ListMetTypeResponse = {
  __typename?: 'ListMetTypeResponse';
  data?: Maybe<Array<Maybe<MetTypeRecord>>>;
  pagination?: Maybe<PaginationPageInfo>;
};

export type ListNotificationResponse = {
  __typename?: 'ListNotificationResponse';
  data: Array<Maybe<NotificationResponse>>;
  pagination: PaginationPageInfo;
};

export type ListPrefectureResponse = {
  __typename?: 'ListPrefectureResponse';
  data: Array<Maybe<PrefectureResponse>>;
  pagination: PaginationPageInfo;
};

export type ListQuestionnaireResponse = {
  __typename?: 'ListQuestionnaireResponse';
  data?: Maybe<Array<Maybe<QuestionnaireResponse>>>;
  pagination?: Maybe<PaginationPageInfo>;
};

export type ListServiceConfigResponse = {
  __typename?: 'ListServiceConfigResponse';
  data: Array<Maybe<ServiceConfigResponse>>;
  pagination: PaginationPageInfo;
};

export type ListServiceResponse = {
  __typename?: 'ListServiceResponse';
  data: Array<Maybe<ServiceResponse>>;
  pagination: PaginationPageInfo;
};

export type ListServiceTargetResponse = {
  __typename?: 'ListServiceTargetResponse';
  data?: Maybe<Array<Maybe<ServiceTarget>>>;
  pagination?: Maybe<PaginationPageInfo>;
};

export type ListUserResponse = {
  __typename?: 'ListUserResponse';
  data?: Maybe<Array<Maybe<UserResponse>>>;
  pagination?: Maybe<PaginationPageInfo>;
};

export type MetTypeInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  speed?: InputMaybe<Scalars['Float']>;
  value: Scalars['Float'];
};

export type MetTypeListRelationFilter = {
  every?: InputMaybe<MetTypeWhereInput>;
  none?: InputMaybe<MetTypeWhereInput>;
  some?: InputMaybe<MetTypeWhereInput>;
};

export type MetTypeOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  exerciseManagements?: InputMaybe<ExerciseManagementOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  speed?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type MetTypeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type MetTypeRecord = {
  __typename?: 'MetTypeRecord';
  createdAt: Scalars['AWSDateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  speed?: Maybe<Scalars['Float']>;
  value: Scalars['Float'];
};

export type MetTypeRelationFilter = {
  is?: InputMaybe<MetTypeWhereInput>;
  isNot?: InputMaybe<MetTypeWhereInput>;
};

export type MetTypeWhereInput = {
  AND?: InputMaybe<Array<MetTypeWhereInput>>;
  NOT?: InputMaybe<Array<MetTypeWhereInput>>;
  OR?: InputMaybe<Array<MetTypeWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  exerciseManagements?: InputMaybe<ExerciseManagementListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  speed?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<FloatFilter>;
};

export type Mutation = {
  __typename?: 'Mutation';
  changeEmail?: Maybe<Scalars['Boolean']>;
  changePassword?: Maybe<Scalars['Boolean']>;
  companyCreateEndUser: Scalars['Boolean'];
  companyCreateMember: Scalars['Boolean'];
  companyUpdateMember?: Maybe<Scalars['Boolean']>;
  createAgeGlycation?: Maybe<AgeGlycationRecord>;
  createBloodGlucose?: Maybe<BloodGlucoseRecord>;
  createBloodPressure: BloodPressureRecord;
  createCapillaryType?: Maybe<CapillaryTypeRecord>;
  createCompany: Scalars['Boolean'];
  createCompanyDepartment?: Maybe<CompanyDepartmentResponse>;
  createEvent?: Maybe<Scalars['Boolean']>;
  createExerciseManagement?: Maybe<ExerciseManagementRecord>;
  createGripStrength?: Maybe<GripStrengthRecord>;
  createMetType: Scalars['Boolean'];
  createNotification: Scalars['Boolean'];
  createPulseWave?: Maybe<RecordPulseWave>;
  createQuestionnaire?: Maybe<Scalars['Boolean']>;
  createService?: Maybe<Scalars['Boolean']>;
  createServiceTarget?: Maybe<Scalars['String']>;
  createUserServiceRecord?: Maybe<Scalars['String']>;
  createWeightLoss?: Maybe<Scalars['Boolean']>;
  deleteAgeGlycation?: Maybe<Scalars['Boolean']>;
  deleteBloodGlucose?: Maybe<Scalars['Boolean']>;
  deleteBloodPressure?: Maybe<Scalars['Boolean']>;
  deleteCapillaryType?: Maybe<Scalars['Boolean']>;
  deleteCompanyDepartment?: Maybe<CompanyDepartmentResponse>;
  deleteEvent?: Maybe<Scalars['Boolean']>;
  deleteExerciseManagement?: Maybe<Scalars['Boolean']>;
  deleteGripStrength?: Maybe<Scalars['Boolean']>;
  deleteManyUsers?: Maybe<Scalars['Boolean']>;
  deleteMetType: Scalars['Boolean'];
  deleteNotification: Scalars['Boolean'];
  deletePulseWave?: Maybe<Scalars['Boolean']>;
  deleteQuestionnaire?: Maybe<Scalars['Boolean']>;
  deleteServiceTarget?: Maybe<Scalars['String']>;
  deleteUser?: Maybe<Scalars['Boolean']>;
  deleteUserServiceRecord?: Maybe<Scalars['String']>;
  forgotPassword?: Maybe<Scalars['Boolean']>;
  importCSVEndUser: ImportCsvEndUserResponse;
  modifyUserStatus?: Maybe<Scalars['Boolean']>;
  publishNotification?: Maybe<PublishNotificationResponse>;
  refreshToken: RefreshTokenResponse;
  resendVerifyEmail?: Maybe<Scalars['Boolean']>;
  resendVerifyEmailBtoC?: Maybe<Scalars['Boolean']>;
  sendMailForgotPassword?: Maybe<Scalars['Boolean']>;
  setUserPassword: DynamicLinkResponse;
  signIn: SignInResponse;
  signUp: Scalars['Boolean'];
  syncData: SyncDataResponse;
  updateAgeGlycation?: Maybe<AgeGlycationRecord>;
  updateBloodGlucose?: Maybe<BloodGlucoseRecord>;
  updateBloodPressure: BloodPressureRecord;
  updateCapillaryType?: Maybe<CapillaryTypeRecord>;
  updateCompany?: Maybe<CompanyResponse>;
  updateCompanyDepartment?: Maybe<CompanyDepartmentResponse>;
  updateEvent?: Maybe<Scalars['Boolean']>;
  updateExerciseManagement?: Maybe<ExerciseManagementRecord>;
  updateGripStrength?: Maybe<GripStrengthRecord>;
  updateMetType: Scalars['Boolean'];
  updateNotification: Scalars['Boolean'];
  updatePulseWave?: Maybe<RecordPulseWave>;
  updateQuestionnaire?: Maybe<Scalars['Boolean']>;
  updateService?: Maybe<Scalars['Boolean']>;
  updateServiceTarget?: Maybe<Scalars['String']>;
  updateUser?: Maybe<Scalars['Boolean']>;
  updateUserServiceConfig?: Maybe<Scalars['Boolean']>;
  updateUserServiceRecord?: Maybe<Scalars['String']>;
  userAnswerQuestionnaire?: Maybe<Scalars['Boolean']>;
  userJoinEvent?: Maybe<Scalars['Boolean']>;
  userUpdateProfile: UserResponse;
};

export type MutationChangeEmailArgs = {
  newEmail: Scalars['String'];
  password: Scalars['String'];
};

export type MutationChangePasswordArgs = {
  confirmPassword: Scalars['String'];
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type MutationCompanyCreateEndUserArgs = {
  data: CompanyCreateEndUserInput;
};

export type MutationCompanyCreateMemberArgs = {
  data: CompanyCreateMemberInput;
};

export type MutationCompanyUpdateMemberArgs = {
  data: CompanyUpdateMemberInput;
};

export type MutationCreateAgeGlycationArgs = {
  data: CreateAgeGlycationInput;
};

export type MutationCreateBloodGlucoseArgs = {
  data: CreateBloodGlucoseInput;
};

export type MutationCreateBloodPressureArgs = {
  data: CreateBloodPressureInput;
};

export type MutationCreateCapillaryTypeArgs = {
  data: CreateCapillaryTypeInput;
};

export type MutationCreateCompanyArgs = {
  data: CreateCompanyInput;
};

export type MutationCreateCompanyDepartmentArgs = {
  name: Scalars['String'];
};

export type MutationCreateEventArgs = {
  data: CreateEventInput;
};

export type MutationCreateExerciseManagementArgs = {
  data: CreateExerciseManagementInput;
};

export type MutationCreateGripStrengthArgs = {
  data: CreateGripStrengthInput;
};

export type MutationCreateMetTypeArgs = {
  data: MetTypeInput;
};

export type MutationCreateNotificationArgs = {
  data: NotificationInput;
  target: NotificationTarget;
};

export type MutationCreatePulseWaveArgs = {
  data: CreatePulseWaveInput;
};

export type MutationCreateQuestionnaireArgs = {
  data: CreateQuestionnaireInput;
};

export type MutationCreateServiceArgs = {
  data: CreateServiceInput;
};

export type MutationCreateServiceTargetArgs = {
  data: ServiceTargetInput;
};

export type MutationCreateUserServiceRecordArgs = {
  data: UserServiceRecordInput;
};

export type MutationCreateWeightLossArgs = {
  data: CreateWeightLossInput;
};

export type MutationDeleteAgeGlycationArgs = {
  id: Scalars['String'];
};

export type MutationDeleteBloodGlucoseArgs = {
  id: Scalars['String'];
};

export type MutationDeleteBloodPressureArgs = {
  id: Scalars['String'];
};

export type MutationDeleteCapillaryTypeArgs = {
  id: Scalars['String'];
};

export type MutationDeleteCompanyDepartmentArgs = {
  id: Scalars['String'];
};

export type MutationDeleteEventArgs = {
  eventId: Scalars['String'];
};

export type MutationDeleteExerciseManagementArgs = {
  id: Scalars['String'];
};

export type MutationDeleteGripStrengthArgs = {
  id: Scalars['String'];
};

export type MutationDeleteManyUsersArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isSoftDelete?: InputMaybe<Scalars['Boolean']>;
};

export type MutationDeleteMetTypeArgs = {
  id: Scalars['String'];
};

export type MutationDeleteNotificationArgs = {
  ids: Array<InputMaybe<Scalars['String']>>;
};

export type MutationDeletePulseWaveArgs = {
  id: Scalars['String'];
};

export type MutationDeleteQuestionnaireArgs = {
  data?: InputMaybe<DeleteQuestionnaireInput>;
};

export type MutationDeleteServiceTargetArgs = {
  id: Scalars['String'];
};

export type MutationDeleteUserArgs = {
  id?: InputMaybe<Scalars['String']>;
};

export type MutationDeleteUserServiceRecordArgs = {
  id: Scalars['String'];
};

export type MutationForgotPasswordArgs = {
  confirmPassword: Scalars['String'];
  newPassword: Scalars['String'];
  token: Scalars['String'];
};

export type MutationImportCsvEndUserArgs = {
  fileUrl: Scalars['String'];
};

export type MutationModifyUserStatusArgs = {
  id: Scalars['String'];
  status: UserStatus;
};

export type MutationPublishNotificationArgs = {
  data: Scalars['AWSJSON'];
  receiverId: Scalars['String'];
};

export type MutationRefreshTokenArgs = {
  refreshTokenInput: RefreshTokenInput;
};

export type MutationResendVerifyEmailArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MutationResendVerifyEmailBtoCArgs = {
  email: Scalars['String'];
};

export type MutationSendMailForgotPasswordArgs = {
  email: Scalars['String'];
};

export type MutationSetUserPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type MutationSignInArgs = {
  signInInput: SignInInput;
};

export type MutationSignUpArgs = {
  data: SignUpInput;
};

export type MutationSyncDataArgs = {
  healthData: Array<InputMaybe<RecordSyncDataInput>>;
  lastSyncedAt?: InputMaybe<Scalars['AWSDateTime']>;
  sleepData?: InputMaybe<Array<InputMaybe<RecordSleepInput>>>;
};

export type MutationUpdateAgeGlycationArgs = {
  data: UpdateAgeGlycationInput;
};

export type MutationUpdateBloodGlucoseArgs = {
  data: UpdateBloodGlucoseInput;
};

export type MutationUpdateBloodPressureArgs = {
  data: UpdateBloodPressureInput;
};

export type MutationUpdateCapillaryTypeArgs = {
  data: UpdateCapillaryTypeInput;
};

export type MutationUpdateCompanyArgs = {
  data: UpdateCompanyInput;
};

export type MutationUpdateCompanyDepartmentArgs = {
  data: UpdateCompanyDepartmentInput;
};

export type MutationUpdateEventArgs = {
  data: UpdateEventInput;
};

export type MutationUpdateExerciseManagementArgs = {
  data: UpdateExerciseManagementInput;
};

export type MutationUpdateGripStrengthArgs = {
  data: UpdateGripStrengthInput;
};

export type MutationUpdateMetTypeArgs = {
  data: MetTypeInput;
  id: Scalars['String'];
};

export type MutationUpdateNotificationArgs = {
  data: NotificationInput;
  id: Scalars['String'];
};

export type MutationUpdatePulseWaveArgs = {
  data: UpdatePulseWaveInput;
};

export type MutationUpdateQuestionnaireArgs = {
  data: UpdateQuestionnaireInput;
};

export type MutationUpdateServiceArgs = {
  data: UpdateServiceInput;
};

export type MutationUpdateServiceTargetArgs = {
  data: ServiceTargetInput;
};

export type MutationUpdateUserArgs = {
  data: UpdateUserInput;
};

export type MutationUpdateUserServiceConfigArgs = {
  data: Array<InputMaybe<UpdateUserServiceConfigInput>>;
};

export type MutationUpdateUserServiceRecordArgs = {
  data: UserServiceRecordInput;
};

export type MutationUserAnswerQuestionnaireArgs = {
  data: UserAnswerQuestionnaireInput;
};

export type MutationUserJoinEventArgs = {
  eventId: Scalars['String'];
};

export type MutationUserUpdateProfileArgs = {
  data: UserUpdateProfileInput;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NotificationCompanies = {
  __typename?: 'NotificationCompanies';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type NotificationCompanyListRelationFilter = {
  every?: InputMaybe<NotificationCompanyWhereInput>;
  none?: InputMaybe<NotificationCompanyWhereInput>;
  some?: InputMaybe<NotificationCompanyWhereInput>;
};

export type NotificationCompanyOrderByInput = {
  company?: InputMaybe<CompanyOrderByInput>;
  companyId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  notification?: InputMaybe<NotificationOrderByInput>;
  notificationId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type NotificationCompanyOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type NotificationCompanyRelationFilter = {
  is?: InputMaybe<NotificationCompanyWhereInput>;
  isNot?: InputMaybe<NotificationCompanyWhereInput>;
};

export type NotificationCompanyWhereInput = {
  AND?: InputMaybe<Array<NotificationCompanyWhereInput>>;
  NOT?: InputMaybe<Array<NotificationCompanyWhereInput>>;
  OR?: InputMaybe<Array<NotificationCompanyWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  notification?: InputMaybe<NotificationRelationFilter>;
  notificationId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export enum NotificationCreatedBy {
  Company = 'COMPANY',
  System = 'SYSTEM',
}

export type NotificationDetailResponse = {
  __typename?: 'NotificationDetailResponse';
  companies?: Maybe<Array<Maybe<NotificationCompanies>>>;
  companyId?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['String'];
  isMajor: Scalars['Boolean'];
  isPublishNow: Scalars['Boolean'];
  largeImage?: Maybe<Scalars['String']>;
  personInCharge?: Maybe<Scalars['String']>;
  publishTime: Scalars['AWSDateTime'];
  smallImage?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: NotificationType;
  userId?: Maybe<Scalars['String']>;
  users?: Maybe<Array<Maybe<NotificationUsers>>>;
};

export type NotificationInput = {
  content: Scalars['String'];
  isMajor: Scalars['Boolean'];
  isPublishNow: Scalars['Boolean'];
  largeImage?: InputMaybe<Scalars['String']>;
  personInCharge?: InputMaybe<Scalars['String']>;
  publishTime?: InputMaybe<Scalars['AWSDateTime']>;
  smallImage?: InputMaybe<Scalars['String']>;
  targetIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title: Scalars['String'];
  type: NotificationType;
};

export type NotificationListRelationFilter = {
  every?: InputMaybe<NotificationWhereInput>;
  none?: InputMaybe<NotificationWhereInput>;
  some?: InputMaybe<NotificationWhereInput>;
};

export type NotificationOrderByInput = {
  company?: InputMaybe<CompanyOrderByInput>;
  companyId?: InputMaybe<SortOrder>;
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isMajor?: InputMaybe<SortOrder>;
  isPublishNow?: InputMaybe<SortOrder>;
  largeImage?: InputMaybe<SortOrder>;
  notificationCompanies?: InputMaybe<NotificationCompanyOrderByRelationAggregateInput>;
  notificationReads?: InputMaybe<NotificationReadOrderByRelationAggregateInput>;
  notificationUsers?: InputMaybe<NotificationUserOrderByRelationAggregateInput>;
  personInCharge?: InputMaybe<SortOrder>;
  publishTime?: InputMaybe<SortOrder>;
  smallImage?: InputMaybe<SortOrder>;
  target?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type NotificationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type NotificationReadListRelationFilter = {
  every?: InputMaybe<NotificationReadWhereInput>;
  none?: InputMaybe<NotificationReadWhereInput>;
  some?: InputMaybe<NotificationReadWhereInput>;
};

export type NotificationReadOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  notification?: InputMaybe<NotificationOrderByInput>;
  notificationId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByInput>;
  userId?: InputMaybe<SortOrder>;
};

export type NotificationReadOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type NotificationReadRelationFilter = {
  is?: InputMaybe<NotificationReadWhereInput>;
  isNot?: InputMaybe<NotificationReadWhereInput>;
};

export type NotificationReadWhereInput = {
  AND?: InputMaybe<Array<NotificationReadWhereInput>>;
  NOT?: InputMaybe<Array<NotificationReadWhereInput>>;
  OR?: InputMaybe<Array<NotificationReadWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  notification?: InputMaybe<NotificationRelationFilter>;
  notificationId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type NotificationRelationFilter = {
  is?: InputMaybe<NotificationWhereInput>;
  isNot?: InputMaybe<NotificationWhereInput>;
};

export type NotificationResponse = {
  __typename?: 'NotificationResponse';
  id: Scalars['String'];
  isMajor: Scalars['Boolean'];
  isPublishNow: Scalars['Boolean'];
  largeImage?: Maybe<Scalars['String']>;
  personInCharge?: Maybe<Scalars['String']>;
  publishTime?: Maybe<Scalars['AWSDateTime']>;
  smallImage?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: NotificationType;
};

export enum NotificationTarget {
  Company = 'COMPANY',
  User = 'USER',
}

export enum NotificationType {
  All = 'ALL',
  Multiple = 'MULTIPLE',
}

export type NotificationUserListRelationFilter = {
  every?: InputMaybe<NotificationUserWhereInput>;
  none?: InputMaybe<NotificationUserWhereInput>;
  some?: InputMaybe<NotificationUserWhereInput>;
};

export type NotificationUserOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  notification?: InputMaybe<NotificationOrderByInput>;
  notificationId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByInput>;
  userId?: InputMaybe<SortOrder>;
};

export type NotificationUserOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type NotificationUserRelationFilter = {
  is?: InputMaybe<NotificationUserWhereInput>;
  isNot?: InputMaybe<NotificationUserWhereInput>;
};

export type NotificationUserWhereInput = {
  AND?: InputMaybe<Array<NotificationUserWhereInput>>;
  NOT?: InputMaybe<Array<NotificationUserWhereInput>>;
  OR?: InputMaybe<Array<NotificationUserWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  notification?: InputMaybe<NotificationRelationFilter>;
  notificationId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type NotificationUsers = {
  __typename?: 'NotificationUsers';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type NotificationWhereInput = {
  AND?: InputMaybe<Array<NotificationWhereInput>>;
  NOT?: InputMaybe<Array<NotificationWhereInput>>;
  OR?: InputMaybe<Array<NotificationWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  createdBy?: InputMaybe<EnumNotificationCreatedByFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isMajor?: InputMaybe<BooleanFilter>;
  isPublishNow?: InputMaybe<BooleanFilter>;
  largeImage?: InputMaybe<StringFilter>;
  notificationCompanies?: InputMaybe<NotificationCompanyListRelationFilter>;
  notificationReads?: InputMaybe<NotificationReadListRelationFilter>;
  notificationUsers?: InputMaybe<NotificationUserListRelationFilter>;
  personInCharge?: InputMaybe<StringFilter>;
  publishTime?: InputMaybe<AwsDateTimeFilter>;
  smallImage?: InputMaybe<StringFilter>;
  target?: InputMaybe<EnumNotificationTargetFilter>;
  title?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumNotificationTypeFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type PaginationInput = {
  page: Scalars['Int'];
  perPage: Scalars['Int'];
};

export type PaginationPageInfo = {
  __typename?: 'PaginationPageInfo';
  currentPage: Scalars['Int'];
  perPage: Scalars['Int'];
  total: Scalars['Int'];
};

export type PrefectureListRelationFilter = {
  every?: InputMaybe<PrefectureWhereInput>;
  none?: InputMaybe<PrefectureWhereInput>;
  some?: InputMaybe<PrefectureWhereInput>;
};

export type PrefectureOrderByInput = {
  Company?: InputMaybe<CompanyOrderByRelationAggregateInput>;
  User?: InputMaybe<UserOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  prefecture?: InputMaybe<SortOrder>;
  prefectureKana?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PrefectureOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PrefectureRelationFilter = {
  is?: InputMaybe<PrefectureWhereInput>;
  isNot?: InputMaybe<PrefectureWhereInput>;
};

export type PrefectureResponse = {
  __typename?: 'PrefectureResponse';
  id: Scalars['String'];
  prefecture: Scalars['String'];
  prefectureKana: Scalars['String'];
};

export type PrefectureWhereInput = {
  AND?: InputMaybe<Array<PrefectureWhereInput>>;
  Company?: InputMaybe<CompanyListRelationFilter>;
  NOT?: InputMaybe<Array<PrefectureWhereInput>>;
  OR?: InputMaybe<Array<PrefectureWhereInput>>;
  User?: InputMaybe<UserListRelationFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  prefecture?: InputMaybe<StringFilter>;
  prefectureKana?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type PublishNotificationResponse = {
  __typename?: 'PublishNotificationResponse';
  data: Scalars['AWSJSON'];
  receiverId: Scalars['String'];
};

export type PulseWaveCursorInput = {
  id?: InputMaybe<Scalars['String']>;
  userId_recordedAt?: InputMaybe<CursorUserIdAndRecordedAt>;
};

export type PulseWaveListRelationFilter = {
  every?: InputMaybe<PulseWaveWhereInput>;
  none?: InputMaybe<PulseWaveWhereInput>;
  some?: InputMaybe<PulseWaveWhereInput>;
};

export type PulseWaveOrderByInput = {
  bulkModulus?: InputMaybe<SortOrder>;
  closePressure?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  expansionRate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  openPressure?: InputMaybe<SortOrder>;
  recordedAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByInput>;
  userGender?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  vascularAge?: InputMaybe<SortOrder>;
};

export type PulseWaveOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PulseWaveRelationFilter = {
  is?: InputMaybe<PulseWaveWhereInput>;
  isNot?: InputMaybe<PulseWaveWhereInput>;
};

export type PulseWaveTypeInput = {
  bulkModulus?: InputMaybe<Scalars['Boolean']>;
  closePressure?: InputMaybe<Scalars['Boolean']>;
  expansionRate?: InputMaybe<Scalars['Boolean']>;
  openPressure?: InputMaybe<Scalars['Boolean']>;
  vascularAge?: InputMaybe<Scalars['Boolean']>;
};

export type PulseWaveWhereInput = {
  AND?: InputMaybe<Array<PulseWaveWhereInput>>;
  NOT?: InputMaybe<Array<PulseWaveWhereInput>>;
  OR?: InputMaybe<Array<PulseWaveWhereInput>>;
  bulkModulus?: InputMaybe<FloatFilter>;
  closePressure?: InputMaybe<FloatFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  expansionRate?: InputMaybe<FloatFilter>;
  id?: InputMaybe<StringFilter>;
  openPressure?: InputMaybe<FloatFilter>;
  recordedAt?: InputMaybe<AwsDateTimeFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userGender?: InputMaybe<EnumGenderFilter>;
  userId?: InputMaybe<StringFilter>;
  vascularAge?: InputMaybe<FloatFilter>;
};

export type Query = {
  __typename?: 'Query';
  detailAgeGlycation: AgeGlycationRecord;
  detailBloodGlucose: BloodGlucoseRecord;
  detailBloodPressure: BloodPressureRecord;
  detailCapillaryType: CapillaryTypeRecord;
  detailCompany: CompanyResponse;
  detailDepartment?: Maybe<CompanyDepartmentResponse>;
  detailEvent?: Maybe<DetailEventResponse>;
  detailExerciseManagement?: Maybe<ExerciseManagementRecord>;
  detailGripStrength: GripStrengthRecord;
  detailNotification: NotificationDetailResponse;
  detailPulseWave?: Maybe<RecordPulseWave>;
  detailQuestionnaire: QuestionnaireResponse;
  detailService?: Maybe<ServiceResponse>;
  detailServiceTarget?: Maybe<ServiceTarget>;
  detailUserAnswerQuestionnaire?: Maybe<QuestionnaireResponse>;
  exportCSVEndUser: ExportCsvEndUserResponse;
  exportQuestionnaire?: Maybe<ExportQuestionnaireResponse>;
  getAppVersion?: Maybe<AppVersionResponse>;
  getHomeData?: Maybe<HomeResponse>;
  getListFileUrl?: Maybe<Array<Maybe<Scalars['String']>>>;
  getUploadUrl?: Maybe<CreatePutPresignedUrlResponse>;
  getUser: UserResponse;
  getUserInfo: UserResponse;
  graphAgeGlycation: GraphAgeGlycationResponse;
  graphBloodGlucose: GraphBloodGlucoseResponse;
  graphBloodPressure: GraphBloodPressureResponse;
  graphCalorie: GraphCalorieResponse;
  graphCapillaryType: GraphCapillaryTypeResponse;
  graphExercise?: Maybe<GraphExerciseResponse>;
  graphExerciseManagement: GraphExerciseManagementResponse;
  graphFatigue?: Maybe<GraphFatigueResponse>;
  graphGripStrength: GraphGripStrengthResponse;
  graphHeartRate: GraphHeartRateResponse;
  graphPulseWave: GraphPulseWaveResponse;
  graphSleep?: Maybe<GraphSleepResponse>;
  graphStand: GraphStandResponse;
  graphStep: GraphStepResponse;
  graphUserServicesRecord?: Maybe<GraphUserServices>;
  health?: Maybe<Scalars['Boolean']>;
  historyAgeGlycation: HistoryAgeGlycationResponse;
  historyBloodGlucose: HistoryBloodGlucoseResponse;
  historyBloodPressure: HistoryBloodPressureResponse;
  historyCalorie: HistoryCalorieResponse;
  historyCapillaryType: HistoryCapillaryTypeResponse;
  historyExercise: HistoryExerciseResponse;
  historyExerciseManagement: HistoryExerciseManagementResponse;
  historyFatigue?: Maybe<HistoryFatigueResponse>;
  historyGripStrength: HistoryGripStrengthResponse;
  historyHeartRate: HistoryHeartRateResponse;
  historyPulseWave: HistoryPulseWaveResponse;
  historyRunning: HistoryRunningResponse;
  historySleep?: Maybe<HistorySleepResponse>;
  historySport: HistorySportResponse;
  historyStand: HistoryStandResponse;
  historyStep: HistoryStepResponse;
  historyUserServicesRecord?: Maybe<HistoryUserServicesRecord>;
  historyWalking: HistoryWalkingResponse;
  historyWeightLoss: HistoryWeightLossResponse;
  listCompany: ListCompanyResponse;
  listCompanyDepartment: ListDepartmentResponse;
  listCompanyMember: ListCompanyMemberResponse;
  listEvents?: Maybe<ListEventsResponse>;
  listMetType?: Maybe<ListMetTypeResponse>;
  listNotification: ListNotificationResponse;
  listPrefecture: ListPrefectureResponse;
  listQuestionnaire: ListQuestionnaireResponse;
  listService: ListServiceResponse;
  listServiceItem: Array<Maybe<ServiceItemResponse>>;
  listServiceTarget?: Maybe<ListServiceTargetResponse>;
  listTopUsers?: Maybe<Array<Maybe<TopUsersResponse>>>;
  listUser: ListUserResponse;
  listUserJoinedEvent?: Maybe<UsersJoinedEventResponse>;
  listUserService?: Maybe<ListServiceConfigResponse>;
  stampHistory?: Maybe<Array<Maybe<StampHistoryResponse>>>;
  verifyChangeEmail: DynamicLinkResponse;
  verifyToken?: Maybe<Scalars['Boolean']>;
};

export type QueryDetailAgeGlycationArgs = {
  id: Scalars['String'];
};

export type QueryDetailBloodGlucoseArgs = {
  id: Scalars['String'];
};

export type QueryDetailBloodPressureArgs = {
  id: Scalars['String'];
};

export type QueryDetailCapillaryTypeArgs = {
  id: Scalars['String'];
};

export type QueryDetailCompanyArgs = {
  id?: InputMaybe<Scalars['String']>;
};

export type QueryDetailDepartmentArgs = {
  id: Scalars['String'];
};

export type QueryDetailEventArgs = {
  eventId: Scalars['String'];
};

export type QueryDetailExerciseManagementArgs = {
  id: Scalars['String'];
};

export type QueryDetailGripStrengthArgs = {
  id: Scalars['String'];
};

export type QueryDetailNotificationArgs = {
  notificationId: Scalars['String'];
};

export type QueryDetailPulseWaveArgs = {
  id: Scalars['String'];
};

export type QueryDetailQuestionnaireArgs = {
  id: Scalars['String'];
};

export type QueryDetailServiceArgs = {
  id: Scalars['String'];
};

export type QueryDetailServiceTargetArgs = {
  id: Scalars['String'];
};

export type QueryDetailUserAnswerQuestionnaireArgs = {
  data: DetailUserAnswerQuestionnaireInput;
};

export type QueryExportCsvEndUserArgs = {
  data: ExportCsvEndUserInput;
};

export type QueryExportQuestionnaireArgs = {
  data: ExportQuestionnaireInput;
};

export type QueryGetHomeDataArgs = {
  from: Scalars['AWSDateTime'];
  keys: Array<InputMaybe<HomeDataKey>>;
  to: Scalars['AWSDateTime'];
};

export type QueryGetListFileUrlArgs = {
  fileNames: Array<Scalars['String']>;
};

export type QueryGetUploadUrlArgs = {
  contentType: Scalars['String'];
  fileName: Scalars['String'];
};

export type QueryGetUserArgs = {
  userId: Scalars['String'];
};

export type QueryGraphAgeGlycationArgs = {
  from: Scalars['AWSDateTime'];
  mode: ChunkUnitOfTime;
  to: Scalars['AWSDateTime'];
  userId?: InputMaybe<Scalars['String']>;
};

export type QueryGraphBloodGlucoseArgs = {
  from: Scalars['AWSDateTime'];
  mode: ChunkUnitOfTime;
  to: Scalars['AWSDateTime'];
  userId?: InputMaybe<Scalars['String']>;
};

export type QueryGraphBloodPressureArgs = {
  from: Scalars['AWSDateTime'];
  mode: ChunkUnitOfTime;
  to: Scalars['AWSDateTime'];
  userId?: InputMaybe<Scalars['String']>;
};

export type QueryGraphCalorieArgs = {
  from: Scalars['AWSDateTime'];
  mode: ChunkUnitOfTime;
  to: Scalars['AWSDateTime'];
  userId?: InputMaybe<Scalars['String']>;
};

export type QueryGraphCapillaryTypeArgs = {
  from: Scalars['AWSDateTime'];
  mode: ChunkUnitOfTime;
  to: Scalars['AWSDateTime'];
  userId?: InputMaybe<Scalars['String']>;
};

export type QueryGraphExerciseArgs = {
  from: Scalars['AWSDateTime'];
  mode: ChunkUnitOfTime;
  to: Scalars['AWSDateTime'];
  userId?: InputMaybe<Scalars['String']>;
};

export type QueryGraphExerciseManagementArgs = {
  from: Scalars['AWSDateTime'];
  mode: ChunkUnitOfTime;
  to: Scalars['AWSDateTime'];
  userId?: InputMaybe<Scalars['String']>;
};

export type QueryGraphFatigueArgs = {
  from: Scalars['AWSDateTime'];
  mode: ChunkUnitOfTime;
  to: Scalars['AWSDateTime'];
  userId?: InputMaybe<Scalars['String']>;
};

export type QueryGraphGripStrengthArgs = {
  from: Scalars['AWSDateTime'];
  mode: ChunkUnitOfTime;
  to: Scalars['AWSDateTime'];
  userId?: InputMaybe<Scalars['String']>;
};

export type QueryGraphHeartRateArgs = {
  from: Scalars['AWSDateTime'];
  mode: ChunkUnitOfTime;
  to: Scalars['AWSDateTime'];
  userId?: InputMaybe<Scalars['String']>;
};

export type QueryGraphPulseWaveArgs = {
  from: Scalars['AWSDateTime'];
  mode: ChunkUnitOfTime;
  to: Scalars['AWSDateTime'];
  userId?: InputMaybe<Scalars['String']>;
};

export type QueryGraphSleepArgs = {
  from: Scalars['AWSDateTime'];
  to: Scalars['AWSDateTime'];
  userId?: InputMaybe<Scalars['String']>;
};

export type QueryGraphStandArgs = {
  from: Scalars['AWSDateTime'];
  mode: ChunkUnitOfTime;
  to: Scalars['AWSDateTime'];
  userId?: InputMaybe<Scalars['String']>;
};

export type QueryGraphStepArgs = {
  data: GraphStepInput;
};

export type QueryGraphUserServicesRecordArgs = {
  data?: InputMaybe<GraphUserServicesInput>;
};

export type QueryHealthArgs = {
  log?: InputMaybe<Scalars['AWSJSON']>;
};

export type QueryHistoryAgeGlycationArgs = {
  cursor?: InputMaybe<AgeGlycationCursorInput>;
  from?: InputMaybe<Scalars['AWSDateTime']>;
  orderBy?: InputMaybe<AgeGlycationOrderByInput>;
  pagination?: InputMaybe<PaginationInput>;
  to?: InputMaybe<Scalars['AWSDateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type QueryHistoryBloodGlucoseArgs = {
  cursor?: InputMaybe<BloodGlucoseCursorInput>;
  from?: InputMaybe<Scalars['AWSDateTime']>;
  orderBy?: InputMaybe<BloodGlucoseOrderByInput>;
  pagination?: InputMaybe<PaginationInput>;
  to?: InputMaybe<Scalars['AWSDateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type QueryHistoryBloodPressureArgs = {
  cursor?: InputMaybe<BloodPressureCursorInput>;
  from?: InputMaybe<Scalars['AWSDateTime']>;
  orderBy?: InputMaybe<BloodPressureOrderByInput>;
  pagination?: InputMaybe<PaginationInput>;
  to?: InputMaybe<Scalars['AWSDateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type QueryHistoryCalorieArgs = {
  from: Scalars['AWSDateTime'];
  mode?: InputMaybe<ChunkUnitOfTime>;
  to: Scalars['AWSDateTime'];
  userId?: InputMaybe<Scalars['String']>;
};

export type QueryHistoryCapillaryTypeArgs = {
  cursor?: InputMaybe<CapillaryTypeCursorInput>;
  from?: InputMaybe<Scalars['AWSDateTime']>;
  orderBy?: InputMaybe<CapillaryTypeOrderByInput>;
  pagination?: InputMaybe<PaginationInput>;
  to?: InputMaybe<Scalars['AWSDateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type QueryHistoryExerciseArgs = {
  from: Scalars['AWSDateTime'];
  mode?: InputMaybe<ChunkUnitOfTime>;
  to: Scalars['AWSDateTime'];
  userId?: InputMaybe<Scalars['String']>;
};

export type QueryHistoryExerciseManagementArgs = {
  cursor?: InputMaybe<ExerciseManagementCursorInput>;
  eventId?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['AWSDateTime']>;
  orderBy?: InputMaybe<ExerciseManagementOrderByInput>;
  pagination?: InputMaybe<PaginationInput>;
  to?: InputMaybe<Scalars['AWSDateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type QueryHistoryFatigueArgs = {
  cursor?: InputMaybe<FatigueCursorInput>;
  from?: InputMaybe<Scalars['AWSDateTime']>;
  orderBy?: InputMaybe<FatigueOrderByInput>;
  pagination?: InputMaybe<PaginationInput>;
  to?: InputMaybe<Scalars['AWSDateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type QueryHistoryGripStrengthArgs = {
  cursor?: InputMaybe<GripStrengthCursorInput>;
  from?: InputMaybe<Scalars['AWSDateTime']>;
  orderBy?: InputMaybe<GripStrengthOrderByInput>;
  pagination?: InputMaybe<PaginationInput>;
  to?: InputMaybe<Scalars['AWSDateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type QueryHistoryHeartRateArgs = {
  from: Scalars['AWSDateTime'];
  mode?: InputMaybe<ChunkUnitOfTime>;
  to: Scalars['AWSDateTime'];
  userId?: InputMaybe<Scalars['String']>;
};

export type QueryHistoryPulseWaveArgs = {
  cursor?: InputMaybe<PulseWaveCursorInput>;
  from?: InputMaybe<Scalars['AWSDateTime']>;
  orderBy?: InputMaybe<PulseWaveOrderByInput>;
  pagination?: InputMaybe<PaginationInput>;
  to?: InputMaybe<Scalars['AWSDateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type QueryHistoryRunningArgs = {
  cursor?: InputMaybe<RunningCursorInput>;
  from: Scalars['AWSDateTime'];
  orderBy?: InputMaybe<RunningOrderByInput>;
  pagination?: InputMaybe<PaginationInput>;
  to: Scalars['AWSDateTime'];
  userId?: InputMaybe<Scalars['String']>;
};

export type QueryHistorySleepArgs = {
  from: Scalars['AWSDateTime'];
  to: Scalars['AWSDateTime'];
  userId?: InputMaybe<Scalars['String']>;
};

export type QueryHistorySportArgs = {
  cursorId?: InputMaybe<Scalars['String']>;
  from: Scalars['AWSDateTime'];
  orderBy?: InputMaybe<SportOrderByInput>;
  pagination?: InputMaybe<PaginationInput>;
  sportType: Array<InputMaybe<Scalars['Int']>>;
  to: Scalars['AWSDateTime'];
};

export type QueryHistoryStandArgs = {
  from: Scalars['AWSDateTime'];
  mode?: InputMaybe<ChunkUnitOfTime>;
  to: Scalars['AWSDateTime'];
  userId?: InputMaybe<Scalars['String']>;
};

export type QueryHistoryStepArgs = {
  eventId?: InputMaybe<Scalars['String']>;
  from: Scalars['AWSDateTime'];
  mode?: InputMaybe<ChunkUnitOfTime>;
  to: Scalars['AWSDateTime'];
  userId?: InputMaybe<Scalars['String']>;
};

export type QueryHistoryUserServicesRecordArgs = {
  data?: InputMaybe<HistoryUserServicesInput>;
  pagination?: InputMaybe<PaginationInput>;
};

export type QueryHistoryWalkingArgs = {
  cursor?: InputMaybe<WalkingCursorInput>;
  from: Scalars['AWSDateTime'];
  orderBy?: InputMaybe<WalkingOrderByInput>;
  pagination?: InputMaybe<PaginationInput>;
  to: Scalars['AWSDateTime'];
  userId?: InputMaybe<Scalars['String']>;
};

export type QueryHistoryWeightLossArgs = {
  cursor?: InputMaybe<WeightLossCursorInput>;
  eventId?: InputMaybe<Scalars['String']>;
  from: Scalars['AWSDateTime'];
  orderBy?: InputMaybe<WeightLossOrderByInput>;
  pagination?: InputMaybe<PaginationInput>;
  to: Scalars['AWSDateTime'];
};

export type QueryListCompanyArgs = {
  orderBy?: InputMaybe<CompanyOrderByInput>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<CompanyWhereInput>;
};

export type QueryListCompanyDepartmentArgs = {
  orderBy?: InputMaybe<CompanyDepartmentOrderByInput>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<CompanyDepartmentWhereInput>;
};

export type QueryListCompanyMemberArgs = {
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<UserWhereInput>;
};

export type QueryListEventsArgs = {
  cursor?: InputMaybe<EventCursorInput>;
  isHistory?: InputMaybe<Scalars['Boolean']>;
  orderBy?: InputMaybe<EventOrderByInput>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<EventWhereInput>;
};

export type QueryListMetTypeArgs = {
  orderBy?: InputMaybe<MetTypeOrderByInput>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<MetTypeWhereInput>;
};

export type QueryListNotificationArgs = {
  orderBy?: InputMaybe<NotificationOrderByInput>;
  pagination?: InputMaybe<PaginationInput>;
  target: NotificationTarget;
  where?: InputMaybe<NotificationWhereInput>;
};

export type QueryListPrefectureArgs = {
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<PrefectureWhereInput>;
};

export type QueryListQuestionnaireArgs = {
  orderBy?: InputMaybe<QuestionnaireOrderByInput>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<QuestionnaireWhereInput>;
};

export type QueryListServiceArgs = {
  orderBy?: InputMaybe<ServiceOrderByInput>;
  orderByItem?: InputMaybe<ServiceItemOrderByInput>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<ServiceWhereInput>;
};

export type QueryListServiceItemArgs = {
  order?: InputMaybe<ServiceItemOrderByInput>;
  where?: InputMaybe<ServiceItemWhereInput>;
};

export type QueryListServiceTargetArgs = {
  cursor?: InputMaybe<ServiceTargetCursorInput>;
  orderBy?: InputMaybe<ServiceTargetOrderByInput>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<ServiceTargetWhereInput>;
};

export type QueryListTopUsersArgs = {
  eventId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
};

export type QueryListUserArgs = {
  orderBy?: InputMaybe<UserOrderByInput>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<UserWhereInput>;
};

export type QueryListUserJoinedEventArgs = {
  eventId: Scalars['String'];
  orderBy?: InputMaybe<UsersJoinedEventOrderByInput>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<UsersJoinedEventWhereInput>;
};

export type QueryListUserServiceArgs = {
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<ServiceConfigWhereInput>;
};

export type QueryStampHistoryArgs = {
  eventId: Scalars['String'];
  from: Scalars['AWSDateTime'];
  to: Scalars['AWSDateTime'];
};

export type QueryVerifyChangeEmailArgs = {
  token: Scalars['String'];
};

export type QueryVerifyTokenArgs = {
  token: Scalars['String'];
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive',
}

export type QuestionInput = {
  answers?: InputMaybe<Array<InputMaybe<AnswerInput>>>;
  content?: InputMaybe<Scalars['String']>;
  explain?: InputMaybe<Scalars['String']>;
  groupAnswers?: InputMaybe<Array<InputMaybe<GroupAnswerInput>>>;
  highlyRatingLabel?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isRequired?: InputMaybe<Scalars['Boolean']>;
  isShowExplain?: InputMaybe<Scalars['Boolean']>;
  lowRatingLabel?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<QuestionType>;
};

export type QuestionListRelationFilter = {
  every?: InputMaybe<QuestionWhereInput>;
  none?: InputMaybe<QuestionWhereInput>;
  some?: InputMaybe<QuestionWhereInput>;
};

export type QuestionOrderByInput = {
  answers?: InputMaybe<AnswerOrderByRelationAggregateInput>;
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  explain?: InputMaybe<SortOrder>;
  groupAnswers?: InputMaybe<GroupAnswerOrderByRelationAggregateInput>;
  highlyRatingLabel?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isRequired?: InputMaybe<SortOrder>;
  isShowExplain?: InputMaybe<SortOrder>;
  lowRatingLabel?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  questionnaire?: InputMaybe<QuestionnaireOrderByInput>;
  questionnaireId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userQuestionnaireAnswers?: InputMaybe<UserQuestionnaireAnswerOrderByRelationAggregateInput>;
};

export type QuestionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type QuestionRelationFilter = {
  is?: InputMaybe<QuestionWhereInput>;
  isNot?: InputMaybe<QuestionWhereInput>;
};

export type QuestionResponse = {
  __typename?: 'QuestionResponse';
  answers?: Maybe<Array<Maybe<AnswerResponse>>>;
  content?: Maybe<Scalars['String']>;
  explain?: Maybe<Scalars['String']>;
  groupAnswers?: Maybe<Array<Maybe<GroupAnswerResponse>>>;
  highlyRatingLabel?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  isShowExplain?: Maybe<Scalars['Boolean']>;
  lowRatingLabel?: Maybe<Scalars['String']>;
  numOfUser?: Maybe<Scalars['Int']>;
  type?: Maybe<QuestionType>;
};

export enum QuestionType {
  CheckboxSelection = 'CheckboxSelection',
  Matrix = 'Matrix',
  RadioSelection = 'RadioSelection',
  Rating = 'Rating',
  SelectSelection = 'SelectSelection',
  Text = 'Text',
  TextArea = 'TextArea',
}

export type QuestionWhereInput = {
  AND?: InputMaybe<Array<QuestionWhereInput>>;
  NOT?: InputMaybe<Array<QuestionWhereInput>>;
  OR?: InputMaybe<Array<QuestionWhereInput>>;
  answers?: InputMaybe<AnswerListRelationFilter>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  explain?: InputMaybe<StringFilter>;
  groupAnswers?: InputMaybe<GroupAnswerListRelationFilter>;
  highlyRatingLabel?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isRequired?: InputMaybe<BooleanFilter>;
  isShowExplain?: InputMaybe<BooleanFilter>;
  lowRatingLabel?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  questionnaire?: InputMaybe<QuestionnaireRelationFilter>;
  questionnaireId?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumQuestionTypeFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  userQuestionnaireAnswers?: InputMaybe<UserQuestionnaireAnswerListRelationFilter>;
};

export enum QuestionnaireCategory {
  HealthCheckForm = 'HealthCheckForm',
  Questionnaire = 'Questionnaire',
}

export type QuestionnaireListRelationFilter = {
  every?: InputMaybe<QuestionnaireWhereInput>;
  none?: InputMaybe<QuestionnaireWhereInput>;
  some?: InputMaybe<QuestionnaireWhereInput>;
};

export type QuestionnaireOrderByInput = {
  category?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  finishedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  numOfUser?: InputMaybe<SortOrder>;
  questions?: InputMaybe<QuestionOrderByRelationAggregateInput>;
  startAt?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByInput>;
  userQuestionnaireAnswers?: InputMaybe<UserQuestionnaireAnswerOrderByRelationAggregateInput>;
};

export type QuestionnaireOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type QuestionnaireRelationFilter = {
  is?: InputMaybe<QuestionnaireWhereInput>;
  isNot?: InputMaybe<QuestionnaireWhereInput>;
};

export type QuestionnaireResponse = {
  __typename?: 'QuestionnaireResponse';
  category?: Maybe<QuestionnaireCategory>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  finishedAt?: Maybe<Scalars['AWSDateTime']>;
  id?: Maybe<Scalars['String']>;
  numOfUser?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  questions?: Maybe<Array<Maybe<QuestionResponse>>>;
  startAt?: Maybe<Scalars['AWSDateTime']>;
  status: QuestionnaireStatus;
  title?: Maybe<Scalars['String']>;
};

export enum QuestionnaireStatus {
  Public = 'PUBLIC',
  Unpublic = 'UNPUBLIC',
}

export type QuestionnaireWhereInput = {
  AND?: InputMaybe<Array<QuestionnaireWhereInput>>;
  NOT?: InputMaybe<Array<QuestionnaireWhereInput>>;
  OR?: InputMaybe<Array<QuestionnaireWhereInput>>;
  category?: InputMaybe<EnumQuestionnaireCategoryFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  createdBy?: InputMaybe<StringFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  finishedAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  numOfUser?: InputMaybe<IntFilter>;
  questions?: InputMaybe<QuestionListRelationFilter>;
  startAt?: InputMaybe<AwsDateTimeFilter>;
  status?: InputMaybe<EnumQuestionnaireStatusFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userQuestionnaireAnswers?: InputMaybe<UserQuestionnaireAnswerListRelationFilter>;
};

export type RateTotalSleepMode = {
  __typename?: 'RateTotalSleepMode';
  percentage?: Maybe<Scalars['Float']>;
  rate?: Maybe<SleepModeRate>;
  sleepMode?: Maybe<Scalars['Int']>;
  totalTime?: Maybe<Scalars['Float']>;
};

export type RecordCalorie = {
  __typename?: 'RecordCalorie';
  recordedAt?: Maybe<RecordedAtFromTo>;
  value?: Maybe<Scalars['Float']>;
};

export type RecordExercise = {
  __typename?: 'RecordExercise';
  endTime?: Maybe<Scalars['AWSDateTime']>;
  recordedAt?: Maybe<Scalars['AWSDateTime']>;
  startTime?: Maybe<Scalars['AWSDateTime']>;
  value?: Maybe<Scalars['Float']>;
};

export type RecordFatigue = {
  __typename?: 'RecordFatigue';
  recordedAt?: Maybe<RecordedAtFromTo>;
  value?: Maybe<Scalars['Float']>;
};

export type RecordGraphSleep = {
  __typename?: 'RecordGraphSleep';
  inSleepTime?: Maybe<Scalars['AWSDateTime']>;
  outSleepTime?: Maybe<Scalars['AWSDateTime']>;
  sleepRanges?: Maybe<Array<Maybe<RecordSleepRange>>>;
  sleepScore?: Maybe<Scalars['Float']>;
  totalBySleepMode?: Maybe<Array<Maybe<TotalTimeBySleepMode>>>;
  totalPerDay?: Maybe<Scalars['Float']>;
};

export type RecordHeartRate = {
  __typename?: 'RecordHeartRate';
  avgValue?: Maybe<Scalars['Float']>;
  maxValue?: Maybe<Scalars['Float']>;
  minValue?: Maybe<Scalars['Float']>;
  recordedAt?: Maybe<RecordedAtFromTo>;
};

export type RecordHistorySleep = {
  __typename?: 'RecordHistorySleep';
  recordedAt?: Maybe<RecordedAtFromTo>;
  value?: Maybe<Scalars['Float']>;
};

export type RecordPulseWave = {
  __typename?: 'RecordPulseWave';
  bulkModulus?: Maybe<Scalars['Float']>;
  closePressure?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  expansionRate?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  openPressure?: Maybe<Scalars['Float']>;
  recordedAt?: Maybe<Scalars['AWSDateTime']>;
  userGender?: Maybe<Gender>;
  userId?: Maybe<Scalars['String']>;
  vascularAge?: Maybe<Scalars['Float']>;
};

export type RecordSleepInput = {
  inSleepTime?: InputMaybe<Scalars['AWSDateTime']>;
  outSleepTime?: InputMaybe<Scalars['AWSDateTime']>;
  sleepRanges?: InputMaybe<Array<InputMaybe<SleepRangeInput>>>;
};

export type RecordSleepRange = {
  __typename?: 'RecordSleepRange';
  sleepMode?: Maybe<Scalars['Int']>;
  startTime?: Maybe<Scalars['AWSDateTime']>;
  stopTime?: Maybe<Scalars['AWSDateTime']>;
};

export type RecordStand = {
  __typename?: 'RecordStand';
  recordedAt?: Maybe<RecordedAtFromTo>;
  value?: Maybe<Scalars['Float']>;
};

export type RecordSyncDataInput = {
  avg_bpm?: InputMaybe<Scalars['Int']>;
  calorie?: InputMaybe<Scalars['Float']>;
  date_record?: InputMaybe<Scalars['AWSDateTime']>;
  distance?: InputMaybe<Scalars['Float']>;
  fatigue?: InputMaybe<Scalars['Int']>;
  max_bpm?: InputMaybe<Scalars['Int']>;
  min_bpm?: InputMaybe<Scalars['Int']>;
  second?: InputMaybe<Scalars['Int']>;
  sport_type?: InputMaybe<Scalars['Int']>;
  state_type?: InputMaybe<Scalars['Int']>;
  step?: InputMaybe<Scalars['Int']>;
};

export type RecordedAt = {
  __typename?: 'RecordedAt';
  from?: Maybe<Scalars['AWSDateTime']>;
  to?: Maybe<Scalars['AWSDateTime']>;
};

export type RecordedAtFromTo = {
  __typename?: 'RecordedAtFromTo';
  from?: Maybe<Scalars['AWSDateTime']>;
  to?: Maybe<Scalars['AWSDateTime']>;
};

export type RefreshTokenInput = {
  refreshToken: Scalars['String'];
};

export type RefreshTokenResponse = {
  __typename?: 'RefreshTokenResponse';
  expiredAt: Scalars['Int'];
  refreshToken: Scalars['String'];
  token: Scalars['String'];
};

export type RoleListRelationFilter = {
  every?: InputMaybe<RoleWhereInput>;
  none?: InputMaybe<RoleWhereInput>;
  some?: InputMaybe<RoleWhereInput>;
};

export enum RoleName {
  Company = 'COMPANY',
  CompanyMember = 'COMPANY_MEMBER',
  SystemAdmin = 'SYSTEM_ADMIN',
  User = 'USER',
}

export type RoleOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  roleName?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userRoles?: InputMaybe<UserRoleOrderByRelationAggregateInput>;
};

export type RoleOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type RoleRelationFilter = {
  is?: InputMaybe<RoleWhereInput>;
  isNot?: InputMaybe<RoleWhereInput>;
};

export type RoleWhereInput = {
  AND?: InputMaybe<Array<RoleWhereInput>>;
  NOT?: InputMaybe<Array<RoleWhereInput>>;
  OR?: InputMaybe<Array<RoleWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  roleName?: InputMaybe<EnumRoleNameFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  userRoles?: InputMaybe<UserRoleListRelationFilter>;
};

export type RunningCursorInput = {
  id?: InputMaybe<Scalars['String']>;
  userId_startTime_endTime?: InputMaybe<CursorUserIdStartTimeEndTime>;
};

export type RunningListRelationFilter = {
  every?: InputMaybe<RunningWhereInput>;
  none?: InputMaybe<RunningWhereInput>;
  some?: InputMaybe<RunningWhereInput>;
};

export type RunningOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  distance?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  endTime?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isInRunning?: InputMaybe<SortOrder>;
  startTime?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByInput>;
  userId?: InputMaybe<SortOrder>;
};

export type RunningOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type RunningRecord = {
  __typename?: 'RunningRecord';
  distance?: Maybe<Scalars['Float']>;
  endTime?: Maybe<Scalars['AWSDateTime']>;
  id?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['AWSDateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type RunningRelationFilter = {
  is?: InputMaybe<RunningWhereInput>;
  isNot?: InputMaybe<RunningWhereInput>;
};

export type RunningWhereInput = {
  AND?: InputMaybe<Array<RunningWhereInput>>;
  NOT?: InputMaybe<Array<RunningWhereInput>>;
  OR?: InputMaybe<Array<RunningWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  distance?: InputMaybe<FloatFilter>;
  duration?: InputMaybe<IntFilter>;
  endTime?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isInRunning?: InputMaybe<BooleanFilter>;
  startTime?: InputMaybe<AwsDateTimeFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type ServiceConfigListRelationFilter = {
  every?: InputMaybe<ServiceConfigWhereInput>;
  none?: InputMaybe<ServiceConfigWhereInput>;
  some?: InputMaybe<ServiceConfigWhereInput>;
};

export type ServiceConfigOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isShow?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  service?: InputMaybe<ServiceOrderByInput>;
  serviceId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByInput>;
  userId?: InputMaybe<SortOrder>;
};

export type ServiceConfigOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ServiceConfigRelationFilter = {
  is?: InputMaybe<ServiceConfigWhereInput>;
  isNot?: InputMaybe<ServiceConfigWhereInput>;
};

export type ServiceConfigResponse = {
  __typename?: 'ServiceConfigResponse';
  id?: Maybe<Scalars['String']>;
  isShow?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  service?: Maybe<ServiceResponse>;
  serviceId?: Maybe<Scalars['String']>;
  user?: Maybe<UserResponse>;
  userId?: Maybe<Scalars['String']>;
};

export type ServiceConfigWhereInput = {
  AND?: InputMaybe<Array<ServiceConfigWhereInput>>;
  NOT?: InputMaybe<Array<ServiceConfigWhereInput>>;
  OR?: InputMaybe<Array<ServiceConfigWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isShow?: InputMaybe<BooleanFilter>;
  order?: InputMaybe<IntFilter>;
  service?: InputMaybe<ServiceRelationFilter>;
  serviceId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type ServiceCountResponse = {
  __typename?: 'ServiceCountResponse';
  companyServices?: Maybe<Scalars['Int']>;
};

export enum ServiceItemKey {
  Calorie = 'calorie',
  ClosePressure = 'closePressure',
  Diastolic = 'diastolic',
  ExpansionRate = 'expansionRate',
  OpenPressure = 'openPressure',
  Systolic = 'systolic',
  Time = 'time',
  VascularAge = 'vascularAge',
  Weight = 'weight',
}

export type ServiceItemListRelationFilter = {
  every?: InputMaybe<ServiceItemWhereInput>;
  none?: InputMaybe<ServiceItemWhereInput>;
  some?: InputMaybe<ServiceItemWhereInput>;
};

export type ServiceItemOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  key?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  service?: InputMaybe<ServiceOrderByInput>;
  serviceId?: InputMaybe<SortOrder>;
  serviceItemTarget?: InputMaybe<ServiceItemTargetOrderByRelationAggregateInput>;
  unit?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userServiceItemDetails?: InputMaybe<UserServiceItemDetailOrderByRelationAggregateInput>;
};

export type ServiceItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ServiceItemRelationFilter = {
  is?: InputMaybe<ServiceItemWhereInput>;
  isNot?: InputMaybe<ServiceItemWhereInput>;
};

export type ServiceItemResponse = {
  __typename?: 'ServiceItemResponse';
  id?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['Float']>;
  targets?: Maybe<Array<Maybe<ServiceItemTarget>>>;
  unit?: Maybe<Scalars['String']>;
};

export type ServiceItemTarget = {
  __typename?: 'ServiceItemTarget';
  id?: Maybe<Scalars['String']>;
  serviceItem?: Maybe<ServiceItemResponse>;
  serviceItemId?: Maybe<Scalars['String']>;
  serviceTarget?: Maybe<ServiceTarget>;
  serviceTargetId?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['Float']>;
};

export type ServiceItemTargetInput = {
  serviceItemId: Scalars['String'];
  target: Scalars['Float'];
};

export type ServiceItemTargetListRelationFilter = {
  every?: InputMaybe<ServiceItemTargetWhereInput>;
  none?: InputMaybe<ServiceItemTargetWhereInput>;
  some?: InputMaybe<ServiceItemTargetWhereInput>;
};

export type ServiceItemTargetOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  serviceItem?: InputMaybe<ServiceItemOrderByInput>;
  serviceItemId?: InputMaybe<SortOrder>;
  serviceTarget?: InputMaybe<ServiceTargetOrderByInput>;
  serviceTargetId?: InputMaybe<SortOrder>;
  target?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ServiceItemTargetOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ServiceItemTargetRelationFilter = {
  is?: InputMaybe<ServiceItemTargetWhereInput>;
  isNot?: InputMaybe<ServiceItemTargetWhereInput>;
};

export type ServiceItemTargetWhereInput = {
  AND?: InputMaybe<Array<ServiceItemTargetWhereInput>>;
  NOT?: InputMaybe<Array<ServiceItemTargetWhereInput>>;
  OR?: InputMaybe<Array<ServiceItemTargetWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  serviceItem?: InputMaybe<ServiceItemRelationFilter>;
  serviceItemId?: InputMaybe<StringFilter>;
  serviceTarget?: InputMaybe<ServiceTargetRelationFilter>;
  serviceTargetId?: InputMaybe<StringFilter>;
  target?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type ServiceItemWhereInput = {
  AND?: InputMaybe<Array<ServiceItemWhereInput>>;
  NOT?: InputMaybe<Array<ServiceItemWhereInput>>;
  OR?: InputMaybe<Array<ServiceItemWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  key?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  service?: InputMaybe<ServiceRelationFilter>;
  serviceId?: InputMaybe<StringFilter>;
  serviceItemTarget?: InputMaybe<ServiceItemTargetListRelationFilter>;
  unit?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  userServiceItemDetails?: InputMaybe<UserServiceItemDetailListRelationFilter>;
};

export type ServiceListRelationFilter = {
  every?: InputMaybe<ServiceWhereInput>;
  none?: InputMaybe<ServiceWhereInput>;
  some?: InputMaybe<ServiceWhereInput>;
};

export type ServiceOrderByInput = {
  companyServices?: InputMaybe<CompanyServiceOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDefault?: InputMaybe<SortOrder>;
  isManual?: InputMaybe<SortOrder>;
  key?: InputMaybe<SortOrder>;
  limit?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  serviceConfigs?: InputMaybe<ServiceConfigOrderByRelationAggregateInput>;
  serviceItems?: InputMaybe<ServiceItemOrderByRelationAggregateInput>;
  serviceTargets?: InputMaybe<ServiceTargetOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userServices?: InputMaybe<UserServiceOrderByRelationAggregateInput>;
};

export type ServiceOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ServiceRelationFilter = {
  is?: InputMaybe<ServiceWhereInput>;
  isNot?: InputMaybe<ServiceWhereInput>;
};

export type ServiceResponse = {
  __typename?: 'ServiceResponse';
  _count?: Maybe<ServiceCountResponse>;
  companyId?: Maybe<Scalars['String']>;
  contractEndAt?: Maybe<Scalars['AWSDateTime']>;
  contractStartAt?: Maybe<Scalars['AWSDateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isDefault?: Maybe<Scalars['Boolean']>;
  isManual?: Maybe<Scalars['Boolean']>;
  itemsName?: Maybe<Array<Maybe<Scalars['String']>>>;
  key?: Maybe<ServiceType>;
  limit?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  numberUsers?: Maybe<Scalars['Int']>;
  order: Scalars['Int'];
  serviceItems?: Maybe<Array<Maybe<ServiceItemResponse>>>;
  status?: Maybe<ServiceStatus>;
};

export enum ServiceStatus {
  Public = 'PUBLIC',
  Unpublic = 'UNPUBLIC',
}

export type ServiceTarget = {
  __typename?: 'ServiceTarget';
  companyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  service?: Maybe<ServiceResponse>;
  serviceId?: Maybe<Scalars['String']>;
  serviceItemTargets?: Maybe<Array<Maybe<ServiceItemTarget>>>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
};

export type ServiceTargetCursorInput = {
  companyId_serviceId?: InputMaybe<CursorCompanyIdAndServiceId>;
  id?: InputMaybe<Scalars['String']>;
};

export type ServiceTargetInput = {
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  serviceId: Scalars['String'];
  serviceItemTargets: Array<ServiceItemTargetInput>;
};

export type ServiceTargetListRelationFilter = {
  every?: InputMaybe<ServiceTargetWhereInput>;
  none?: InputMaybe<ServiceTargetWhereInput>;
  some?: InputMaybe<ServiceTargetWhereInput>;
};

export type ServiceTargetOrderByInput = {
  company?: InputMaybe<CompanyOrderByInput>;
  companyId?: InputMaybe<SortOrder>;
  companyService?: InputMaybe<CompanyServiceOrderByInput>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  service?: InputMaybe<ServiceOrderByInput>;
  serviceId?: InputMaybe<SortOrder>;
  serviceItemTargets?: InputMaybe<ServiceItemTargetOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ServiceTargetOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ServiceTargetRelationFilter = {
  is?: InputMaybe<ServiceTargetWhereInput>;
  isNot?: InputMaybe<ServiceTargetWhereInput>;
};

export type ServiceTargetWhereInput = {
  AND?: InputMaybe<Array<ServiceTargetWhereInput>>;
  NOT?: InputMaybe<Array<ServiceTargetWhereInput>>;
  OR?: InputMaybe<Array<ServiceTargetWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  companyService?: InputMaybe<CompanyServiceRelationFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  service?: InputMaybe<ServiceRelationFilter>;
  serviceId?: InputMaybe<StringFilter>;
  serviceItemTargets?: InputMaybe<ServiceItemTargetListRelationFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export enum ServiceType {
  Aerobics = 'AEROBICS',
  AgeGlycation = 'AGE_GLYCATION',
  Badminton = 'BADMINTON',
  Basketball = 'BASKETBALL',
  BloodGlucose = 'BLOOD_GLUCOSE',
  BloodPressure = 'BLOOD_PRESSURE',
  CaloriesBurned = 'CALORIES_BURNED',
  CapillaryType = 'CAPILLARY_TYPE',
  Cycling = 'CYCLING',
  Dance = 'DANCE',
  Exercise = 'EXERCISE',
  ExerciseManagement = 'EXERCISE_MANAGEMENT',
  FatigueIndex = 'FATIGUE_INDEX',
  GripStrength = 'GRIP_STRENGTH',
  MountainClimbing = 'MOUNTAIN_CLIMBING',
  PulseWave = 'PULSE_WAVE',
  Running = 'RUNNING',
  Skate = 'SKATE',
  Soccer = 'SOCCER',
  StandTime = 'STAND_TIME',
  Swimming = 'SWIMMING',
  TableTennis = 'TABLE_TENNIS',
  Tennis = 'TENNIS',
  Volleyball = 'VOLLEYBALL',
  Walk = 'WALK',
  Yoga = 'YOGA',
}

export type ServiceWhereInput = {
  AND?: InputMaybe<Array<ServiceWhereInput>>;
  NOT?: InputMaybe<Array<ServiceWhereInput>>;
  OR?: InputMaybe<Array<ServiceWhereInput>>;
  companyServices?: InputMaybe<CompanyServiceListRelationFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isDefault?: InputMaybe<BooleanFilter>;
  isManual?: InputMaybe<BooleanFilter>;
  key?: InputMaybe<EnumServiceTypeFilter>;
  limit?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  serviceConfigs?: InputMaybe<ServiceConfigListRelationFilter>;
  serviceItems?: InputMaybe<ServiceItemListRelationFilter>;
  serviceTargets?: InputMaybe<ServiceTargetListRelationFilter>;
  status?: InputMaybe<EnumServiceStatusFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  userServices?: InputMaybe<UserServiceListRelationFilter>;
};

export type SignInInput = {
  captchaToken?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
  type?: InputMaybe<UserType>;
  userGroup: RoleName;
};

export type SignInResponse = {
  __typename?: 'SignInResponse';
  expiredAt: Scalars['Int'];
  groups: Array<Maybe<Scalars['String']>>;
  refreshToken?: Maybe<Scalars['String']>;
  token: Scalars['String'];
};

export type SignUpInput = {
  birthday: Scalars['AWSDateTime'];
  departmentId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  gender: Gender;
  height?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  occupation?: InputMaybe<Scalars['String']>;
  prefectureId?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Float']>;
};

export type SleepListRelationFilter = {
  every?: InputMaybe<SleepWhereInput>;
  none?: InputMaybe<SleepWhereInput>;
  some?: InputMaybe<SleepWhereInput>;
};

export type SleepModeRate = {
  __typename?: 'SleepModeRate';
  reference?: Maybe<SleepModeReference>;
  status?: Maybe<SleepModeStatus>;
};

export type SleepModeReference = {
  __typename?: 'SleepModeReference';
  from?: Maybe<Scalars['Float']>;
  to?: Maybe<Scalars['Float']>;
};

export enum SleepModeStatus {
  High = 'HIGH',
  Low = 'LOW',
  Normal = 'NORMAL',
}

export type SleepOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inSleepTime?: InputMaybe<SortOrder>;
  outSleepTime?: InputMaybe<SortOrder>;
  recordedAt?: InputMaybe<SortOrder>;
  sleepRanges?: InputMaybe<SleepRangeOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByInput>;
  userId?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type SleepOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type SleepRangeInput = {
  sleepMode?: InputMaybe<Scalars['Int']>;
  startTime?: InputMaybe<Scalars['AWSDateTime']>;
  stopTime?: InputMaybe<Scalars['AWSDateTime']>;
};

export type SleepRangeListRelationFilter = {
  every?: InputMaybe<SleepRangeWhereInput>;
  none?: InputMaybe<SleepRangeWhereInput>;
  some?: InputMaybe<SleepRangeWhereInput>;
};

export type SleepRangeOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  sleep?: InputMaybe<SleepOrderByInput>;
  sleepId?: InputMaybe<SortOrder>;
  sleepMode?: InputMaybe<SortOrder>;
  startTime?: InputMaybe<SortOrder>;
  stopTime?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SleepRangeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type SleepRangeRelationFilter = {
  is?: InputMaybe<SleepRangeWhereInput>;
  isNot?: InputMaybe<SleepRangeWhereInput>;
};

export type SleepRangeWhereInput = {
  AND?: InputMaybe<Array<SleepRangeWhereInput>>;
  NOT?: InputMaybe<Array<SleepRangeWhereInput>>;
  OR?: InputMaybe<Array<SleepRangeWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  sleep?: InputMaybe<SleepRelationFilter>;
  sleepId?: InputMaybe<StringFilter>;
  sleepMode?: InputMaybe<IntFilter>;
  startTime?: InputMaybe<AwsDateTimeFilter>;
  stopTime?: InputMaybe<AwsDateTimeFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type SleepRelationFilter = {
  is?: InputMaybe<SleepWhereInput>;
  isNot?: InputMaybe<SleepWhereInput>;
};

export type SleepWhereInput = {
  AND?: InputMaybe<Array<SleepWhereInput>>;
  NOT?: InputMaybe<Array<SleepWhereInput>>;
  OR?: InputMaybe<Array<SleepWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  inSleepTime?: InputMaybe<AwsDateTimeFilter>;
  outSleepTime?: InputMaybe<AwsDateTimeFilter>;
  recordedAt?: InputMaybe<AwsDateTimeFilter>;
  sleepRanges?: InputMaybe<SleepRangeListRelationFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  value?: InputMaybe<FloatFilter>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export type SportListRelationFilter = {
  every?: InputMaybe<SportWhereInput>;
  none?: InputMaybe<SportWhereInput>;
  some?: InputMaybe<SportWhereInput>;
};

export type SportOrderByInput = {
  calorie?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  distance?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  endTime?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isInSport?: InputMaybe<SortOrder>;
  sportType?: InputMaybe<SortOrder>;
  startTime?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByInput>;
  userId?: InputMaybe<SortOrder>;
};

export type SportOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type SportRecord = {
  __typename?: 'SportRecord';
  calorie?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  endTime?: Maybe<Scalars['AWSDateTime']>;
  id?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['AWSDateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type SportRelationFilter = {
  is?: InputMaybe<SportWhereInput>;
  isNot?: InputMaybe<SportWhereInput>;
};

export type SportWhereInput = {
  AND?: InputMaybe<Array<SportWhereInput>>;
  NOT?: InputMaybe<Array<SportWhereInput>>;
  OR?: InputMaybe<Array<SportWhereInput>>;
  calorie?: InputMaybe<FloatFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  distance?: InputMaybe<FloatFilter>;
  duration?: InputMaybe<IntFilter>;
  endTime?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isInSport?: InputMaybe<BooleanFilter>;
  sportType?: InputMaybe<IntFilter>;
  startTime?: InputMaybe<AwsDateTimeFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type StampHistoryResponse = {
  __typename?: 'StampHistoryResponse';
  isCompleted?: Maybe<Scalars['Boolean']>;
  recordedAt?: Maybe<Scalars['AWSDateTime']>;
  value?: Maybe<Scalars['Float']>;
};

export type StandListRelationFilter = {
  every?: InputMaybe<StandWhereInput>;
  none?: InputMaybe<StandWhereInput>;
  some?: InputMaybe<StandWhereInput>;
};

export type StandOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  endAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  recordedAt?: InputMaybe<SortOrder>;
  startAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByInput>;
  userId?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type StandOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type StandRelationFilter = {
  is?: InputMaybe<StandWhereInput>;
  isNot?: InputMaybe<StandWhereInput>;
};

export type StandWhereInput = {
  AND?: InputMaybe<Array<StandWhereInput>>;
  NOT?: InputMaybe<Array<StandWhereInput>>;
  OR?: InputMaybe<Array<StandWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  endAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  recordedAt?: InputMaybe<AwsDateTimeFilter>;
  startAt?: InputMaybe<AwsDateTimeFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  value?: InputMaybe<IntFilter>;
};

export type StepListRelationFilter = {
  every?: InputMaybe<StepWhereInput>;
  none?: InputMaybe<StepWhereInput>;
  some?: InputMaybe<StepWhereInput>;
};

export type StepOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  createdOrder?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  recordedAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByInput>;
  userId?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type StepOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type StepRelationFilter = {
  is?: InputMaybe<StepWhereInput>;
  isNot?: InputMaybe<StepWhereInput>;
};

export type StepWhereInput = {
  AND?: InputMaybe<Array<StepWhereInput>>;
  NOT?: InputMaybe<Array<StepWhereInput>>;
  OR?: InputMaybe<Array<StepWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  createdOrder?: InputMaybe<IntFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  recordedAt?: InputMaybe<AwsDateTimeFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  value?: InputMaybe<IntFilter>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  subscribeNotification?: Maybe<PublishNotificationResponse>;
};

export type SubscriptionSubscribeNotificationArgs = {
  receiverId: Scalars['String'];
};

export type SyncDataResponse = {
  __typename?: 'SyncDataResponse';
  isEmpty?: Maybe<Scalars['Boolean']>;
  notificationId?: Maybe<Scalars['String']>;
  syncingAt?: Maybe<Scalars['AWSDateTime']>;
};

export type SyncDataSessionListRelationFilter = {
  every?: InputMaybe<SyncDataSessionWhereInput>;
  none?: InputMaybe<SyncDataSessionWhereInput>;
  some?: InputMaybe<SyncDataSessionWhereInput>;
};

export type SyncDataSessionOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  endAt?: InputMaybe<SortOrder>;
  error?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  numberOfRetries?: InputMaybe<SortOrder>;
  startAt?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByInput>;
  userId?: InputMaybe<SortOrder>;
};

export type SyncDataSessionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type SyncDataSessionRelationFilter = {
  is?: InputMaybe<SyncDataSessionWhereInput>;
  isNot?: InputMaybe<SyncDataSessionWhereInput>;
};

export enum SyncDataSessionStatus {
  Pending = 'PENDING',
  Reject = 'REJECT',
  Success = 'SUCCESS',
}

export type SyncDataSessionWhereInput = {
  AND?: InputMaybe<Array<SyncDataSessionWhereInput>>;
  NOT?: InputMaybe<Array<SyncDataSessionWhereInput>>;
  OR?: InputMaybe<Array<SyncDataSessionWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  endAt?: InputMaybe<AwsDateTimeFilter>;
  error?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  numberOfRetries?: InputMaybe<IntFilter>;
  startAt?: InputMaybe<AwsDateTimeFilter>;
  status?: InputMaybe<EnumSyncDataSessionStatusFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type TokenListRelationFilter = {
  every?: InputMaybe<TokenWhereInput>;
  none?: InputMaybe<TokenWhereInput>;
  some?: InputMaybe<TokenWhereInput>;
};

export type TokenOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  expiredAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  token?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByInput>;
  userId?: InputMaybe<SortOrder>;
};

export type TokenOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TokenRelationFilter = {
  is?: InputMaybe<TokenWhereInput>;
  isNot?: InputMaybe<TokenWhereInput>;
};

export enum TokenType {
  ChangeEmail = 'CHANGE_EMAIL',
  ForgotPassword = 'FORGOT_PASSWORD',
  SetPassword = 'SET_PASSWORD',
}

export type TokenWhereInput = {
  AND?: InputMaybe<Array<TokenWhereInput>>;
  NOT?: InputMaybe<Array<TokenWhereInput>>;
  OR?: InputMaybe<Array<TokenWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  expiredAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  token?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumTokenTypeFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type TopUsersResponse = {
  __typename?: 'TopUsersResponse';
  name?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Float']>;
};

export type TotalTimeBySleepMode = {
  __typename?: 'TotalTimeBySleepMode';
  sleepMode?: Maybe<Scalars['Int']>;
  totalTime?: Maybe<Scalars['Float']>;
};

export type UpdateAgeGlycationInput = {
  id: Scalars['String'];
  recordedAt: Scalars['AWSDateTime'];
  value: Scalars['Float'];
};

export type UpdateBloodGlucoseInput = {
  id: Scalars['String'];
  recordedAt: Scalars['AWSDateTime'];
  value: Scalars['Float'];
};

export type UpdateBloodPressureInput = {
  diastolic: Scalars['Float'];
  id: Scalars['String'];
  recordedAt: Scalars['AWSDateTime'];
  systolic: Scalars['Float'];
};

export type UpdateCapillaryTypeInput = {
  id: Scalars['String'];
  recordedAt: Scalars['AWSDateTime'];
  value: Scalars['Float'];
};

export type UpdateCompanyDepartmentInput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type UpdateCompanyInput = {
  adminEmail?: InputMaybe<Scalars['String']>;
  businessForm?: InputMaybe<BusinessForm>;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  personInCharge?: InputMaybe<Scalars['String']>;
  serviceIds?: InputMaybe<Array<Scalars['String']>>;
  type?: InputMaybe<CompanyType>;
};

export type UpdateEventInput = {
  bannerKeyName?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate: Scalars['AWSDateTime'];
  evaluation?: InputMaybe<EventEvaluation>;
  eventCondition?: InputMaybe<CreateEventConditionInput>;
  eventId: Scalars['String'];
  format: EventFormat;
  mainMotionKeyName?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  startDate: Scalars['AWSDateTime'];
  subMotionKeyName?: InputMaybe<Scalars['String']>;
  targetValue?: InputMaybe<Scalars['Float']>;
  type: EventType;
};

export type UpdateExerciseManagementInput = {
  calorie: Scalars['Float'];
  id: Scalars['String'];
  metTypeId: Scalars['String'];
  recordedAt: Scalars['AWSDateTime'];
  time: Scalars['Float'];
  weight: Scalars['Float'];
};

export type UpdateGripStrengthInput = {
  id: Scalars['String'];
  recordedAt: Scalars['AWSDateTime'];
  value: Scalars['Float'];
};

export type UpdateGroupAnswerInput = {
  answers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id?: InputMaybe<Scalars['String']>;
};

export type UpdatePulseWaveInput = {
  bulkModulus: Scalars['Float'];
  closePressure: Scalars['Float'];
  expansionRate: Scalars['Float'];
  id: Scalars['String'];
  openPressure: Scalars['Float'];
  recordedAt: Scalars['AWSDateTime'];
  userGender?: InputMaybe<Gender>;
};

export type UpdateQuestionnaireInput = {
  category?: InputMaybe<QuestionnaireCategory>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  questions?: InputMaybe<Array<InputMaybe<QuestionInput>>>;
  status?: InputMaybe<QuestionnaireStatus>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateServiceInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  items?: InputMaybe<Array<InputMaybe<ItemServiceInput>>>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ServiceStatus>;
};

export type UpdateUserInput = {
  birthday: Scalars['AWSDateTime'];
  companyId?: InputMaybe<Scalars['String']>;
  departmentId?: InputMaybe<Scalars['String']>;
  gender: Gender;
  height?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  occupation?: InputMaybe<Scalars['String']>;
  prefectureId?: InputMaybe<Scalars['String']>;
  serviceIds: Array<InputMaybe<Scalars['String']>>;
  status?: InputMaybe<UserStatus>;
  userId: Scalars['String'];
  weight?: InputMaybe<Scalars['Float']>;
};

export type UserAnswerQuestionInput = {
  answers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  content?: InputMaybe<Scalars['String']>;
  groupAnswers?: InputMaybe<Array<InputMaybe<UpdateGroupAnswerInput>>>;
  id: Scalars['String'];
  type?: InputMaybe<QuestionType>;
};

export type UserAnswerQuestionnaireInput = {
  category?: InputMaybe<QuestionnaireCategory>;
  finishedAt?: InputMaybe<Scalars['AWSDateTime']>;
  id: Scalars['String'];
  questions?: InputMaybe<Array<InputMaybe<UserAnswerQuestionInput>>>;
  startAt?: InputMaybe<Scalars['AWSDateTime']>;
};

export type UserCompany = {
  __typename?: 'UserCompany';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UserJoinedEvent = {
  __typename?: 'UserJoinedEvent';
  birthday?: Maybe<Scalars['AWSDateTime']>;
  email: Scalars['String'];
  gender: Gender;
  joinDate?: Maybe<Scalars['AWSDateTime']>;
  name?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['Int']>;
  subId?: Maybe<Scalars['Int']>;
  type?: Maybe<UserType>;
  userId: Scalars['String'];
};

export type UserListRelationFilter = {
  every?: InputMaybe<UserWhereInput>;
  none?: InputMaybe<UserWhereInput>;
  some?: InputMaybe<UserWhereInput>;
};

export type UserOrderByInput = {
  accessToken?: InputMaybe<SortOrder>;
  address1?: InputMaybe<SortOrder>;
  address1Kana?: InputMaybe<SortOrder>;
  address2?: InputMaybe<SortOrder>;
  address2Kana?: InputMaybe<SortOrder>;
  ageGlycations?: InputMaybe<AgeGlycationOrderByRelationAggregateInput>;
  avatar?: InputMaybe<SortOrder>;
  birthday?: InputMaybe<SortOrder>;
  bloodGlucoses?: InputMaybe<BloodGlucoseOrderByRelationAggregateInput>;
  bloodPressures?: InputMaybe<BloodPressureOrderByRelationAggregateInput>;
  calories?: InputMaybe<CalorieOrderByRelationAggregateInput>;
  capillaryTypes?: InputMaybe<CapillaryTypeOrderByRelationAggregateInput>;
  city?: InputMaybe<SortOrder>;
  cityKana?: InputMaybe<SortOrder>;
  cognitoId?: InputMaybe<SortOrder>;
  company?: InputMaybe<CompanyOrderByInput>;
  companyId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  department?: InputMaybe<CompanyDepartmentOrderByInput>;
  departmentId?: InputMaybe<SortOrder>;
  deviceUsers?: InputMaybe<DeviceUserOrderByRelationAggregateInput>;
  email?: InputMaybe<SortOrder>;
  eventUsers?: InputMaybe<EventUserOrderByRelationAggregateInput>;
  exerciseManagements?: InputMaybe<ExerciseManagementOrderByRelationAggregateInput>;
  exercises?: InputMaybe<ExerciseOrderByRelationAggregateInput>;
  fatigue?: InputMaybe<FatigueOrderByRelationAggregateInput>;
  firebaseDeviceToken?: InputMaybe<SortOrder>;
  gender?: InputMaybe<SortOrder>;
  gripStrengths?: InputMaybe<GripStrengthOrderByRelationAggregateInput>;
  heartRates?: InputMaybe<HeartRateOrderByRelationAggregateInput>;
  height?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPrimary?: InputMaybe<SortOrder>;
  lastSignInAt?: InputMaybe<SortOrder>;
  lastSyncedAt?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  newEmail?: InputMaybe<SortOrder>;
  notificationReads?: InputMaybe<NotificationReadOrderByRelationAggregateInput>;
  notificationUsers?: InputMaybe<NotificationUserOrderByRelationAggregateInput>;
  occupation?: InputMaybe<SortOrder>;
  postalCode?: InputMaybe<SortOrder>;
  prefecture?: InputMaybe<PrefectureOrderByInput>;
  prefectureId?: InputMaybe<SortOrder>;
  pulseWaves?: InputMaybe<PulseWaveOrderByRelationAggregateInput>;
  questionnaires?: InputMaybe<QuestionnaireOrderByRelationAggregateInput>;
  registeredAt?: InputMaybe<SortOrder>;
  running?: InputMaybe<RunningOrderByRelationAggregateInput>;
  serviceConfigs?: InputMaybe<ServiceConfigOrderByRelationAggregateInput>;
  sleeps?: InputMaybe<SleepOrderByRelationAggregateInput>;
  sports?: InputMaybe<SportOrderByRelationAggregateInput>;
  stand?: InputMaybe<StandOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  statusModifiedAt?: InputMaybe<SortOrder>;
  steps?: InputMaybe<StepOrderByRelationAggregateInput>;
  subId?: InputMaybe<SortOrder>;
  subIdText?: InputMaybe<SortOrder>;
  syncDataSessions?: InputMaybe<SyncDataSessionOrderByRelationAggregateInput>;
  tokens?: InputMaybe<TokenOrderByRelationAggregateInput>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userQuestionnaireAnswers?: InputMaybe<UserQuestionnaireAnswerOrderByRelationAggregateInput>;
  userRoles?: InputMaybe<UserRoleOrderByRelationAggregateInput>;
  userServices?: InputMaybe<UserServiceOrderByRelationAggregateInput>;
  userSessions?: InputMaybe<UserSessionOrderByRelationAggregateInput>;
  walking?: InputMaybe<WalkingOrderByRelationAggregateInput>;
  weight?: InputMaybe<SortOrder>;
  weightLosses?: InputMaybe<WeightLossOrderByRelationAggregateInput>;
};

export type UserOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserQuestionnaireAnswerListRelationFilter = {
  every?: InputMaybe<UserQuestionnaireAnswerWhereInput>;
  none?: InputMaybe<UserQuestionnaireAnswerWhereInput>;
  some?: InputMaybe<UserQuestionnaireAnswerWhereInput>;
};

export type UserQuestionnaireAnswerOrderByInput = {
  answer?: InputMaybe<AnswerOrderByInput>;
  answerId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  groupAnswer?: InputMaybe<GroupAnswerOrderByInput>;
  groupAnswerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  question?: InputMaybe<QuestionOrderByInput>;
  questionId?: InputMaybe<SortOrder>;
  questionnaire?: InputMaybe<QuestionnaireOrderByInput>;
  questionnaireId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByInput>;
  userId?: InputMaybe<SortOrder>;
};

export type UserQuestionnaireAnswerOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserQuestionnaireAnswerRelationFilter = {
  is?: InputMaybe<UserQuestionnaireAnswerWhereInput>;
  isNot?: InputMaybe<UserQuestionnaireAnswerWhereInput>;
};

export type UserQuestionnaireAnswerWhereInput = {
  AND?: InputMaybe<Array<UserQuestionnaireAnswerWhereInput>>;
  NOT?: InputMaybe<Array<UserQuestionnaireAnswerWhereInput>>;
  OR?: InputMaybe<Array<UserQuestionnaireAnswerWhereInput>>;
  answer?: InputMaybe<AnswerRelationFilter>;
  answerId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  groupAnswer?: InputMaybe<GroupAnswerRelationFilter>;
  groupAnswerId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  question?: InputMaybe<QuestionRelationFilter>;
  questionId?: InputMaybe<StringFilter>;
  questionnaire?: InputMaybe<QuestionnaireRelationFilter>;
  questionnaireId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserRankData = {
  __typename?: 'UserRankData';
  currentRank?: Maybe<Scalars['Int']>;
  differenceNext?: Maybe<Scalars['Float']>;
  differencePrevious?: Maybe<Scalars['Float']>;
};

export type UserRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export type UserResponse = {
  __typename?: 'UserResponse';
  address1?: Maybe<Scalars['String']>;
  address1Kana?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address2Kana?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['AWSDateTime']>;
  city?: Maybe<Scalars['String']>;
  cityKana?: Maybe<Scalars['String']>;
  cognitoId?: Maybe<Scalars['String']>;
  company?: Maybe<CompanyResponse>;
  companyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  department?: Maybe<CompanyDepartmentResponse>;
  departmentId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  gender?: Maybe<Gender>;
  height?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  isPrimary?: Maybe<Scalars['Boolean']>;
  lastSignInAt?: Maybe<Scalars['AWSDateTime']>;
  lastSyncedAt?: Maybe<Scalars['AWSDateTime']>;
  name?: Maybe<Scalars['String']>;
  occupation?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  prefecture?: Maybe<PrefectureResponse>;
  prefectureId?: Maybe<Scalars['String']>;
  registeredAt?: Maybe<Scalars['AWSDateTime']>;
  status?: Maybe<UserStatus>;
  statusModifiedAt?: Maybe<Scalars['AWSDateTime']>;
  subId?: Maybe<Scalars['Int']>;
  type?: Maybe<UserType>;
  userRoles?: Maybe<Array<Maybe<RoleName>>>;
  userServices?: Maybe<Array<Maybe<UserServices>>>;
  weight?: Maybe<Scalars['Float']>;
};

export type UserRoleListRelationFilter = {
  every?: InputMaybe<UserRoleWhereInput>;
  none?: InputMaybe<UserRoleWhereInput>;
  some?: InputMaybe<UserRoleWhereInput>;
};

export type UserRoleOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  role?: InputMaybe<RoleOrderByInput>;
  roleId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByInput>;
  userId?: InputMaybe<SortOrder>;
};

export type UserRoleOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserRoleRelationFilter = {
  is?: InputMaybe<UserRoleWhereInput>;
  isNot?: InputMaybe<UserRoleWhereInput>;
};

export type UserRoleWhereInput = {
  AND?: InputMaybe<Array<UserRoleWhereInput>>;
  NOT?: InputMaybe<Array<UserRoleWhereInput>>;
  OR?: InputMaybe<Array<UserRoleWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  role?: InputMaybe<RoleRelationFilter>;
  roleId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserServiceDetail = {
  __typename?: 'UserServiceDetail';
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  id?: Maybe<Scalars['String']>;
  recordedAt?: Maybe<Scalars['AWSDateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userServiceItemDetails?: Maybe<Array<Maybe<UserServiceItemDetails>>>;
};

export type UserServiceDetailCursorInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type UserServiceDetailListRelationFilter = {
  every?: InputMaybe<UserServiceDetailWhereInput>;
  none?: InputMaybe<UserServiceDetailWhereInput>;
  some?: InputMaybe<UserServiceDetailWhereInput>;
};

export type UserServiceDetailOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  recordedAt?: InputMaybe<SortOrder>;
  serviceId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  userService?: InputMaybe<UserServiceOrderByInput>;
  userServiceItemDetails?: InputMaybe<UserServiceItemDetailOrderByRelationAggregateInput>;
};

export type UserServiceDetailOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserServiceDetailRelationFilter = {
  is?: InputMaybe<UserServiceDetailWhereInput>;
  isNot?: InputMaybe<UserServiceDetailWhereInput>;
};

export type UserServiceDetailWhereInput = {
  AND?: InputMaybe<Array<UserServiceDetailWhereInput>>;
  NOT?: InputMaybe<Array<UserServiceDetailWhereInput>>;
  OR?: InputMaybe<Array<UserServiceDetailWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  recordedAt?: InputMaybe<AwsDateTimeFilter>;
  serviceId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
  userService?: InputMaybe<UserServiceRelationFilter>;
  userServiceItemDetails?: InputMaybe<UserServiceItemDetailListRelationFilter>;
};

export type UserServiceItemDetail = {
  __typename?: 'UserServiceItemDetail';
  recordedAt?: Maybe<Scalars['AWSDateTime']>;
  value?: Maybe<Scalars['Float']>;
};

export type UserServiceItemDetailListRelationFilter = {
  every?: InputMaybe<UserServiceItemDetailWhereInput>;
  none?: InputMaybe<UserServiceItemDetailWhereInput>;
  some?: InputMaybe<UserServiceItemDetailWhereInput>;
};

export type UserServiceItemDetailOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  serviceItem?: InputMaybe<ServiceItemOrderByInput>;
  serviceItemId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userServiceDetail?: InputMaybe<UserServiceDetailOrderByInput>;
  userServiceDetailId?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type UserServiceItemDetailOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserServiceItemDetailRelationFilter = {
  is?: InputMaybe<UserServiceItemDetailWhereInput>;
  isNot?: InputMaybe<UserServiceItemDetailWhereInput>;
};

export type UserServiceItemDetailWhereInput = {
  AND?: InputMaybe<Array<UserServiceItemDetailWhereInput>>;
  NOT?: InputMaybe<Array<UserServiceItemDetailWhereInput>>;
  OR?: InputMaybe<Array<UserServiceItemDetailWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  serviceItem?: InputMaybe<ServiceItemRelationFilter>;
  serviceItemId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  userServiceDetail?: InputMaybe<UserServiceDetailRelationFilter>;
  userServiceDetailId?: InputMaybe<StringFilter>;
  value?: InputMaybe<FloatFilter>;
};

export type UserServiceItemDetails = {
  __typename?: 'UserServiceItemDetails';
  serviceItem?: Maybe<ServiceItemResponse>;
  serviceItemId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type UserServiceItemDetailsInput = {
  serviceItemId: Scalars['String'];
  value?: InputMaybe<Scalars['Float']>;
};

export type UserServiceListRelationFilter = {
  every?: InputMaybe<UserServiceWhereInput>;
  none?: InputMaybe<UserServiceWhereInput>;
  some?: InputMaybe<UserServiceWhereInput>;
};

export type UserServiceOrderByInput = {
  company?: InputMaybe<CompanyOrderByInput>;
  companyId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  service?: InputMaybe<ServiceOrderByInput>;
  serviceId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByInput>;
  userId?: InputMaybe<SortOrder>;
  userServiceDetails?: InputMaybe<UserServiceDetailOrderByRelationAggregateInput>;
};

export type UserServiceOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserServiceRecordInput = {
  id?: InputMaybe<Scalars['String']>;
  recordedAt: Scalars['String'];
  serviceId: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
  userServiceItemDetails: Array<UserServiceItemDetailsInput>;
};

export type UserServiceRelationFilter = {
  is?: InputMaybe<UserServiceWhereInput>;
  isNot?: InputMaybe<UserServiceWhereInput>;
};

export type UserServiceWhereInput = {
  AND?: InputMaybe<Array<UserServiceWhereInput>>;
  NOT?: InputMaybe<Array<UserServiceWhereInput>>;
  OR?: InputMaybe<Array<UserServiceWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  service?: InputMaybe<ServiceRelationFilter>;
  serviceId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  userServiceDetails?: InputMaybe<UserServiceDetailListRelationFilter>;
};

export type UserServices = {
  __typename?: 'UserServices';
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  serviceId?: Maybe<Scalars['String']>;
  serviceItems?: Maybe<Array<Maybe<ServiceItemResponse>>>;
};

export type UserSessionListRelationFilter = {
  every?: InputMaybe<UserSessionWhereInput>;
  none?: InputMaybe<UserSessionWhereInput>;
  some?: InputMaybe<UserSessionWhereInput>;
};

export type UserSessionOrderByInput = {
  accessToken?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByInput>;
  userId?: InputMaybe<SortOrder>;
};

export type UserSessionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserSessionRelationFilter = {
  is?: InputMaybe<UserSessionWhereInput>;
  isNot?: InputMaybe<UserSessionWhereInput>;
};

export type UserSessionWhereInput = {
  AND?: InputMaybe<Array<UserSessionWhereInput>>;
  NOT?: InputMaybe<Array<UserSessionWhereInput>>;
  OR?: InputMaybe<Array<UserSessionWhereInput>>;
  accessToken?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export enum UserStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Inactive = 'INACTIVE',
  Invited = 'INVITED',
}

export enum UserType {
  Btob = 'BTOB',
  Btoc = 'BTOC',
}

export type UserUpdateProfileInput = {
  avatar?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['AWSDateTime']>;
  departmentId?: InputMaybe<Scalars['String']>;
  firebaseDeviceToken?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  height?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  occupation?: InputMaybe<Scalars['String']>;
  prefectureId?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Float']>;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  accessToken?: InputMaybe<StringFilter>;
  address1?: InputMaybe<StringFilter>;
  address1Kana?: InputMaybe<StringFilter>;
  address2?: InputMaybe<StringFilter>;
  address2Kana?: InputMaybe<StringFilter>;
  ageGlycations?: InputMaybe<AgeGlycationListRelationFilter>;
  avatar?: InputMaybe<StringFilter>;
  birthday?: InputMaybe<AwsDateTimeFilter>;
  bloodGlucoses?: InputMaybe<BloodGlucoseListRelationFilter>;
  bloodPressures?: InputMaybe<BloodPressureListRelationFilter>;
  calories?: InputMaybe<CalorieListRelationFilter>;
  capillaryTypes?: InputMaybe<CapillaryTypeListRelationFilter>;
  city?: InputMaybe<StringFilter>;
  cityKana?: InputMaybe<StringFilter>;
  cognitoId?: InputMaybe<StringFilter>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  department?: InputMaybe<CompanyDepartmentRelationFilter>;
  departmentId?: InputMaybe<StringFilter>;
  deviceUsers?: InputMaybe<DeviceUserListRelationFilter>;
  email?: InputMaybe<StringFilter>;
  eventUsers?: InputMaybe<EventUserListRelationFilter>;
  exerciseManagements?: InputMaybe<ExerciseManagementListRelationFilter>;
  exercises?: InputMaybe<ExerciseListRelationFilter>;
  fatigue?: InputMaybe<FatigueListRelationFilter>;
  firebaseDeviceToken?: InputMaybe<StringFilter>;
  gender?: InputMaybe<EnumGenderFilter>;
  gripStrengths?: InputMaybe<GripStrengthListRelationFilter>;
  heartRates?: InputMaybe<HeartRateListRelationFilter>;
  height?: InputMaybe<FloatFilter>;
  id?: InputMaybe<StringFilter>;
  isPrimary?: InputMaybe<BooleanFilter>;
  lastSignInAt?: InputMaybe<AwsDateTimeFilter>;
  lastSyncedAt?: InputMaybe<AwsDateTimeFilter>;
  name?: InputMaybe<StringFilter>;
  newEmail?: InputMaybe<StringFilter>;
  notificationReads?: InputMaybe<NotificationReadListRelationFilter>;
  notificationUsers?: InputMaybe<NotificationUserListRelationFilter>;
  occupation?: InputMaybe<StringFilter>;
  postalCode?: InputMaybe<StringFilter>;
  prefecture?: InputMaybe<PrefectureRelationFilter>;
  prefectureId?: InputMaybe<StringFilter>;
  pulseWaves?: InputMaybe<PulseWaveListRelationFilter>;
  questionnaires?: InputMaybe<QuestionnaireListRelationFilter>;
  registeredAt?: InputMaybe<AwsDateTimeFilter>;
  running?: InputMaybe<RunningListRelationFilter>;
  serviceConfigs?: InputMaybe<ServiceConfigListRelationFilter>;
  sleeps?: InputMaybe<SleepListRelationFilter>;
  sports?: InputMaybe<SportListRelationFilter>;
  stand?: InputMaybe<StandListRelationFilter>;
  status?: InputMaybe<EnumUserStatusFilter>;
  statusModifiedAt?: InputMaybe<AwsDateTimeFilter>;
  steps?: InputMaybe<StepListRelationFilter>;
  subId?: InputMaybe<IntFilter>;
  subIdText?: InputMaybe<StringFilter>;
  syncDataSessions?: InputMaybe<SyncDataSessionListRelationFilter>;
  tokens?: InputMaybe<TokenListRelationFilter>;
  type?: InputMaybe<EnumUserTypeFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  userQuestionnaireAnswers?: InputMaybe<UserQuestionnaireAnswerListRelationFilter>;
  userRoles?: InputMaybe<UserRoleListRelationFilter>;
  userServices?: InputMaybe<UserServiceListRelationFilter>;
  userSessions?: InputMaybe<UserSessionListRelationFilter>;
  walking?: InputMaybe<WalkingListRelationFilter>;
  weight?: InputMaybe<FloatFilter>;
  weightLosses?: InputMaybe<WeightLossListRelationFilter>;
};

export type UsersJoinedEventOrderByInput = {
  joinDate?: InputMaybe<SortOrder>;
};

export type UsersJoinedEventResponse = {
  __typename?: 'UsersJoinedEventResponse';
  avg: Scalars['Float'];
  companyId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  female: Scalars['Int'];
  male: Scalars['Int'];
  other: Scalars['Int'];
  pagination?: Maybe<PaginationPageInfo>;
  participants: Scalars['Int'];
  sum: Scalars['Float'];
  type?: Maybe<EventType>;
  users: Array<Maybe<UserJoinedEvent>>;
};

export type UsersJoinedEventWhereInput = {
  birthday?: InputMaybe<Array<InputMaybe<Scalars['AWSDateTime']>>>;
  email?: InputMaybe<Scalars['String']>;
  joinDate?: InputMaybe<Array<InputMaybe<Scalars['AWSDateTime']>>>;
  name?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type WalkingCursorInput = {
  id?: InputMaybe<Scalars['String']>;
  userId_startTime_endTime?: InputMaybe<CursorUserIdStartTimeEndTime>;
};

export type WalkingListRelationFilter = {
  every?: InputMaybe<WalkingWhereInput>;
  none?: InputMaybe<WalkingWhereInput>;
  some?: InputMaybe<WalkingWhereInput>;
};

export type WalkingOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  distance?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  endTime?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isInWalking?: InputMaybe<SortOrder>;
  startTime?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByInput>;
  userId?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type WalkingOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type WalkingRecord = {
  __typename?: 'WalkingRecord';
  distance?: Maybe<Scalars['Float']>;
  endTime?: Maybe<Scalars['AWSDateTime']>;
  id?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['AWSDateTime']>;
  userId?: Maybe<Scalars['String']>;
  /** @deprecated Use 'distance' instead */
  value?: Maybe<Scalars['Int']>;
};

export type WalkingRelationFilter = {
  is?: InputMaybe<WalkingWhereInput>;
  isNot?: InputMaybe<WalkingWhereInput>;
};

export type WalkingWhereInput = {
  AND?: InputMaybe<Array<WalkingWhereInput>>;
  NOT?: InputMaybe<Array<WalkingWhereInput>>;
  OR?: InputMaybe<Array<WalkingWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  distance?: InputMaybe<FloatFilter>;
  duration?: InputMaybe<IntFilter>;
  endTime?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isInWalking?: InputMaybe<BooleanFilter>;
  startTime?: InputMaybe<AwsDateTimeFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  value?: InputMaybe<IntFilter>;
};

export type WeightLossCursorInput = {
  id?: InputMaybe<Scalars['String']>;
  userId_eventId_recordedAt?: InputMaybe<CursorUserIdAndEventIdAndRecordedAt>;
};

export type WeightLossListRelationFilter = {
  every?: InputMaybe<WeightLossWhereInput>;
  none?: InputMaybe<WeightLossWhereInput>;
  some?: InputMaybe<WeightLossWhereInput>;
};

export type WeightLossOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  createdOrder?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  event?: InputMaybe<EventOrderByInput>;
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  recordedAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByInput>;
  userId?: InputMaybe<SortOrder>;
  weight?: InputMaybe<SortOrder>;
  weightDiff?: InputMaybe<SortOrder>;
};

export type WeightLossOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type WeightLossRecord = {
  __typename?: 'WeightLossRecord';
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  eventId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  recordedAt?: Maybe<Scalars['AWSDateTime']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  weight?: Maybe<Scalars['Float']>;
};

export type WeightLossRelationFilter = {
  is?: InputMaybe<WeightLossWhereInput>;
  isNot?: InputMaybe<WeightLossWhereInput>;
};

export type WeightLossWhereInput = {
  AND?: InputMaybe<Array<WeightLossWhereInput>>;
  NOT?: InputMaybe<Array<WeightLossWhereInput>>;
  OR?: InputMaybe<Array<WeightLossWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  createdOrder?: InputMaybe<IntFilter>;
  deletedAt?: InputMaybe<AwsDateTimeFilter>;
  event?: InputMaybe<EventRelationFilter>;
  eventId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  recordedAt?: InputMaybe<AwsDateTimeFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  weight?: InputMaybe<FloatFilter>;
  weightDiff?: InputMaybe<FloatFilter>;
};

export type UpdateUserServiceConfigInput = {
  id: Scalars['String'];
  isShow: Scalars['Boolean'];
  order?: InputMaybe<Scalars['Int']>;
};

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs,
> {
  subscribe: SubscriptionSubscribeFn<
    { [key in TKey]: TResult },
    TParent,
    TContext,
    TArgs
  >;
  resolve?: SubscriptionResolveFn<
    TResult,
    { [key in TKey]: TResult },
    TContext,
    TArgs
  >;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs,
> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {},
> =
  | ((
      ...args: any[]
    ) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo,
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo,
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<
  TResult = {},
  TParent = {},
  TContext = {},
  TArgs = {},
> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AWSDate: ResolverTypeWrapper<Scalars['AWSDate']>;
  AWSDateTime: ResolverTypeWrapper<Scalars['AWSDateTime']>;
  AWSDateTimeFilter: AwsDateTimeFilter;
  AWSEmail: ResolverTypeWrapper<Scalars['AWSEmail']>;
  AWSIPAddress: ResolverTypeWrapper<Scalars['AWSIPAddress']>;
  AWSJSON: ResolverTypeWrapper<Scalars['AWSJSON']>;
  AWSPhone: ResolverTypeWrapper<Scalars['AWSPhone']>;
  AWSTime: ResolverTypeWrapper<Scalars['AWSTime']>;
  AWSTimestamp: ResolverTypeWrapper<Scalars['AWSTimestamp']>;
  AWSURL: ResolverTypeWrapper<Scalars['AWSURL']>;
  AgeGlycationCursorInput: AgeGlycationCursorInput;
  AgeGlycationListRelationFilter: AgeGlycationListRelationFilter;
  AgeGlycationOrderByInput: AgeGlycationOrderByInput;
  AgeGlycationOrderByRelationAggregateInput: AgeGlycationOrderByRelationAggregateInput;
  AgeGlycationRecord: ResolverTypeWrapper<AgeGlycationRecord>;
  AgeGlycationRelationFilter: AgeGlycationRelationFilter;
  AgeGlycationWhereInput: AgeGlycationWhereInput;
  AnswerInput: AnswerInput;
  AnswerListRelationFilter: AnswerListRelationFilter;
  AnswerOrderByInput: AnswerOrderByInput;
  AnswerOrderByRelationAggregateInput: AnswerOrderByRelationAggregateInput;
  AnswerRelationFilter: AnswerRelationFilter;
  AnswerResponse: ResolverTypeWrapper<AnswerResponse>;
  AnswerWhereInput: AnswerWhereInput;
  AppVersionListRelationFilter: AppVersionListRelationFilter;
  AppVersionOrderByInput: AppVersionOrderByInput;
  AppVersionOrderByRelationAggregateInput: AppVersionOrderByRelationAggregateInput;
  AppVersionRelationFilter: AppVersionRelationFilter;
  AppVersionResponse: ResolverTypeWrapper<AppVersionResponse>;
  AppVersionWhereInput: AppVersionWhereInput;
  BloodGlucoseCursorInput: BloodGlucoseCursorInput;
  BloodGlucoseListRelationFilter: BloodGlucoseListRelationFilter;
  BloodGlucoseOrderByInput: BloodGlucoseOrderByInput;
  BloodGlucoseOrderByRelationAggregateInput: BloodGlucoseOrderByRelationAggregateInput;
  BloodGlucoseRecord: ResolverTypeWrapper<BloodGlucoseRecord>;
  BloodGlucoseRelationFilter: BloodGlucoseRelationFilter;
  BloodGlucoseWhereInput: BloodGlucoseWhereInput;
  BloodPressureCursorInput: BloodPressureCursorInput;
  BloodPressureListRelationFilter: BloodPressureListRelationFilter;
  BloodPressureOrderByInput: BloodPressureOrderByInput;
  BloodPressureOrderByRelationAggregateInput: BloodPressureOrderByRelationAggregateInput;
  BloodPressureRecord: ResolverTypeWrapper<BloodPressureRecord>;
  BloodPressureRelationFilter: BloodPressureRelationFilter;
  BloodPressureWhereInput: BloodPressureWhereInput;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  BooleanFilter: BooleanFilter;
  BusinessForm: BusinessForm;
  CalorieListRelationFilter: CalorieListRelationFilter;
  CalorieOrderByInput: CalorieOrderByInput;
  CalorieOrderByRelationAggregateInput: CalorieOrderByRelationAggregateInput;
  CalorieRelationFilter: CalorieRelationFilter;
  CalorieWhereInput: CalorieWhereInput;
  CapillaryTypeCursorInput: CapillaryTypeCursorInput;
  CapillaryTypeListRelationFilter: CapillaryTypeListRelationFilter;
  CapillaryTypeOrderByInput: CapillaryTypeOrderByInput;
  CapillaryTypeOrderByRelationAggregateInput: CapillaryTypeOrderByRelationAggregateInput;
  CapillaryTypeRecord: ResolverTypeWrapper<CapillaryTypeRecord>;
  CapillaryTypeRelationFilter: CapillaryTypeRelationFilter;
  CapillaryTypeWhereInput: CapillaryTypeWhereInput;
  ChunkUnitOfTime: ChunkUnitOfTime;
  CompanyCreateEndUserInput: CompanyCreateEndUserInput;
  CompanyCreateMemberInput: CompanyCreateMemberInput;
  CompanyDepartmentListRelationFilter: CompanyDepartmentListRelationFilter;
  CompanyDepartmentOrderByInput: CompanyDepartmentOrderByInput;
  CompanyDepartmentOrderByRelationAggregateInput: CompanyDepartmentOrderByRelationAggregateInput;
  CompanyDepartmentRelationFilter: CompanyDepartmentRelationFilter;
  CompanyDepartmentResponse: ResolverTypeWrapper<CompanyDepartmentResponse>;
  CompanyDepartmentUserCount: ResolverTypeWrapper<CompanyDepartmentUserCount>;
  CompanyDepartmentWhereInput: CompanyDepartmentWhereInput;
  CompanyListRelationFilter: CompanyListRelationFilter;
  CompanyOrderByInput: CompanyOrderByInput;
  CompanyOrderByRelationAggregateInput: CompanyOrderByRelationAggregateInput;
  CompanyRelationFilter: CompanyRelationFilter;
  CompanyResponse: ResolverTypeWrapper<CompanyResponse>;
  CompanyServiceListRelationFilter: CompanyServiceListRelationFilter;
  CompanyServiceOrderByInput: CompanyServiceOrderByInput;
  CompanyServiceOrderByRelationAggregateInput: CompanyServiceOrderByRelationAggregateInput;
  CompanyServiceRelationFilter: CompanyServiceRelationFilter;
  CompanyServiceResponse: ResolverTypeWrapper<CompanyServiceResponse>;
  CompanyServiceWhereInput: CompanyServiceWhereInput;
  CompanyType: CompanyType;
  CompanyUpdateMemberInput: CompanyUpdateMemberInput;
  CompanyWhereInput: CompanyWhereInput;
  CreateAgeGlycationInput: CreateAgeGlycationInput;
  CreateBloodGlucoseInput: CreateBloodGlucoseInput;
  CreateBloodPressureInput: CreateBloodPressureInput;
  CreateCapillaryTypeInput: CreateCapillaryTypeInput;
  CreateCompanyInput: CreateCompanyInput;
  CreateEventConditionInput: CreateEventConditionInput;
  CreateEventInput: CreateEventInput;
  CreateExerciseManagementInput: CreateExerciseManagementInput;
  CreateGripStrengthInput: CreateGripStrengthInput;
  CreatePulseWaveInput: CreatePulseWaveInput;
  CreatePutPresignedUrlResponse: ResolverTypeWrapper<CreatePutPresignedUrlResponse>;
  CreateQuestionnaireInput: CreateQuestionnaireInput;
  CreateServiceInput: CreateServiceInput;
  CreateWeightLossInput: CreateWeightLossInput;
  CsvListUserResponse: ResolverTypeWrapper<CsvListUserResponse>;
  CursorCompanyIdAndServiceId: CursorCompanyIdAndServiceId;
  CursorUserIdAndEventIdAndRecordedAt: CursorUserIdAndEventIdAndRecordedAt;
  CursorUserIdAndRecordedAt: CursorUserIdAndRecordedAt;
  CursorUserIdStartTimeEndTime: CursorUserIdStartTimeEndTime;
  DeleteQuestionnaireInput: DeleteQuestionnaireInput;
  DetailEventResponse: ResolverTypeWrapper<DetailEventResponse>;
  DetailUserAnswerQuestionnaireInput: DetailUserAnswerQuestionnaireInput;
  DeviceListRelationFilter: DeviceListRelationFilter;
  DeviceOrderByInput: DeviceOrderByInput;
  DeviceOrderByRelationAggregateInput: DeviceOrderByRelationAggregateInput;
  DeviceRelationFilter: DeviceRelationFilter;
  DeviceUserListRelationFilter: DeviceUserListRelationFilter;
  DeviceUserOrderByInput: DeviceUserOrderByInput;
  DeviceUserOrderByRelationAggregateInput: DeviceUserOrderByRelationAggregateInput;
  DeviceUserRelationFilter: DeviceUserRelationFilter;
  DeviceUserWhereInput: DeviceUserWhereInput;
  DeviceWhereInput: DeviceWhereInput;
  DynamicLinkResponse: ResolverTypeWrapper<DynamicLinkResponse>;
  EnumBusinessFormFilter: EnumBusinessFormFilter;
  EnumCompanyTypeFilter: EnumCompanyTypeFilter;
  EnumEventEvaluationFilter: EnumEventEvaluationFilter;
  EnumEventFormatFilter: EnumEventFormatFilter;
  EnumEventTypeFilter: EnumEventTypeFilter;
  EnumGenderFilter: EnumGenderFilter;
  EnumNotificationCreatedByFilter: EnumNotificationCreatedByFilter;
  EnumNotificationTargetFilter: EnumNotificationTargetFilter;
  EnumNotificationTypeFilter: EnumNotificationTypeFilter;
  EnumQuestionTypeFilter: EnumQuestionTypeFilter;
  EnumQuestionnaireCategoryFilter: EnumQuestionnaireCategoryFilter;
  EnumQuestionnaireStatusFilter: EnumQuestionnaireStatusFilter;
  EnumRoleNameFilter: EnumRoleNameFilter;
  EnumServiceStatusFilter: EnumServiceStatusFilter;
  EnumServiceTypeFilter: EnumServiceTypeFilter;
  EnumSyncDataSessionStatusFilter: EnumSyncDataSessionStatusFilter;
  EnumTokenTypeFilter: EnumTokenTypeFilter;
  EnumUserStatusFilter: EnumUserStatusFilter;
  EnumUserTypeFilter: EnumUserTypeFilter;
  Event: ResolverTypeWrapper<Event>;
  EventCondition: ResolverTypeWrapper<EventCondition>;
  EventConditionListRelationFilter: EventConditionListRelationFilter;
  EventConditionOrderByInput: EventConditionOrderByInput;
  EventConditionOrderByRelationAggregateInput: EventConditionOrderByRelationAggregateInput;
  EventConditionRelationFilter: EventConditionRelationFilter;
  EventConditionWhereInput: EventConditionWhereInput;
  EventCursorInput: EventCursorInput;
  EventEvaluation: EventEvaluation;
  EventFormat: EventFormat;
  EventListRelationFilter: EventListRelationFilter;
  EventOrderByInput: EventOrderByInput;
  EventOrderByRelationAggregateInput: EventOrderByRelationAggregateInput;
  EventOverallData: ResolverTypeWrapper<EventOverallData>;
  EventRelationFilter: EventRelationFilter;
  EventType: EventType;
  EventUser: ResolverTypeWrapper<EventUser>;
  EventUserListRelationFilter: EventUserListRelationFilter;
  EventUserOrderByInput: EventUserOrderByInput;
  EventUserOrderByRelationAggregateInput: EventUserOrderByRelationAggregateInput;
  EventUserRelationFilter: EventUserRelationFilter;
  EventUserWhereInput: EventUserWhereInput;
  EventWhereInput: EventWhereInput;
  ExerciseListRelationFilter: ExerciseListRelationFilter;
  ExerciseManagementCursorInput: ExerciseManagementCursorInput;
  ExerciseManagementListRelationFilter: ExerciseManagementListRelationFilter;
  ExerciseManagementOrderByInput: ExerciseManagementOrderByInput;
  ExerciseManagementOrderByRelationAggregateInput: ExerciseManagementOrderByRelationAggregateInput;
  ExerciseManagementRecord: ResolverTypeWrapper<ExerciseManagementRecord>;
  ExerciseManagementRelationFilter: ExerciseManagementRelationFilter;
  ExerciseManagementWhereInput: ExerciseManagementWhereInput;
  ExerciseOrderByInput: ExerciseOrderByInput;
  ExerciseOrderByRelationAggregateInput: ExerciseOrderByRelationAggregateInput;
  ExerciseRelationFilter: ExerciseRelationFilter;
  ExerciseWhereInput: ExerciseWhereInput;
  ExportCSVEndUserInput: ExportCsvEndUserInput;
  ExportCSVEndUserResponse: ResolverTypeWrapper<ExportCsvEndUserResponse>;
  ExportQuestionnaireInput: ExportQuestionnaireInput;
  ExportQuestionnaireResponse: ResolverTypeWrapper<ExportQuestionnaireResponse>;
  FatigueCursorInput: FatigueCursorInput;
  FatigueListRelationFilter: FatigueListRelationFilter;
  FatigueOrderByInput: FatigueOrderByInput;
  FatigueOrderByRelationAggregateInput: FatigueOrderByRelationAggregateInput;
  FatigueRelationFilter: FatigueRelationFilter;
  FatigueWhereInput: FatigueWhereInput;
  FilterCsvInput: FilterCsvInput;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  FloatFilter: FloatFilter;
  Gender: Gender;
  GraphAgeGlycationResponse: ResolverTypeWrapper<GraphAgeGlycationResponse>;
  GraphBloodGlucoseResponse: ResolverTypeWrapper<GraphBloodGlucoseResponse>;
  GraphBloodPressureResponse: ResolverTypeWrapper<GraphBloodPressureResponse>;
  GraphCalorieResponse: ResolverTypeWrapper<GraphCalorieResponse>;
  GraphCapillaryTypeResponse: ResolverTypeWrapper<GraphCapillaryTypeResponse>;
  GraphExerciseManagementResponse: ResolverTypeWrapper<GraphExerciseManagementResponse>;
  GraphExerciseResponse: ResolverTypeWrapper<GraphExerciseResponse>;
  GraphFatigueResponse: ResolverTypeWrapper<GraphFatigueResponse>;
  GraphGripStrengthResponse: ResolverTypeWrapper<GraphGripStrengthResponse>;
  GraphHeartRateResponse: ResolverTypeWrapper<GraphHeartRateResponse>;
  GraphPulseWaveResponse: ResolverTypeWrapper<GraphPulseWaveResponse>;
  GraphSleepResponse: ResolverTypeWrapper<GraphSleepResponse>;
  GraphStandResponse: ResolverTypeWrapper<GraphStandResponse>;
  GraphStepInput: GraphStepInput;
  GraphStepRecord: ResolverTypeWrapper<GraphStepRecord>;
  GraphStepResponse: ResolverTypeWrapper<GraphStepResponse>;
  GraphUserServices: ResolverTypeWrapper<GraphUserServices>;
  GraphUserServicesInput: GraphUserServicesInput;
  GripStrengthCursorInput: GripStrengthCursorInput;
  GripStrengthListRelationFilter: GripStrengthListRelationFilter;
  GripStrengthOrderByInput: GripStrengthOrderByInput;
  GripStrengthOrderByRelationAggregateInput: GripStrengthOrderByRelationAggregateInput;
  GripStrengthRecord: ResolverTypeWrapper<GripStrengthRecord>;
  GripStrengthRelationFilter: GripStrengthRelationFilter;
  GripStrengthWhereInput: GripStrengthWhereInput;
  GroupAnswerInput: GroupAnswerInput;
  GroupAnswerListRelationFilter: GroupAnswerListRelationFilter;
  GroupAnswerOrderByInput: GroupAnswerOrderByInput;
  GroupAnswerOrderByRelationAggregateInput: GroupAnswerOrderByRelationAggregateInput;
  GroupAnswerRelationFilter: GroupAnswerRelationFilter;
  GroupAnswerResponse: ResolverTypeWrapper<GroupAnswerResponse>;
  GroupAnswerWhereInput: GroupAnswerWhereInput;
  HeartRateListRelationFilter: HeartRateListRelationFilter;
  HeartRateOrderByInput: HeartRateOrderByInput;
  HeartRateOrderByRelationAggregateInput: HeartRateOrderByRelationAggregateInput;
  HeartRateRelationFilter: HeartRateRelationFilter;
  HeartRateWhereInput: HeartRateWhereInput;
  HistoryAgeGlycationResponse: ResolverTypeWrapper<HistoryAgeGlycationResponse>;
  HistoryBloodGlucoseResponse: ResolverTypeWrapper<HistoryBloodGlucoseResponse>;
  HistoryBloodPressureResponse: ResolverTypeWrapper<HistoryBloodPressureResponse>;
  HistoryCalorieResponse: ResolverTypeWrapper<HistoryCalorieResponse>;
  HistoryCapillaryTypeResponse: ResolverTypeWrapper<HistoryCapillaryTypeResponse>;
  HistoryExerciseManagementResponse: ResolverTypeWrapper<HistoryExerciseManagementResponse>;
  HistoryExerciseResponse: ResolverTypeWrapper<HistoryExerciseResponse>;
  HistoryFatigueResponse: ResolverTypeWrapper<HistoryFatigueResponse>;
  HistoryGripStrengthResponse: ResolverTypeWrapper<HistoryGripStrengthResponse>;
  HistoryHeartRateResponse: ResolverTypeWrapper<HistoryHeartRateResponse>;
  HistoryPulseWaveResponse: ResolverTypeWrapper<HistoryPulseWaveResponse>;
  HistoryRecordFatigue: ResolverTypeWrapper<HistoryRecordFatigue>;
  HistoryRunningResponse: ResolverTypeWrapper<HistoryRunningResponse>;
  HistorySleepResponse: ResolverTypeWrapper<HistorySleepResponse>;
  HistorySportResponse: ResolverTypeWrapper<HistorySportResponse>;
  HistoryStandResponse: ResolverTypeWrapper<HistoryStandResponse>;
  HistoryStepResponse: ResolverTypeWrapper<HistoryStepResponse>;
  HistoryUserServicesInput: HistoryUserServicesInput;
  HistoryUserServicesRecord: ResolverTypeWrapper<HistoryUserServicesRecord>;
  HistoryWalkingResponse: ResolverTypeWrapper<HistoryWalkingResponse>;
  HistoryWeightLossResponse: ResolverTypeWrapper<HistoryWeightLossResponse>;
  HomeData: ResolverTypeWrapper<HomeData>;
  HomeDataKey: HomeDataKey;
  HomeResponse: ResolverTypeWrapper<HomeResponse>;
  ImportCSVEndUserError: ResolverTypeWrapper<ImportCsvEndUserError>;
  ImportCSVEndUserResponse: ResolverTypeWrapper<ImportCsvEndUserResponse>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  IntFilter: IntFilter;
  ItemServiceInput: ItemServiceInput;
  ListCompanyMemberResponse: ResolverTypeWrapper<ListCompanyMemberResponse>;
  ListCompanyResponse: ResolverTypeWrapper<ListCompanyResponse>;
  ListDepartmentResponse: ResolverTypeWrapper<ListDepartmentResponse>;
  ListEventsResponse: ResolverTypeWrapper<ListEventsResponse>;
  ListMetTypeResponse: ResolverTypeWrapper<ListMetTypeResponse>;
  ListNotificationResponse: ResolverTypeWrapper<ListNotificationResponse>;
  ListPrefectureResponse: ResolverTypeWrapper<ListPrefectureResponse>;
  ListQuestionnaireResponse: ResolverTypeWrapper<ListQuestionnaireResponse>;
  ListServiceConfigResponse: ResolverTypeWrapper<ListServiceConfigResponse>;
  ListServiceResponse: ResolverTypeWrapper<ListServiceResponse>;
  ListServiceTargetResponse: ResolverTypeWrapper<ListServiceTargetResponse>;
  ListUserResponse: ResolverTypeWrapper<ListUserResponse>;
  MetTypeInput: MetTypeInput;
  MetTypeListRelationFilter: MetTypeListRelationFilter;
  MetTypeOrderByInput: MetTypeOrderByInput;
  MetTypeOrderByRelationAggregateInput: MetTypeOrderByRelationAggregateInput;
  MetTypeRecord: ResolverTypeWrapper<MetTypeRecord>;
  MetTypeRelationFilter: MetTypeRelationFilter;
  MetTypeWhereInput: MetTypeWhereInput;
  Mutation: ResolverTypeWrapper<{}>;
  NestedStringFilter: NestedStringFilter;
  NotificationCompanies: ResolverTypeWrapper<NotificationCompanies>;
  NotificationCompanyListRelationFilter: NotificationCompanyListRelationFilter;
  NotificationCompanyOrderByInput: NotificationCompanyOrderByInput;
  NotificationCompanyOrderByRelationAggregateInput: NotificationCompanyOrderByRelationAggregateInput;
  NotificationCompanyRelationFilter: NotificationCompanyRelationFilter;
  NotificationCompanyWhereInput: NotificationCompanyWhereInput;
  NotificationCreatedBy: NotificationCreatedBy;
  NotificationDetailResponse: ResolverTypeWrapper<NotificationDetailResponse>;
  NotificationInput: NotificationInput;
  NotificationListRelationFilter: NotificationListRelationFilter;
  NotificationOrderByInput: NotificationOrderByInput;
  NotificationOrderByRelationAggregateInput: NotificationOrderByRelationAggregateInput;
  NotificationReadListRelationFilter: NotificationReadListRelationFilter;
  NotificationReadOrderByInput: NotificationReadOrderByInput;
  NotificationReadOrderByRelationAggregateInput: NotificationReadOrderByRelationAggregateInput;
  NotificationReadRelationFilter: NotificationReadRelationFilter;
  NotificationReadWhereInput: NotificationReadWhereInput;
  NotificationRelationFilter: NotificationRelationFilter;
  NotificationResponse: ResolverTypeWrapper<NotificationResponse>;
  NotificationTarget: NotificationTarget;
  NotificationType: NotificationType;
  NotificationUserListRelationFilter: NotificationUserListRelationFilter;
  NotificationUserOrderByInput: NotificationUserOrderByInput;
  NotificationUserOrderByRelationAggregateInput: NotificationUserOrderByRelationAggregateInput;
  NotificationUserRelationFilter: NotificationUserRelationFilter;
  NotificationUserWhereInput: NotificationUserWhereInput;
  NotificationUsers: ResolverTypeWrapper<NotificationUsers>;
  NotificationWhereInput: NotificationWhereInput;
  PaginationInput: PaginationInput;
  PaginationPageInfo: ResolverTypeWrapper<PaginationPageInfo>;
  PrefectureListRelationFilter: PrefectureListRelationFilter;
  PrefectureOrderByInput: PrefectureOrderByInput;
  PrefectureOrderByRelationAggregateInput: PrefectureOrderByRelationAggregateInput;
  PrefectureRelationFilter: PrefectureRelationFilter;
  PrefectureResponse: ResolverTypeWrapper<PrefectureResponse>;
  PrefectureWhereInput: PrefectureWhereInput;
  PublishNotificationResponse: ResolverTypeWrapper<PublishNotificationResponse>;
  PulseWaveCursorInput: PulseWaveCursorInput;
  PulseWaveListRelationFilter: PulseWaveListRelationFilter;
  PulseWaveOrderByInput: PulseWaveOrderByInput;
  PulseWaveOrderByRelationAggregateInput: PulseWaveOrderByRelationAggregateInput;
  PulseWaveRelationFilter: PulseWaveRelationFilter;
  PulseWaveTypeInput: PulseWaveTypeInput;
  PulseWaveWhereInput: PulseWaveWhereInput;
  Query: ResolverTypeWrapper<{}>;
  QueryMode: QueryMode;
  QuestionInput: QuestionInput;
  QuestionListRelationFilter: QuestionListRelationFilter;
  QuestionOrderByInput: QuestionOrderByInput;
  QuestionOrderByRelationAggregateInput: QuestionOrderByRelationAggregateInput;
  QuestionRelationFilter: QuestionRelationFilter;
  QuestionResponse: ResolverTypeWrapper<QuestionResponse>;
  QuestionType: QuestionType;
  QuestionWhereInput: QuestionWhereInput;
  QuestionnaireCategory: QuestionnaireCategory;
  QuestionnaireListRelationFilter: QuestionnaireListRelationFilter;
  QuestionnaireOrderByInput: QuestionnaireOrderByInput;
  QuestionnaireOrderByRelationAggregateInput: QuestionnaireOrderByRelationAggregateInput;
  QuestionnaireRelationFilter: QuestionnaireRelationFilter;
  QuestionnaireResponse: ResolverTypeWrapper<QuestionnaireResponse>;
  QuestionnaireStatus: QuestionnaireStatus;
  QuestionnaireWhereInput: QuestionnaireWhereInput;
  RateTotalSleepMode: ResolverTypeWrapper<RateTotalSleepMode>;
  RecordCalorie: ResolverTypeWrapper<RecordCalorie>;
  RecordExercise: ResolverTypeWrapper<RecordExercise>;
  RecordFatigue: ResolverTypeWrapper<RecordFatigue>;
  RecordGraphSleep: ResolverTypeWrapper<RecordGraphSleep>;
  RecordHeartRate: ResolverTypeWrapper<RecordHeartRate>;
  RecordHistorySleep: ResolverTypeWrapper<RecordHistorySleep>;
  RecordPulseWave: ResolverTypeWrapper<RecordPulseWave>;
  RecordSleepInput: RecordSleepInput;
  RecordSleepRange: ResolverTypeWrapper<RecordSleepRange>;
  RecordStand: ResolverTypeWrapper<RecordStand>;
  RecordSyncDataInput: RecordSyncDataInput;
  RecordedAt: ResolverTypeWrapper<RecordedAt>;
  RecordedAtFromTo: ResolverTypeWrapper<RecordedAtFromTo>;
  RefreshTokenInput: RefreshTokenInput;
  RefreshTokenResponse: ResolverTypeWrapper<RefreshTokenResponse>;
  RoleListRelationFilter: RoleListRelationFilter;
  RoleName: RoleName;
  RoleOrderByInput: RoleOrderByInput;
  RoleOrderByRelationAggregateInput: RoleOrderByRelationAggregateInput;
  RoleRelationFilter: RoleRelationFilter;
  RoleWhereInput: RoleWhereInput;
  RunningCursorInput: RunningCursorInput;
  RunningListRelationFilter: RunningListRelationFilter;
  RunningOrderByInput: RunningOrderByInput;
  RunningOrderByRelationAggregateInput: RunningOrderByRelationAggregateInput;
  RunningRecord: ResolverTypeWrapper<RunningRecord>;
  RunningRelationFilter: RunningRelationFilter;
  RunningWhereInput: RunningWhereInput;
  ServiceConfigListRelationFilter: ServiceConfigListRelationFilter;
  ServiceConfigOrderByInput: ServiceConfigOrderByInput;
  ServiceConfigOrderByRelationAggregateInput: ServiceConfigOrderByRelationAggregateInput;
  ServiceConfigRelationFilter: ServiceConfigRelationFilter;
  ServiceConfigResponse: ResolverTypeWrapper<ServiceConfigResponse>;
  ServiceConfigWhereInput: ServiceConfigWhereInput;
  ServiceCountResponse: ResolverTypeWrapper<ServiceCountResponse>;
  ServiceItemKey: ServiceItemKey;
  ServiceItemListRelationFilter: ServiceItemListRelationFilter;
  ServiceItemOrderByInput: ServiceItemOrderByInput;
  ServiceItemOrderByRelationAggregateInput: ServiceItemOrderByRelationAggregateInput;
  ServiceItemRelationFilter: ServiceItemRelationFilter;
  ServiceItemResponse: ResolverTypeWrapper<ServiceItemResponse>;
  ServiceItemTarget: ResolverTypeWrapper<ServiceItemTarget>;
  ServiceItemTargetInput: ServiceItemTargetInput;
  ServiceItemTargetListRelationFilter: ServiceItemTargetListRelationFilter;
  ServiceItemTargetOrderByInput: ServiceItemTargetOrderByInput;
  ServiceItemTargetOrderByRelationAggregateInput: ServiceItemTargetOrderByRelationAggregateInput;
  ServiceItemTargetRelationFilter: ServiceItemTargetRelationFilter;
  ServiceItemTargetWhereInput: ServiceItemTargetWhereInput;
  ServiceItemWhereInput: ServiceItemWhereInput;
  ServiceListRelationFilter: ServiceListRelationFilter;
  ServiceOrderByInput: ServiceOrderByInput;
  ServiceOrderByRelationAggregateInput: ServiceOrderByRelationAggregateInput;
  ServiceRelationFilter: ServiceRelationFilter;
  ServiceResponse: ResolverTypeWrapper<ServiceResponse>;
  ServiceStatus: ServiceStatus;
  ServiceTarget: ResolverTypeWrapper<ServiceTarget>;
  ServiceTargetCursorInput: ServiceTargetCursorInput;
  ServiceTargetInput: ServiceTargetInput;
  ServiceTargetListRelationFilter: ServiceTargetListRelationFilter;
  ServiceTargetOrderByInput: ServiceTargetOrderByInput;
  ServiceTargetOrderByRelationAggregateInput: ServiceTargetOrderByRelationAggregateInput;
  ServiceTargetRelationFilter: ServiceTargetRelationFilter;
  ServiceTargetWhereInput: ServiceTargetWhereInput;
  ServiceType: ServiceType;
  ServiceWhereInput: ServiceWhereInput;
  SignInInput: SignInInput;
  SignInResponse: ResolverTypeWrapper<SignInResponse>;
  SignUpInput: SignUpInput;
  SleepListRelationFilter: SleepListRelationFilter;
  SleepModeRate: ResolverTypeWrapper<SleepModeRate>;
  SleepModeReference: ResolverTypeWrapper<SleepModeReference>;
  SleepModeStatus: SleepModeStatus;
  SleepOrderByInput: SleepOrderByInput;
  SleepOrderByRelationAggregateInput: SleepOrderByRelationAggregateInput;
  SleepRangeInput: SleepRangeInput;
  SleepRangeListRelationFilter: SleepRangeListRelationFilter;
  SleepRangeOrderByInput: SleepRangeOrderByInput;
  SleepRangeOrderByRelationAggregateInput: SleepRangeOrderByRelationAggregateInput;
  SleepRangeRelationFilter: SleepRangeRelationFilter;
  SleepRangeWhereInput: SleepRangeWhereInput;
  SleepRelationFilter: SleepRelationFilter;
  SleepWhereInput: SleepWhereInput;
  SortOrder: SortOrder;
  SportListRelationFilter: SportListRelationFilter;
  SportOrderByInput: SportOrderByInput;
  SportOrderByRelationAggregateInput: SportOrderByRelationAggregateInput;
  SportRecord: ResolverTypeWrapper<SportRecord>;
  SportRelationFilter: SportRelationFilter;
  SportWhereInput: SportWhereInput;
  StampHistoryResponse: ResolverTypeWrapper<StampHistoryResponse>;
  StandListRelationFilter: StandListRelationFilter;
  StandOrderByInput: StandOrderByInput;
  StandOrderByRelationAggregateInput: StandOrderByRelationAggregateInput;
  StandRelationFilter: StandRelationFilter;
  StandWhereInput: StandWhereInput;
  StepListRelationFilter: StepListRelationFilter;
  StepOrderByInput: StepOrderByInput;
  StepOrderByRelationAggregateInput: StepOrderByRelationAggregateInput;
  StepRelationFilter: StepRelationFilter;
  StepWhereInput: StepWhereInput;
  String: ResolverTypeWrapper<Scalars['String']>;
  StringFilter: StringFilter;
  Subscription: ResolverTypeWrapper<{}>;
  SyncDataResponse: ResolverTypeWrapper<SyncDataResponse>;
  SyncDataSessionListRelationFilter: SyncDataSessionListRelationFilter;
  SyncDataSessionOrderByInput: SyncDataSessionOrderByInput;
  SyncDataSessionOrderByRelationAggregateInput: SyncDataSessionOrderByRelationAggregateInput;
  SyncDataSessionRelationFilter: SyncDataSessionRelationFilter;
  SyncDataSessionStatus: SyncDataSessionStatus;
  SyncDataSessionWhereInput: SyncDataSessionWhereInput;
  TokenListRelationFilter: TokenListRelationFilter;
  TokenOrderByInput: TokenOrderByInput;
  TokenOrderByRelationAggregateInput: TokenOrderByRelationAggregateInput;
  TokenRelationFilter: TokenRelationFilter;
  TokenType: TokenType;
  TokenWhereInput: TokenWhereInput;
  TopUsersResponse: ResolverTypeWrapper<TopUsersResponse>;
  TotalTimeBySleepMode: ResolverTypeWrapper<TotalTimeBySleepMode>;
  UpdateAgeGlycationInput: UpdateAgeGlycationInput;
  UpdateBloodGlucoseInput: UpdateBloodGlucoseInput;
  UpdateBloodPressureInput: UpdateBloodPressureInput;
  UpdateCapillaryTypeInput: UpdateCapillaryTypeInput;
  UpdateCompanyDepartmentInput: UpdateCompanyDepartmentInput;
  UpdateCompanyInput: UpdateCompanyInput;
  UpdateEventInput: UpdateEventInput;
  UpdateExerciseManagementInput: UpdateExerciseManagementInput;
  UpdateGripStrengthInput: UpdateGripStrengthInput;
  UpdateGroupAnswerInput: UpdateGroupAnswerInput;
  UpdatePulseWaveInput: UpdatePulseWaveInput;
  UpdateQuestionnaireInput: UpdateQuestionnaireInput;
  UpdateServiceInput: UpdateServiceInput;
  UpdateUserInput: UpdateUserInput;
  UserAnswerQuestionInput: UserAnswerQuestionInput;
  UserAnswerQuestionnaireInput: UserAnswerQuestionnaireInput;
  UserCompany: ResolverTypeWrapper<UserCompany>;
  UserJoinedEvent: ResolverTypeWrapper<UserJoinedEvent>;
  UserListRelationFilter: UserListRelationFilter;
  UserOrderByInput: UserOrderByInput;
  UserOrderByRelationAggregateInput: UserOrderByRelationAggregateInput;
  UserQuestionnaireAnswerListRelationFilter: UserQuestionnaireAnswerListRelationFilter;
  UserQuestionnaireAnswerOrderByInput: UserQuestionnaireAnswerOrderByInput;
  UserQuestionnaireAnswerOrderByRelationAggregateInput: UserQuestionnaireAnswerOrderByRelationAggregateInput;
  UserQuestionnaireAnswerRelationFilter: UserQuestionnaireAnswerRelationFilter;
  UserQuestionnaireAnswerWhereInput: UserQuestionnaireAnswerWhereInput;
  UserRankData: ResolverTypeWrapper<UserRankData>;
  UserRelationFilter: UserRelationFilter;
  UserResponse: ResolverTypeWrapper<UserResponse>;
  UserRoleListRelationFilter: UserRoleListRelationFilter;
  UserRoleOrderByInput: UserRoleOrderByInput;
  UserRoleOrderByRelationAggregateInput: UserRoleOrderByRelationAggregateInput;
  UserRoleRelationFilter: UserRoleRelationFilter;
  UserRoleWhereInput: UserRoleWhereInput;
  UserServiceDetail: ResolverTypeWrapper<UserServiceDetail>;
  UserServiceDetailCursorInput: UserServiceDetailCursorInput;
  UserServiceDetailListRelationFilter: UserServiceDetailListRelationFilter;
  UserServiceDetailOrderByInput: UserServiceDetailOrderByInput;
  UserServiceDetailOrderByRelationAggregateInput: UserServiceDetailOrderByRelationAggregateInput;
  UserServiceDetailRelationFilter: UserServiceDetailRelationFilter;
  UserServiceDetailWhereInput: UserServiceDetailWhereInput;
  UserServiceItemDetail: ResolverTypeWrapper<UserServiceItemDetail>;
  UserServiceItemDetailListRelationFilter: UserServiceItemDetailListRelationFilter;
  UserServiceItemDetailOrderByInput: UserServiceItemDetailOrderByInput;
  UserServiceItemDetailOrderByRelationAggregateInput: UserServiceItemDetailOrderByRelationAggregateInput;
  UserServiceItemDetailRelationFilter: UserServiceItemDetailRelationFilter;
  UserServiceItemDetailWhereInput: UserServiceItemDetailWhereInput;
  UserServiceItemDetails: ResolverTypeWrapper<UserServiceItemDetails>;
  UserServiceItemDetailsInput: UserServiceItemDetailsInput;
  UserServiceListRelationFilter: UserServiceListRelationFilter;
  UserServiceOrderByInput: UserServiceOrderByInput;
  UserServiceOrderByRelationAggregateInput: UserServiceOrderByRelationAggregateInput;
  UserServiceRecordInput: UserServiceRecordInput;
  UserServiceRelationFilter: UserServiceRelationFilter;
  UserServiceWhereInput: UserServiceWhereInput;
  UserServices: ResolverTypeWrapper<UserServices>;
  UserSessionListRelationFilter: UserSessionListRelationFilter;
  UserSessionOrderByInput: UserSessionOrderByInput;
  UserSessionOrderByRelationAggregateInput: UserSessionOrderByRelationAggregateInput;
  UserSessionRelationFilter: UserSessionRelationFilter;
  UserSessionWhereInput: UserSessionWhereInput;
  UserStatus: UserStatus;
  UserType: UserType;
  UserUpdateProfileInput: UserUpdateProfileInput;
  UserWhereInput: UserWhereInput;
  UsersJoinedEventOrderByInput: UsersJoinedEventOrderByInput;
  UsersJoinedEventResponse: ResolverTypeWrapper<UsersJoinedEventResponse>;
  UsersJoinedEventWhereInput: UsersJoinedEventWhereInput;
  WalkingCursorInput: WalkingCursorInput;
  WalkingListRelationFilter: WalkingListRelationFilter;
  WalkingOrderByInput: WalkingOrderByInput;
  WalkingOrderByRelationAggregateInput: WalkingOrderByRelationAggregateInput;
  WalkingRecord: ResolverTypeWrapper<WalkingRecord>;
  WalkingRelationFilter: WalkingRelationFilter;
  WalkingWhereInput: WalkingWhereInput;
  WeightLossCursorInput: WeightLossCursorInput;
  WeightLossListRelationFilter: WeightLossListRelationFilter;
  WeightLossOrderByInput: WeightLossOrderByInput;
  WeightLossOrderByRelationAggregateInput: WeightLossOrderByRelationAggregateInput;
  WeightLossRecord: ResolverTypeWrapper<WeightLossRecord>;
  WeightLossRelationFilter: WeightLossRelationFilter;
  WeightLossWhereInput: WeightLossWhereInput;
  updateUserServiceConfigInput: UpdateUserServiceConfigInput;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AWSDate: Scalars['AWSDate'];
  AWSDateTime: Scalars['AWSDateTime'];
  AWSDateTimeFilter: AwsDateTimeFilter;
  AWSEmail: Scalars['AWSEmail'];
  AWSIPAddress: Scalars['AWSIPAddress'];
  AWSJSON: Scalars['AWSJSON'];
  AWSPhone: Scalars['AWSPhone'];
  AWSTime: Scalars['AWSTime'];
  AWSTimestamp: Scalars['AWSTimestamp'];
  AWSURL: Scalars['AWSURL'];
  AgeGlycationCursorInput: AgeGlycationCursorInput;
  AgeGlycationListRelationFilter: AgeGlycationListRelationFilter;
  AgeGlycationOrderByInput: AgeGlycationOrderByInput;
  AgeGlycationOrderByRelationAggregateInput: AgeGlycationOrderByRelationAggregateInput;
  AgeGlycationRecord: AgeGlycationRecord;
  AgeGlycationRelationFilter: AgeGlycationRelationFilter;
  AgeGlycationWhereInput: AgeGlycationWhereInput;
  AnswerInput: AnswerInput;
  AnswerListRelationFilter: AnswerListRelationFilter;
  AnswerOrderByInput: AnswerOrderByInput;
  AnswerOrderByRelationAggregateInput: AnswerOrderByRelationAggregateInput;
  AnswerRelationFilter: AnswerRelationFilter;
  AnswerResponse: AnswerResponse;
  AnswerWhereInput: AnswerWhereInput;
  AppVersionListRelationFilter: AppVersionListRelationFilter;
  AppVersionOrderByInput: AppVersionOrderByInput;
  AppVersionOrderByRelationAggregateInput: AppVersionOrderByRelationAggregateInput;
  AppVersionRelationFilter: AppVersionRelationFilter;
  AppVersionResponse: AppVersionResponse;
  AppVersionWhereInput: AppVersionWhereInput;
  BloodGlucoseCursorInput: BloodGlucoseCursorInput;
  BloodGlucoseListRelationFilter: BloodGlucoseListRelationFilter;
  BloodGlucoseOrderByInput: BloodGlucoseOrderByInput;
  BloodGlucoseOrderByRelationAggregateInput: BloodGlucoseOrderByRelationAggregateInput;
  BloodGlucoseRecord: BloodGlucoseRecord;
  BloodGlucoseRelationFilter: BloodGlucoseRelationFilter;
  BloodGlucoseWhereInput: BloodGlucoseWhereInput;
  BloodPressureCursorInput: BloodPressureCursorInput;
  BloodPressureListRelationFilter: BloodPressureListRelationFilter;
  BloodPressureOrderByInput: BloodPressureOrderByInput;
  BloodPressureOrderByRelationAggregateInput: BloodPressureOrderByRelationAggregateInput;
  BloodPressureRecord: BloodPressureRecord;
  BloodPressureRelationFilter: BloodPressureRelationFilter;
  BloodPressureWhereInput: BloodPressureWhereInput;
  Boolean: Scalars['Boolean'];
  BooleanFilter: BooleanFilter;
  CalorieListRelationFilter: CalorieListRelationFilter;
  CalorieOrderByInput: CalorieOrderByInput;
  CalorieOrderByRelationAggregateInput: CalorieOrderByRelationAggregateInput;
  CalorieRelationFilter: CalorieRelationFilter;
  CalorieWhereInput: CalorieWhereInput;
  CapillaryTypeCursorInput: CapillaryTypeCursorInput;
  CapillaryTypeListRelationFilter: CapillaryTypeListRelationFilter;
  CapillaryTypeOrderByInput: CapillaryTypeOrderByInput;
  CapillaryTypeOrderByRelationAggregateInput: CapillaryTypeOrderByRelationAggregateInput;
  CapillaryTypeRecord: CapillaryTypeRecord;
  CapillaryTypeRelationFilter: CapillaryTypeRelationFilter;
  CapillaryTypeWhereInput: CapillaryTypeWhereInput;
  CompanyCreateEndUserInput: CompanyCreateEndUserInput;
  CompanyCreateMemberInput: CompanyCreateMemberInput;
  CompanyDepartmentListRelationFilter: CompanyDepartmentListRelationFilter;
  CompanyDepartmentOrderByInput: CompanyDepartmentOrderByInput;
  CompanyDepartmentOrderByRelationAggregateInput: CompanyDepartmentOrderByRelationAggregateInput;
  CompanyDepartmentRelationFilter: CompanyDepartmentRelationFilter;
  CompanyDepartmentResponse: CompanyDepartmentResponse;
  CompanyDepartmentUserCount: CompanyDepartmentUserCount;
  CompanyDepartmentWhereInput: CompanyDepartmentWhereInput;
  CompanyListRelationFilter: CompanyListRelationFilter;
  CompanyOrderByInput: CompanyOrderByInput;
  CompanyOrderByRelationAggregateInput: CompanyOrderByRelationAggregateInput;
  CompanyRelationFilter: CompanyRelationFilter;
  CompanyResponse: CompanyResponse;
  CompanyServiceListRelationFilter: CompanyServiceListRelationFilter;
  CompanyServiceOrderByInput: CompanyServiceOrderByInput;
  CompanyServiceOrderByRelationAggregateInput: CompanyServiceOrderByRelationAggregateInput;
  CompanyServiceRelationFilter: CompanyServiceRelationFilter;
  CompanyServiceResponse: CompanyServiceResponse;
  CompanyServiceWhereInput: CompanyServiceWhereInput;
  CompanyUpdateMemberInput: CompanyUpdateMemberInput;
  CompanyWhereInput: CompanyWhereInput;
  CreateAgeGlycationInput: CreateAgeGlycationInput;
  CreateBloodGlucoseInput: CreateBloodGlucoseInput;
  CreateBloodPressureInput: CreateBloodPressureInput;
  CreateCapillaryTypeInput: CreateCapillaryTypeInput;
  CreateCompanyInput: CreateCompanyInput;
  CreateEventConditionInput: CreateEventConditionInput;
  CreateEventInput: CreateEventInput;
  CreateExerciseManagementInput: CreateExerciseManagementInput;
  CreateGripStrengthInput: CreateGripStrengthInput;
  CreatePulseWaveInput: CreatePulseWaveInput;
  CreatePutPresignedUrlResponse: CreatePutPresignedUrlResponse;
  CreateQuestionnaireInput: CreateQuestionnaireInput;
  CreateServiceInput: CreateServiceInput;
  CreateWeightLossInput: CreateWeightLossInput;
  CsvListUserResponse: CsvListUserResponse;
  CursorCompanyIdAndServiceId: CursorCompanyIdAndServiceId;
  CursorUserIdAndEventIdAndRecordedAt: CursorUserIdAndEventIdAndRecordedAt;
  CursorUserIdAndRecordedAt: CursorUserIdAndRecordedAt;
  CursorUserIdStartTimeEndTime: CursorUserIdStartTimeEndTime;
  DeleteQuestionnaireInput: DeleteQuestionnaireInput;
  DetailEventResponse: DetailEventResponse;
  DetailUserAnswerQuestionnaireInput: DetailUserAnswerQuestionnaireInput;
  DeviceListRelationFilter: DeviceListRelationFilter;
  DeviceOrderByInput: DeviceOrderByInput;
  DeviceOrderByRelationAggregateInput: DeviceOrderByRelationAggregateInput;
  DeviceRelationFilter: DeviceRelationFilter;
  DeviceUserListRelationFilter: DeviceUserListRelationFilter;
  DeviceUserOrderByInput: DeviceUserOrderByInput;
  DeviceUserOrderByRelationAggregateInput: DeviceUserOrderByRelationAggregateInput;
  DeviceUserRelationFilter: DeviceUserRelationFilter;
  DeviceUserWhereInput: DeviceUserWhereInput;
  DeviceWhereInput: DeviceWhereInput;
  DynamicLinkResponse: DynamicLinkResponse;
  EnumBusinessFormFilter: EnumBusinessFormFilter;
  EnumCompanyTypeFilter: EnumCompanyTypeFilter;
  EnumEventEvaluationFilter: EnumEventEvaluationFilter;
  EnumEventFormatFilter: EnumEventFormatFilter;
  EnumEventTypeFilter: EnumEventTypeFilter;
  EnumGenderFilter: EnumGenderFilter;
  EnumNotificationCreatedByFilter: EnumNotificationCreatedByFilter;
  EnumNotificationTargetFilter: EnumNotificationTargetFilter;
  EnumNotificationTypeFilter: EnumNotificationTypeFilter;
  EnumQuestionTypeFilter: EnumQuestionTypeFilter;
  EnumQuestionnaireCategoryFilter: EnumQuestionnaireCategoryFilter;
  EnumQuestionnaireStatusFilter: EnumQuestionnaireStatusFilter;
  EnumRoleNameFilter: EnumRoleNameFilter;
  EnumServiceStatusFilter: EnumServiceStatusFilter;
  EnumServiceTypeFilter: EnumServiceTypeFilter;
  EnumSyncDataSessionStatusFilter: EnumSyncDataSessionStatusFilter;
  EnumTokenTypeFilter: EnumTokenTypeFilter;
  EnumUserStatusFilter: EnumUserStatusFilter;
  EnumUserTypeFilter: EnumUserTypeFilter;
  Event: Event;
  EventCondition: EventCondition;
  EventConditionListRelationFilter: EventConditionListRelationFilter;
  EventConditionOrderByInput: EventConditionOrderByInput;
  EventConditionOrderByRelationAggregateInput: EventConditionOrderByRelationAggregateInput;
  EventConditionRelationFilter: EventConditionRelationFilter;
  EventConditionWhereInput: EventConditionWhereInput;
  EventCursorInput: EventCursorInput;
  EventListRelationFilter: EventListRelationFilter;
  EventOrderByInput: EventOrderByInput;
  EventOrderByRelationAggregateInput: EventOrderByRelationAggregateInput;
  EventOverallData: EventOverallData;
  EventRelationFilter: EventRelationFilter;
  EventUser: EventUser;
  EventUserListRelationFilter: EventUserListRelationFilter;
  EventUserOrderByInput: EventUserOrderByInput;
  EventUserOrderByRelationAggregateInput: EventUserOrderByRelationAggregateInput;
  EventUserRelationFilter: EventUserRelationFilter;
  EventUserWhereInput: EventUserWhereInput;
  EventWhereInput: EventWhereInput;
  ExerciseListRelationFilter: ExerciseListRelationFilter;
  ExerciseManagementCursorInput: ExerciseManagementCursorInput;
  ExerciseManagementListRelationFilter: ExerciseManagementListRelationFilter;
  ExerciseManagementOrderByInput: ExerciseManagementOrderByInput;
  ExerciseManagementOrderByRelationAggregateInput: ExerciseManagementOrderByRelationAggregateInput;
  ExerciseManagementRecord: ExerciseManagementRecord;
  ExerciseManagementRelationFilter: ExerciseManagementRelationFilter;
  ExerciseManagementWhereInput: ExerciseManagementWhereInput;
  ExerciseOrderByInput: ExerciseOrderByInput;
  ExerciseOrderByRelationAggregateInput: ExerciseOrderByRelationAggregateInput;
  ExerciseRelationFilter: ExerciseRelationFilter;
  ExerciseWhereInput: ExerciseWhereInput;
  ExportCSVEndUserInput: ExportCsvEndUserInput;
  ExportCSVEndUserResponse: ExportCsvEndUserResponse;
  ExportQuestionnaireInput: ExportQuestionnaireInput;
  ExportQuestionnaireResponse: ExportQuestionnaireResponse;
  FatigueCursorInput: FatigueCursorInput;
  FatigueListRelationFilter: FatigueListRelationFilter;
  FatigueOrderByInput: FatigueOrderByInput;
  FatigueOrderByRelationAggregateInput: FatigueOrderByRelationAggregateInput;
  FatigueRelationFilter: FatigueRelationFilter;
  FatigueWhereInput: FatigueWhereInput;
  FilterCsvInput: FilterCsvInput;
  Float: Scalars['Float'];
  FloatFilter: FloatFilter;
  GraphAgeGlycationResponse: GraphAgeGlycationResponse;
  GraphBloodGlucoseResponse: GraphBloodGlucoseResponse;
  GraphBloodPressureResponse: GraphBloodPressureResponse;
  GraphCalorieResponse: GraphCalorieResponse;
  GraphCapillaryTypeResponse: GraphCapillaryTypeResponse;
  GraphExerciseManagementResponse: GraphExerciseManagementResponse;
  GraphExerciseResponse: GraphExerciseResponse;
  GraphFatigueResponse: GraphFatigueResponse;
  GraphGripStrengthResponse: GraphGripStrengthResponse;
  GraphHeartRateResponse: GraphHeartRateResponse;
  GraphPulseWaveResponse: GraphPulseWaveResponse;
  GraphSleepResponse: GraphSleepResponse;
  GraphStandResponse: GraphStandResponse;
  GraphStepInput: GraphStepInput;
  GraphStepRecord: GraphStepRecord;
  GraphStepResponse: GraphStepResponse;
  GraphUserServices: GraphUserServices;
  GraphUserServicesInput: GraphUserServicesInput;
  GripStrengthCursorInput: GripStrengthCursorInput;
  GripStrengthListRelationFilter: GripStrengthListRelationFilter;
  GripStrengthOrderByInput: GripStrengthOrderByInput;
  GripStrengthOrderByRelationAggregateInput: GripStrengthOrderByRelationAggregateInput;
  GripStrengthRecord: GripStrengthRecord;
  GripStrengthRelationFilter: GripStrengthRelationFilter;
  GripStrengthWhereInput: GripStrengthWhereInput;
  GroupAnswerInput: GroupAnswerInput;
  GroupAnswerListRelationFilter: GroupAnswerListRelationFilter;
  GroupAnswerOrderByInput: GroupAnswerOrderByInput;
  GroupAnswerOrderByRelationAggregateInput: GroupAnswerOrderByRelationAggregateInput;
  GroupAnswerRelationFilter: GroupAnswerRelationFilter;
  GroupAnswerResponse: GroupAnswerResponse;
  GroupAnswerWhereInput: GroupAnswerWhereInput;
  HeartRateListRelationFilter: HeartRateListRelationFilter;
  HeartRateOrderByInput: HeartRateOrderByInput;
  HeartRateOrderByRelationAggregateInput: HeartRateOrderByRelationAggregateInput;
  HeartRateRelationFilter: HeartRateRelationFilter;
  HeartRateWhereInput: HeartRateWhereInput;
  HistoryAgeGlycationResponse: HistoryAgeGlycationResponse;
  HistoryBloodGlucoseResponse: HistoryBloodGlucoseResponse;
  HistoryBloodPressureResponse: HistoryBloodPressureResponse;
  HistoryCalorieResponse: HistoryCalorieResponse;
  HistoryCapillaryTypeResponse: HistoryCapillaryTypeResponse;
  HistoryExerciseManagementResponse: HistoryExerciseManagementResponse;
  HistoryExerciseResponse: HistoryExerciseResponse;
  HistoryFatigueResponse: HistoryFatigueResponse;
  HistoryGripStrengthResponse: HistoryGripStrengthResponse;
  HistoryHeartRateResponse: HistoryHeartRateResponse;
  HistoryPulseWaveResponse: HistoryPulseWaveResponse;
  HistoryRecordFatigue: HistoryRecordFatigue;
  HistoryRunningResponse: HistoryRunningResponse;
  HistorySleepResponse: HistorySleepResponse;
  HistorySportResponse: HistorySportResponse;
  HistoryStandResponse: HistoryStandResponse;
  HistoryStepResponse: HistoryStepResponse;
  HistoryUserServicesInput: HistoryUserServicesInput;
  HistoryUserServicesRecord: HistoryUserServicesRecord;
  HistoryWalkingResponse: HistoryWalkingResponse;
  HistoryWeightLossResponse: HistoryWeightLossResponse;
  HomeData: HomeData;
  HomeResponse: HomeResponse;
  ImportCSVEndUserError: ImportCsvEndUserError;
  ImportCSVEndUserResponse: ImportCsvEndUserResponse;
  Int: Scalars['Int'];
  IntFilter: IntFilter;
  ItemServiceInput: ItemServiceInput;
  ListCompanyMemberResponse: ListCompanyMemberResponse;
  ListCompanyResponse: ListCompanyResponse;
  ListDepartmentResponse: ListDepartmentResponse;
  ListEventsResponse: ListEventsResponse;
  ListMetTypeResponse: ListMetTypeResponse;
  ListNotificationResponse: ListNotificationResponse;
  ListPrefectureResponse: ListPrefectureResponse;
  ListQuestionnaireResponse: ListQuestionnaireResponse;
  ListServiceConfigResponse: ListServiceConfigResponse;
  ListServiceResponse: ListServiceResponse;
  ListServiceTargetResponse: ListServiceTargetResponse;
  ListUserResponse: ListUserResponse;
  MetTypeInput: MetTypeInput;
  MetTypeListRelationFilter: MetTypeListRelationFilter;
  MetTypeOrderByInput: MetTypeOrderByInput;
  MetTypeOrderByRelationAggregateInput: MetTypeOrderByRelationAggregateInput;
  MetTypeRecord: MetTypeRecord;
  MetTypeRelationFilter: MetTypeRelationFilter;
  MetTypeWhereInput: MetTypeWhereInput;
  Mutation: {};
  NestedStringFilter: NestedStringFilter;
  NotificationCompanies: NotificationCompanies;
  NotificationCompanyListRelationFilter: NotificationCompanyListRelationFilter;
  NotificationCompanyOrderByInput: NotificationCompanyOrderByInput;
  NotificationCompanyOrderByRelationAggregateInput: NotificationCompanyOrderByRelationAggregateInput;
  NotificationCompanyRelationFilter: NotificationCompanyRelationFilter;
  NotificationCompanyWhereInput: NotificationCompanyWhereInput;
  NotificationDetailResponse: NotificationDetailResponse;
  NotificationInput: NotificationInput;
  NotificationListRelationFilter: NotificationListRelationFilter;
  NotificationOrderByInput: NotificationOrderByInput;
  NotificationOrderByRelationAggregateInput: NotificationOrderByRelationAggregateInput;
  NotificationReadListRelationFilter: NotificationReadListRelationFilter;
  NotificationReadOrderByInput: NotificationReadOrderByInput;
  NotificationReadOrderByRelationAggregateInput: NotificationReadOrderByRelationAggregateInput;
  NotificationReadRelationFilter: NotificationReadRelationFilter;
  NotificationReadWhereInput: NotificationReadWhereInput;
  NotificationRelationFilter: NotificationRelationFilter;
  NotificationResponse: NotificationResponse;
  NotificationUserListRelationFilter: NotificationUserListRelationFilter;
  NotificationUserOrderByInput: NotificationUserOrderByInput;
  NotificationUserOrderByRelationAggregateInput: NotificationUserOrderByRelationAggregateInput;
  NotificationUserRelationFilter: NotificationUserRelationFilter;
  NotificationUserWhereInput: NotificationUserWhereInput;
  NotificationUsers: NotificationUsers;
  NotificationWhereInput: NotificationWhereInput;
  PaginationInput: PaginationInput;
  PaginationPageInfo: PaginationPageInfo;
  PrefectureListRelationFilter: PrefectureListRelationFilter;
  PrefectureOrderByInput: PrefectureOrderByInput;
  PrefectureOrderByRelationAggregateInput: PrefectureOrderByRelationAggregateInput;
  PrefectureRelationFilter: PrefectureRelationFilter;
  PrefectureResponse: PrefectureResponse;
  PrefectureWhereInput: PrefectureWhereInput;
  PublishNotificationResponse: PublishNotificationResponse;
  PulseWaveCursorInput: PulseWaveCursorInput;
  PulseWaveListRelationFilter: PulseWaveListRelationFilter;
  PulseWaveOrderByInput: PulseWaveOrderByInput;
  PulseWaveOrderByRelationAggregateInput: PulseWaveOrderByRelationAggregateInput;
  PulseWaveRelationFilter: PulseWaveRelationFilter;
  PulseWaveTypeInput: PulseWaveTypeInput;
  PulseWaveWhereInput: PulseWaveWhereInput;
  Query: {};
  QuestionInput: QuestionInput;
  QuestionListRelationFilter: QuestionListRelationFilter;
  QuestionOrderByInput: QuestionOrderByInput;
  QuestionOrderByRelationAggregateInput: QuestionOrderByRelationAggregateInput;
  QuestionRelationFilter: QuestionRelationFilter;
  QuestionResponse: QuestionResponse;
  QuestionWhereInput: QuestionWhereInput;
  QuestionnaireListRelationFilter: QuestionnaireListRelationFilter;
  QuestionnaireOrderByInput: QuestionnaireOrderByInput;
  QuestionnaireOrderByRelationAggregateInput: QuestionnaireOrderByRelationAggregateInput;
  QuestionnaireRelationFilter: QuestionnaireRelationFilter;
  QuestionnaireResponse: QuestionnaireResponse;
  QuestionnaireWhereInput: QuestionnaireWhereInput;
  RateTotalSleepMode: RateTotalSleepMode;
  RecordCalorie: RecordCalorie;
  RecordExercise: RecordExercise;
  RecordFatigue: RecordFatigue;
  RecordGraphSleep: RecordGraphSleep;
  RecordHeartRate: RecordHeartRate;
  RecordHistorySleep: RecordHistorySleep;
  RecordPulseWave: RecordPulseWave;
  RecordSleepInput: RecordSleepInput;
  RecordSleepRange: RecordSleepRange;
  RecordStand: RecordStand;
  RecordSyncDataInput: RecordSyncDataInput;
  RecordedAt: RecordedAt;
  RecordedAtFromTo: RecordedAtFromTo;
  RefreshTokenInput: RefreshTokenInput;
  RefreshTokenResponse: RefreshTokenResponse;
  RoleListRelationFilter: RoleListRelationFilter;
  RoleOrderByInput: RoleOrderByInput;
  RoleOrderByRelationAggregateInput: RoleOrderByRelationAggregateInput;
  RoleRelationFilter: RoleRelationFilter;
  RoleWhereInput: RoleWhereInput;
  RunningCursorInput: RunningCursorInput;
  RunningListRelationFilter: RunningListRelationFilter;
  RunningOrderByInput: RunningOrderByInput;
  RunningOrderByRelationAggregateInput: RunningOrderByRelationAggregateInput;
  RunningRecord: RunningRecord;
  RunningRelationFilter: RunningRelationFilter;
  RunningWhereInput: RunningWhereInput;
  ServiceConfigListRelationFilter: ServiceConfigListRelationFilter;
  ServiceConfigOrderByInput: ServiceConfigOrderByInput;
  ServiceConfigOrderByRelationAggregateInput: ServiceConfigOrderByRelationAggregateInput;
  ServiceConfigRelationFilter: ServiceConfigRelationFilter;
  ServiceConfigResponse: ServiceConfigResponse;
  ServiceConfigWhereInput: ServiceConfigWhereInput;
  ServiceCountResponse: ServiceCountResponse;
  ServiceItemListRelationFilter: ServiceItemListRelationFilter;
  ServiceItemOrderByInput: ServiceItemOrderByInput;
  ServiceItemOrderByRelationAggregateInput: ServiceItemOrderByRelationAggregateInput;
  ServiceItemRelationFilter: ServiceItemRelationFilter;
  ServiceItemResponse: ServiceItemResponse;
  ServiceItemTarget: ServiceItemTarget;
  ServiceItemTargetInput: ServiceItemTargetInput;
  ServiceItemTargetListRelationFilter: ServiceItemTargetListRelationFilter;
  ServiceItemTargetOrderByInput: ServiceItemTargetOrderByInput;
  ServiceItemTargetOrderByRelationAggregateInput: ServiceItemTargetOrderByRelationAggregateInput;
  ServiceItemTargetRelationFilter: ServiceItemTargetRelationFilter;
  ServiceItemTargetWhereInput: ServiceItemTargetWhereInput;
  ServiceItemWhereInput: ServiceItemWhereInput;
  ServiceListRelationFilter: ServiceListRelationFilter;
  ServiceOrderByInput: ServiceOrderByInput;
  ServiceOrderByRelationAggregateInput: ServiceOrderByRelationAggregateInput;
  ServiceRelationFilter: ServiceRelationFilter;
  ServiceResponse: ServiceResponse;
  ServiceTarget: ServiceTarget;
  ServiceTargetCursorInput: ServiceTargetCursorInput;
  ServiceTargetInput: ServiceTargetInput;
  ServiceTargetListRelationFilter: ServiceTargetListRelationFilter;
  ServiceTargetOrderByInput: ServiceTargetOrderByInput;
  ServiceTargetOrderByRelationAggregateInput: ServiceTargetOrderByRelationAggregateInput;
  ServiceTargetRelationFilter: ServiceTargetRelationFilter;
  ServiceTargetWhereInput: ServiceTargetWhereInput;
  ServiceWhereInput: ServiceWhereInput;
  SignInInput: SignInInput;
  SignInResponse: SignInResponse;
  SignUpInput: SignUpInput;
  SleepListRelationFilter: SleepListRelationFilter;
  SleepModeRate: SleepModeRate;
  SleepModeReference: SleepModeReference;
  SleepOrderByInput: SleepOrderByInput;
  SleepOrderByRelationAggregateInput: SleepOrderByRelationAggregateInput;
  SleepRangeInput: SleepRangeInput;
  SleepRangeListRelationFilter: SleepRangeListRelationFilter;
  SleepRangeOrderByInput: SleepRangeOrderByInput;
  SleepRangeOrderByRelationAggregateInput: SleepRangeOrderByRelationAggregateInput;
  SleepRangeRelationFilter: SleepRangeRelationFilter;
  SleepRangeWhereInput: SleepRangeWhereInput;
  SleepRelationFilter: SleepRelationFilter;
  SleepWhereInput: SleepWhereInput;
  SportListRelationFilter: SportListRelationFilter;
  SportOrderByInput: SportOrderByInput;
  SportOrderByRelationAggregateInput: SportOrderByRelationAggregateInput;
  SportRecord: SportRecord;
  SportRelationFilter: SportRelationFilter;
  SportWhereInput: SportWhereInput;
  StampHistoryResponse: StampHistoryResponse;
  StandListRelationFilter: StandListRelationFilter;
  StandOrderByInput: StandOrderByInput;
  StandOrderByRelationAggregateInput: StandOrderByRelationAggregateInput;
  StandRelationFilter: StandRelationFilter;
  StandWhereInput: StandWhereInput;
  StepListRelationFilter: StepListRelationFilter;
  StepOrderByInput: StepOrderByInput;
  StepOrderByRelationAggregateInput: StepOrderByRelationAggregateInput;
  StepRelationFilter: StepRelationFilter;
  StepWhereInput: StepWhereInput;
  String: Scalars['String'];
  StringFilter: StringFilter;
  Subscription: {};
  SyncDataResponse: SyncDataResponse;
  SyncDataSessionListRelationFilter: SyncDataSessionListRelationFilter;
  SyncDataSessionOrderByInput: SyncDataSessionOrderByInput;
  SyncDataSessionOrderByRelationAggregateInput: SyncDataSessionOrderByRelationAggregateInput;
  SyncDataSessionRelationFilter: SyncDataSessionRelationFilter;
  SyncDataSessionWhereInput: SyncDataSessionWhereInput;
  TokenListRelationFilter: TokenListRelationFilter;
  TokenOrderByInput: TokenOrderByInput;
  TokenOrderByRelationAggregateInput: TokenOrderByRelationAggregateInput;
  TokenRelationFilter: TokenRelationFilter;
  TokenWhereInput: TokenWhereInput;
  TopUsersResponse: TopUsersResponse;
  TotalTimeBySleepMode: TotalTimeBySleepMode;
  UpdateAgeGlycationInput: UpdateAgeGlycationInput;
  UpdateBloodGlucoseInput: UpdateBloodGlucoseInput;
  UpdateBloodPressureInput: UpdateBloodPressureInput;
  UpdateCapillaryTypeInput: UpdateCapillaryTypeInput;
  UpdateCompanyDepartmentInput: UpdateCompanyDepartmentInput;
  UpdateCompanyInput: UpdateCompanyInput;
  UpdateEventInput: UpdateEventInput;
  UpdateExerciseManagementInput: UpdateExerciseManagementInput;
  UpdateGripStrengthInput: UpdateGripStrengthInput;
  UpdateGroupAnswerInput: UpdateGroupAnswerInput;
  UpdatePulseWaveInput: UpdatePulseWaveInput;
  UpdateQuestionnaireInput: UpdateQuestionnaireInput;
  UpdateServiceInput: UpdateServiceInput;
  UpdateUserInput: UpdateUserInput;
  UserAnswerQuestionInput: UserAnswerQuestionInput;
  UserAnswerQuestionnaireInput: UserAnswerQuestionnaireInput;
  UserCompany: UserCompany;
  UserJoinedEvent: UserJoinedEvent;
  UserListRelationFilter: UserListRelationFilter;
  UserOrderByInput: UserOrderByInput;
  UserOrderByRelationAggregateInput: UserOrderByRelationAggregateInput;
  UserQuestionnaireAnswerListRelationFilter: UserQuestionnaireAnswerListRelationFilter;
  UserQuestionnaireAnswerOrderByInput: UserQuestionnaireAnswerOrderByInput;
  UserQuestionnaireAnswerOrderByRelationAggregateInput: UserQuestionnaireAnswerOrderByRelationAggregateInput;
  UserQuestionnaireAnswerRelationFilter: UserQuestionnaireAnswerRelationFilter;
  UserQuestionnaireAnswerWhereInput: UserQuestionnaireAnswerWhereInput;
  UserRankData: UserRankData;
  UserRelationFilter: UserRelationFilter;
  UserResponse: UserResponse;
  UserRoleListRelationFilter: UserRoleListRelationFilter;
  UserRoleOrderByInput: UserRoleOrderByInput;
  UserRoleOrderByRelationAggregateInput: UserRoleOrderByRelationAggregateInput;
  UserRoleRelationFilter: UserRoleRelationFilter;
  UserRoleWhereInput: UserRoleWhereInput;
  UserServiceDetail: UserServiceDetail;
  UserServiceDetailCursorInput: UserServiceDetailCursorInput;
  UserServiceDetailListRelationFilter: UserServiceDetailListRelationFilter;
  UserServiceDetailOrderByInput: UserServiceDetailOrderByInput;
  UserServiceDetailOrderByRelationAggregateInput: UserServiceDetailOrderByRelationAggregateInput;
  UserServiceDetailRelationFilter: UserServiceDetailRelationFilter;
  UserServiceDetailWhereInput: UserServiceDetailWhereInput;
  UserServiceItemDetail: UserServiceItemDetail;
  UserServiceItemDetailListRelationFilter: UserServiceItemDetailListRelationFilter;
  UserServiceItemDetailOrderByInput: UserServiceItemDetailOrderByInput;
  UserServiceItemDetailOrderByRelationAggregateInput: UserServiceItemDetailOrderByRelationAggregateInput;
  UserServiceItemDetailRelationFilter: UserServiceItemDetailRelationFilter;
  UserServiceItemDetailWhereInput: UserServiceItemDetailWhereInput;
  UserServiceItemDetails: UserServiceItemDetails;
  UserServiceItemDetailsInput: UserServiceItemDetailsInput;
  UserServiceListRelationFilter: UserServiceListRelationFilter;
  UserServiceOrderByInput: UserServiceOrderByInput;
  UserServiceOrderByRelationAggregateInput: UserServiceOrderByRelationAggregateInput;
  UserServiceRecordInput: UserServiceRecordInput;
  UserServiceRelationFilter: UserServiceRelationFilter;
  UserServiceWhereInput: UserServiceWhereInput;
  UserServices: UserServices;
  UserSessionListRelationFilter: UserSessionListRelationFilter;
  UserSessionOrderByInput: UserSessionOrderByInput;
  UserSessionOrderByRelationAggregateInput: UserSessionOrderByRelationAggregateInput;
  UserSessionRelationFilter: UserSessionRelationFilter;
  UserSessionWhereInput: UserSessionWhereInput;
  UserUpdateProfileInput: UserUpdateProfileInput;
  UserWhereInput: UserWhereInput;
  UsersJoinedEventOrderByInput: UsersJoinedEventOrderByInput;
  UsersJoinedEventResponse: UsersJoinedEventResponse;
  UsersJoinedEventWhereInput: UsersJoinedEventWhereInput;
  WalkingCursorInput: WalkingCursorInput;
  WalkingListRelationFilter: WalkingListRelationFilter;
  WalkingOrderByInput: WalkingOrderByInput;
  WalkingOrderByRelationAggregateInput: WalkingOrderByRelationAggregateInput;
  WalkingRecord: WalkingRecord;
  WalkingRelationFilter: WalkingRelationFilter;
  WalkingWhereInput: WalkingWhereInput;
  WeightLossCursorInput: WeightLossCursorInput;
  WeightLossListRelationFilter: WeightLossListRelationFilter;
  WeightLossOrderByInput: WeightLossOrderByInput;
  WeightLossOrderByRelationAggregateInput: WeightLossOrderByRelationAggregateInput;
  WeightLossRecord: WeightLossRecord;
  WeightLossRelationFilter: WeightLossRelationFilter;
  WeightLossWhereInput: WeightLossWhereInput;
  updateUserServiceConfigInput: UpdateUserServiceConfigInput;
};

export type Aws_Api_KeyDirectiveArgs = {};

export type Aws_Api_KeyDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = Aws_Api_KeyDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_AuthDirectiveArgs = {
  cognito_groups: Array<Scalars['String']>;
};

export type Aws_AuthDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = Aws_AuthDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_Cognito_User_PoolsDirectiveArgs = {
  cognito_groups?: Maybe<Array<Scalars['String']>>;
};

export type Aws_Cognito_User_PoolsDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = Aws_Cognito_User_PoolsDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_IamDirectiveArgs = {};

export type Aws_IamDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = Aws_IamDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_OidcDirectiveArgs = {};

export type Aws_OidcDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = Aws_OidcDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_SubscribeDirectiveArgs = {
  mutations: Array<Scalars['String']>;
};

export type Aws_SubscribeDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = Aws_SubscribeDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export interface AwsDateScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['AWSDate'], any> {
  name: 'AWSDate';
}

export interface AwsDateTimeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['AWSDateTime'], any> {
  name: 'AWSDateTime';
}

export interface AwsEmailScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['AWSEmail'], any> {
  name: 'AWSEmail';
}

export interface AwsipAddressScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['AWSIPAddress'], any> {
  name: 'AWSIPAddress';
}

export interface AwsjsonScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['AWSJSON'], any> {
  name: 'AWSJSON';
}

export interface AwsPhoneScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['AWSPhone'], any> {
  name: 'AWSPhone';
}

export interface AwsTimeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['AWSTime'], any> {
  name: 'AWSTime';
}

export interface AwsTimestampScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['AWSTimestamp'], any> {
  name: 'AWSTimestamp';
}

export interface AwsurlScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['AWSURL'], any> {
  name: 'AWSURL';
}

export type AgeGlycationRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AgeGlycationRecord'] = ResolversParentTypes['AgeGlycationRecord'],
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recordedAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnswerResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AnswerResponse'] = ResolversParentTypes['AnswerResponse'],
> = {
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isSelected?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  numOfUser?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppVersionResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AppVersionResponse'] = ResolversParentTypes['AppVersionResponse'],
> = {
  androidVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  iosVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  minAndroidVersion?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  minIosVersion?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BloodGlucoseRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BloodGlucoseRecord'] = ResolversParentTypes['BloodGlucoseRecord'],
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recordedAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BloodPressureRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BloodPressureRecord'] = ResolversParentTypes['BloodPressureRecord'],
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  diastolic?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recordedAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  systolic?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CapillaryTypeRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CapillaryTypeRecord'] = ResolversParentTypes['CapillaryTypeRecord'],
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recordedAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyDepartmentResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyDepartmentResponse'] = ResolversParentTypes['CompanyDepartmentResponse'],
> = {
  _count?: Resolver<
    Maybe<ResolversTypes['CompanyDepartmentUserCount']>,
    ParentType,
    ContextType
  >;
  companyId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyDepartmentUserCountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyDepartmentUserCount'] = ResolversParentTypes['CompanyDepartmentUserCount'],
> = {
  users?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyResponse'] = ResolversParentTypes['CompanyResponse'],
> = {
  address1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address1Kana?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  address2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address2Kana?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  adminEmail?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  businessForm?: Resolver<
    Maybe<ResolversTypes['BusinessForm']>,
    ParentType,
    ContextType
  >;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cityKana?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  companyServices?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CompanyServiceResponse']>>>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  personInCharge?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  postalCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  prefecture?: Resolver<
    Maybe<ResolversTypes['PrefectureResponse']>,
    ParentType,
    ContextType
  >;
  prefectureId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<
    Maybe<ResolversTypes['UserStatus']>,
    ParentType,
    ContextType
  >;
  statusModifiedAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  type?: Resolver<
    Maybe<ResolversTypes['CompanyType']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyServiceResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyServiceResponse'] = ResolversParentTypes['CompanyServiceResponse'],
> = {
  companyId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serviceId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreatePutPresignedUrlResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreatePutPresignedUrlResponse'] = ResolversParentTypes['CreatePutPresignedUrlResponse'],
> = {
  fileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CsvListUserResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CsvListUserResponse'] = ResolversParentTypes['CsvListUserResponse'],
> = {
  fileUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  page?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  perPage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DetailEventResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DetailEventResponse'] = ResolversParentTypes['DetailEventResponse'],
> = {
  data?: Resolver<Maybe<ResolversTypes['Event']>, ParentType, ContextType>;
  overallData?: Resolver<
    Maybe<ResolversTypes['EventOverallData']>,
    ParentType,
    ContextType
  >;
  rankData?: Resolver<
    Maybe<ResolversTypes['UserRankData']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DynamicLinkResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DynamicLinkResponse'] = ResolversParentTypes['DynamicLinkResponse'],
> = {
  dynamicLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Event'] = ResolversParentTypes['Event'],
> = {
  bannerImageUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  companyId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  companyName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  endDate?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  evaluation?: Resolver<
    Maybe<ResolversTypes['EventEvaluation']>,
    ParentType,
    ContextType
  >;
  eventCondition?: Resolver<
    Maybe<ResolversTypes['EventCondition']>,
    ParentType,
    ContextType
  >;
  eventUsers?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['EventUser']>>>,
    ParentType,
    ContextType
  >;
  format?: Resolver<
    Maybe<ResolversTypes['EventFormat']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isUserJoined?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  mainMotionImageUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDate?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  subMotionImageUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  targetValue?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalUsers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['EventType']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventConditionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventCondition'] = ResolversParentTypes['EventCondition'],
> = {
  department?: Resolver<
    Maybe<ResolversTypes['CompanyDepartmentResponse']>,
    ParentType,
    ContextType
  >;
  enableUserAge?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  enableUserDepartment?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  enableUserGender?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  enableUserHistory?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  eventId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userAgeFrom?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  userAgeTo?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  userDepartmentId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  userGender?: Resolver<
    Maybe<ResolversTypes['Gender']>,
    ParentType,
    ContextType
  >;
  userHistoryFrom?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  userHistoryTo?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventOverallDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventOverallData'] = ResolversParentTypes['EventOverallData'],
> = {
  firstValue?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondValue?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  thirdValue?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventUser'] = ResolversParentTypes['EventUser'],
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  eventId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExerciseManagementRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ExerciseManagementRecord'] = ResolversParentTypes['ExerciseManagementRecord'],
> = {
  calorie?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isAutoCalorie?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  metType?: Resolver<
    Maybe<ResolversTypes['MetTypeRecord']>,
    ParentType,
    ContextType
  >;
  metTypeId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  recordedAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExportCsvEndUserResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ExportCSVEndUserResponse'] = ResolversParentTypes['ExportCSVEndUserResponse'],
> = {
  fileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fileUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExportQuestionnaireResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ExportQuestionnaireResponse'] = ResolversParentTypes['ExportQuestionnaireResponse'],
> = {
  fileUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GraphAgeGlycationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GraphAgeGlycationResponse'] = ResolversParentTypes['GraphAgeGlycationResponse'],
> = {
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['AgeGlycationRecord']>>>,
    ParentType,
    ContextType
  >;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GraphBloodGlucoseResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GraphBloodGlucoseResponse'] = ResolversParentTypes['GraphBloodGlucoseResponse'],
> = {
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['BloodGlucoseRecord']>>>,
    ParentType,
    ContextType
  >;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GraphBloodPressureResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GraphBloodPressureResponse'] = ResolversParentTypes['GraphBloodPressureResponse'],
> = {
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['BloodPressureRecord']>>>,
    ParentType,
    ContextType
  >;
  diastolic?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  systolic?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GraphCalorieResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GraphCalorieResponse'] = ResolversParentTypes['GraphCalorieResponse'],
> = {
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RecordCalorie']>>>,
    ParentType,
    ContextType
  >;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GraphCapillaryTypeResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GraphCapillaryTypeResponse'] = ResolversParentTypes['GraphCapillaryTypeResponse'],
> = {
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CapillaryTypeRecord']>>>,
    ParentType,
    ContextType
  >;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GraphExerciseManagementResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GraphExerciseManagementResponse'] = ResolversParentTypes['GraphExerciseManagementResponse'],
> = {
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ExerciseManagementRecord']>>>,
    ParentType,
    ContextType
  >;
  value?: Resolver<
    Maybe<ResolversTypes['ExerciseManagementRecord']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GraphExerciseResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GraphExerciseResponse'] = ResolversParentTypes['GraphExerciseResponse'],
> = {
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RecordExercise']>>>,
    ParentType,
    ContextType
  >;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GraphFatigueResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GraphFatigueResponse'] = ResolversParentTypes['GraphFatigueResponse'],
> = {
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RecordFatigue']>>>,
    ParentType,
    ContextType
  >;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GraphGripStrengthResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GraphGripStrengthResponse'] = ResolversParentTypes['GraphGripStrengthResponse'],
> = {
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['GripStrengthRecord']>>>,
    ParentType,
    ContextType
  >;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GraphHeartRateResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GraphHeartRateResponse'] = ResolversParentTypes['GraphHeartRateResponse'],
> = {
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RecordHeartRate']>>>,
    ParentType,
    ContextType
  >;
  maxValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GraphPulseWaveResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GraphPulseWaveResponse'] = ResolversParentTypes['GraphPulseWaveResponse'],
> = {
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RecordPulseWave']>>>,
    ParentType,
    ContextType
  >;
  value?: Resolver<
    Maybe<ResolversTypes['RecordPulseWave']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GraphSleepResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GraphSleepResponse'] = ResolversParentTypes['GraphSleepResponse'],
> = {
  avgSleepScore?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  avgTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RecordGraphSleep']>>>,
    ParentType,
    ContextType
  >;
  rateTotalSleepMode?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RateTotalSleepMode']>>>,
    ParentType,
    ContextType
  >;
  totalTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GraphStandResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GraphStandResponse'] = ResolversParentTypes['GraphStandResponse'],
> = {
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RecordStand']>>>,
    ParentType,
    ContextType
  >;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GraphStepRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GraphStepRecord'] = ResolversParentTypes['GraphStepRecord'],
> = {
  recordedAt?: Resolver<
    Maybe<ResolversTypes['RecordedAt']>,
    ParentType,
    ContextType
  >;
  steps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GraphStepResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GraphStepResponse'] = ResolversParentTypes['GraphStepResponse'],
> = {
  avgStep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['GraphStepRecord']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GraphUserServicesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GraphUserServices'] = ResolversParentTypes['GraphUserServices'],
> = {
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['UserServiceItemDetail']>>>,
    ParentType,
    ContextType
  >;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GripStrengthRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GripStrengthRecord'] = ResolversParentTypes['GripStrengthRecord'],
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recordedAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupAnswerResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GroupAnswerResponse'] = ResolversParentTypes['GroupAnswerResponse'],
> = {
  answers?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['AnswerResponse']>>>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryAgeGlycationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HistoryAgeGlycationResponse'] = ResolversParentTypes['HistoryAgeGlycationResponse'],
> = {
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['AgeGlycationRecord']>>>,
    ParentType,
    ContextType
  >;
  pagination?: Resolver<
    ResolversTypes['PaginationPageInfo'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryBloodGlucoseResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HistoryBloodGlucoseResponse'] = ResolversParentTypes['HistoryBloodGlucoseResponse'],
> = {
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['BloodGlucoseRecord']>>>,
    ParentType,
    ContextType
  >;
  pagination?: Resolver<
    ResolversTypes['PaginationPageInfo'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryBloodPressureResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HistoryBloodPressureResponse'] = ResolversParentTypes['HistoryBloodPressureResponse'],
> = {
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['BloodPressureRecord']>>>,
    ParentType,
    ContextType
  >;
  pagination?: Resolver<
    ResolversTypes['PaginationPageInfo'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryCalorieResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HistoryCalorieResponse'] = ResolversParentTypes['HistoryCalorieResponse'],
> = {
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RecordCalorie']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryCapillaryTypeResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HistoryCapillaryTypeResponse'] = ResolversParentTypes['HistoryCapillaryTypeResponse'],
> = {
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CapillaryTypeRecord']>>>,
    ParentType,
    ContextType
  >;
  pagination?: Resolver<
    ResolversTypes['PaginationPageInfo'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryExerciseManagementResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HistoryExerciseManagementResponse'] = ResolversParentTypes['HistoryExerciseManagementResponse'],
> = {
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ExerciseManagementRecord']>>>,
    ParentType,
    ContextType
  >;
  pagination?: Resolver<
    Maybe<ResolversTypes['PaginationPageInfo']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryExerciseResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HistoryExerciseResponse'] = ResolversParentTypes['HistoryExerciseResponse'],
> = {
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RecordExercise']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryFatigueResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HistoryFatigueResponse'] = ResolversParentTypes['HistoryFatigueResponse'],
> = {
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['HistoryRecordFatigue']>>>,
    ParentType,
    ContextType
  >;
  pagination?: Resolver<
    ResolversTypes['PaginationPageInfo'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryGripStrengthResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HistoryGripStrengthResponse'] = ResolversParentTypes['HistoryGripStrengthResponse'],
> = {
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['GripStrengthRecord']>>>,
    ParentType,
    ContextType
  >;
  pagination?: Resolver<
    ResolversTypes['PaginationPageInfo'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryHeartRateResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HistoryHeartRateResponse'] = ResolversParentTypes['HistoryHeartRateResponse'],
> = {
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RecordHeartRate']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryPulseWaveResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HistoryPulseWaveResponse'] = ResolversParentTypes['HistoryPulseWaveResponse'],
> = {
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RecordPulseWave']>>>,
    ParentType,
    ContextType
  >;
  pagination?: Resolver<
    ResolversTypes['PaginationPageInfo'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryRecordFatigueResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HistoryRecordFatigue'] = ResolversParentTypes['HistoryRecordFatigue'],
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recordedAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryRunningResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HistoryRunningResponse'] = ResolversParentTypes['HistoryRunningResponse'],
> = {
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RunningRecord']>>>,
    ParentType,
    ContextType
  >;
  pagination?: Resolver<
    Maybe<ResolversTypes['PaginationPageInfo']>,
    ParentType,
    ContextType
  >;
  totalDistance?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistorySleepResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HistorySleepResponse'] = ResolversParentTypes['HistorySleepResponse'],
> = {
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RecordHistorySleep']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistorySportResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HistorySportResponse'] = ResolversParentTypes['HistorySportResponse'],
> = {
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['SportRecord']>>>,
    ParentType,
    ContextType
  >;
  pagination?: Resolver<
    Maybe<ResolversTypes['PaginationPageInfo']>,
    ParentType,
    ContextType
  >;
  totalCalorie?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalDistance?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryStandResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HistoryStandResponse'] = ResolversParentTypes['HistoryStandResponse'],
> = {
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RecordStand']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryStepResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HistoryStepResponse'] = ResolversParentTypes['HistoryStepResponse'],
> = {
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['GraphStepRecord']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryUserServicesRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HistoryUserServicesRecord'] = ResolversParentTypes['HistoryUserServicesRecord'],
> = {
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['UserServiceDetail']>>>,
    ParentType,
    ContextType
  >;
  pagination?: Resolver<
    Maybe<ResolversTypes['PaginationPageInfo']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryWalkingResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HistoryWalkingResponse'] = ResolversParentTypes['HistoryWalkingResponse'],
> = {
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['WalkingRecord']>>>,
    ParentType,
    ContextType
  >;
  pagination?: Resolver<
    Maybe<ResolversTypes['PaginationPageInfo']>,
    ParentType,
    ContextType
  >;
  totalDistance?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryWeightLossResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HistoryWeightLossResponse'] = ResolversParentTypes['HistoryWeightLossResponse'],
> = {
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['WeightLossRecord']>>>,
    ParentType,
    ContextType
  >;
  pagination?: Resolver<
    Maybe<ResolversTypes['PaginationPageInfo']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HomeDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HomeData'] = ResolversParentTypes['HomeData'],
> = {
  badminton?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  basketball?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  calorie?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cycling?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  exercise?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  fatigue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  football?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gymExercise?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  heartRate?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rockClimbing?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  running?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  skate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stand?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  step?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  swim?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tableTennis?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  tennis?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  vascularAge?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  volleyball?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  walking?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  yoga?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HomeResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HomeResponse'] = ResolversParentTypes['HomeResponse'],
> = {
  data?: Resolver<Maybe<ResolversTypes['HomeData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImportCsvEndUserErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImportCSVEndUserError'] = ResolversParentTypes['ImportCSVEndUserError'],
> = {
  link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImportCsvEndUserResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImportCSVEndUserResponse'] = ResolversParentTypes['ImportCSVEndUserResponse'],
> = {
  error?: Resolver<
    Maybe<ResolversTypes['ImportCSVEndUserError']>,
    ParentType,
    ContextType
  >;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListCompanyMemberResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ListCompanyMemberResponse'] = ResolversParentTypes['ListCompanyMemberResponse'],
> = {
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['UserResponse']>>>,
    ParentType,
    ContextType
  >;
  pagination?: Resolver<
    ResolversTypes['PaginationPageInfo'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListCompanyResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ListCompanyResponse'] = ResolversParentTypes['ListCompanyResponse'],
> = {
  data?: Resolver<
    Array<Maybe<ResolversTypes['CompanyResponse']>>,
    ParentType,
    ContextType
  >;
  pagination?: Resolver<
    ResolversTypes['PaginationPageInfo'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListDepartmentResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ListDepartmentResponse'] = ResolversParentTypes['ListDepartmentResponse'],
> = {
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CompanyDepartmentResponse']>>>,
    ParentType,
    ContextType
  >;
  pagination?: Resolver<
    Maybe<ResolversTypes['PaginationPageInfo']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListEventsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ListEventsResponse'] = ResolversParentTypes['ListEventsResponse'],
> = {
  events?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Event']>>>,
    ParentType,
    ContextType
  >;
  pagination?: Resolver<
    Maybe<ResolversTypes['PaginationPageInfo']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListMetTypeResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ListMetTypeResponse'] = ResolversParentTypes['ListMetTypeResponse'],
> = {
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['MetTypeRecord']>>>,
    ParentType,
    ContextType
  >;
  pagination?: Resolver<
    Maybe<ResolversTypes['PaginationPageInfo']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListNotificationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ListNotificationResponse'] = ResolversParentTypes['ListNotificationResponse'],
> = {
  data?: Resolver<
    Array<Maybe<ResolversTypes['NotificationResponse']>>,
    ParentType,
    ContextType
  >;
  pagination?: Resolver<
    ResolversTypes['PaginationPageInfo'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListPrefectureResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ListPrefectureResponse'] = ResolversParentTypes['ListPrefectureResponse'],
> = {
  data?: Resolver<
    Array<Maybe<ResolversTypes['PrefectureResponse']>>,
    ParentType,
    ContextType
  >;
  pagination?: Resolver<
    ResolversTypes['PaginationPageInfo'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListQuestionnaireResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ListQuestionnaireResponse'] = ResolversParentTypes['ListQuestionnaireResponse'],
> = {
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['QuestionnaireResponse']>>>,
    ParentType,
    ContextType
  >;
  pagination?: Resolver<
    Maybe<ResolversTypes['PaginationPageInfo']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListServiceConfigResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ListServiceConfigResponse'] = ResolversParentTypes['ListServiceConfigResponse'],
> = {
  data?: Resolver<
    Array<Maybe<ResolversTypes['ServiceConfigResponse']>>,
    ParentType,
    ContextType
  >;
  pagination?: Resolver<
    ResolversTypes['PaginationPageInfo'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListServiceResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ListServiceResponse'] = ResolversParentTypes['ListServiceResponse'],
> = {
  data?: Resolver<
    Array<Maybe<ResolversTypes['ServiceResponse']>>,
    ParentType,
    ContextType
  >;
  pagination?: Resolver<
    ResolversTypes['PaginationPageInfo'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListServiceTargetResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ListServiceTargetResponse'] = ResolversParentTypes['ListServiceTargetResponse'],
> = {
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ServiceTarget']>>>,
    ParentType,
    ContextType
  >;
  pagination?: Resolver<
    Maybe<ResolversTypes['PaginationPageInfo']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListUserResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ListUserResponse'] = ResolversParentTypes['ListUserResponse'],
> = {
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['UserResponse']>>>,
    ParentType,
    ContextType
  >;
  pagination?: Resolver<
    Maybe<ResolversTypes['PaginationPageInfo']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MetTypeRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MetTypeRecord'] = ResolversParentTypes['MetTypeRecord'],
> = {
  createdAt?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  speed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation'],
> = {
  changeEmail?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationChangeEmailArgs, 'newEmail' | 'password'>
  >;
  changePassword?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationChangePasswordArgs,
      'confirmPassword' | 'newPassword' | 'oldPassword'
    >
  >;
  companyCreateEndUser?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationCompanyCreateEndUserArgs, 'data'>
  >;
  companyCreateMember?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationCompanyCreateMemberArgs, 'data'>
  >;
  companyUpdateMember?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationCompanyUpdateMemberArgs, 'data'>
  >;
  createAgeGlycation?: Resolver<
    Maybe<ResolversTypes['AgeGlycationRecord']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateAgeGlycationArgs, 'data'>
  >;
  createBloodGlucose?: Resolver<
    Maybe<ResolversTypes['BloodGlucoseRecord']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateBloodGlucoseArgs, 'data'>
  >;
  createBloodPressure?: Resolver<
    ResolversTypes['BloodPressureRecord'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateBloodPressureArgs, 'data'>
  >;
  createCapillaryType?: Resolver<
    Maybe<ResolversTypes['CapillaryTypeRecord']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateCapillaryTypeArgs, 'data'>
  >;
  createCompany?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateCompanyArgs, 'data'>
  >;
  createCompanyDepartment?: Resolver<
    Maybe<ResolversTypes['CompanyDepartmentResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateCompanyDepartmentArgs, 'name'>
  >;
  createEvent?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateEventArgs, 'data'>
  >;
  createExerciseManagement?: Resolver<
    Maybe<ResolversTypes['ExerciseManagementRecord']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateExerciseManagementArgs, 'data'>
  >;
  createGripStrength?: Resolver<
    Maybe<ResolversTypes['GripStrengthRecord']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateGripStrengthArgs, 'data'>
  >;
  createMetType?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateMetTypeArgs, 'data'>
  >;
  createNotification?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateNotificationArgs, 'data' | 'target'>
  >;
  createPulseWave?: Resolver<
    Maybe<ResolversTypes['RecordPulseWave']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreatePulseWaveArgs, 'data'>
  >;
  createQuestionnaire?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateQuestionnaireArgs, 'data'>
  >;
  createService?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateServiceArgs, 'data'>
  >;
  createServiceTarget?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateServiceTargetArgs, 'data'>
  >;
  createUserServiceRecord?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateUserServiceRecordArgs, 'data'>
  >;
  createWeightLoss?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateWeightLossArgs, 'data'>
  >;
  deleteAgeGlycation?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAgeGlycationArgs, 'id'>
  >;
  deleteBloodGlucose?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteBloodGlucoseArgs, 'id'>
  >;
  deleteBloodPressure?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteBloodPressureArgs, 'id'>
  >;
  deleteCapillaryType?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteCapillaryTypeArgs, 'id'>
  >;
  deleteCompanyDepartment?: Resolver<
    Maybe<ResolversTypes['CompanyDepartmentResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteCompanyDepartmentArgs, 'id'>
  >;
  deleteEvent?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteEventArgs, 'eventId'>
  >;
  deleteExerciseManagement?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteExerciseManagementArgs, 'id'>
  >;
  deleteGripStrength?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteGripStrengthArgs, 'id'>
  >;
  deleteManyUsers?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    Partial<MutationDeleteManyUsersArgs>
  >;
  deleteMetType?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteMetTypeArgs, 'id'>
  >;
  deleteNotification?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteNotificationArgs, 'ids'>
  >;
  deletePulseWave?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeletePulseWaveArgs, 'id'>
  >;
  deleteQuestionnaire?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    Partial<MutationDeleteQuestionnaireArgs>
  >;
  deleteServiceTarget?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteServiceTargetArgs, 'id'>
  >;
  deleteUser?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    Partial<MutationDeleteUserArgs>
  >;
  deleteUserServiceRecord?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteUserServiceRecordArgs, 'id'>
  >;
  forgotPassword?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationForgotPasswordArgs,
      'confirmPassword' | 'newPassword' | 'token'
    >
  >;
  importCSVEndUser?: Resolver<
    ResolversTypes['ImportCSVEndUserResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationImportCsvEndUserArgs, 'fileUrl'>
  >;
  modifyUserStatus?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationModifyUserStatusArgs, 'id' | 'status'>
  >;
  publishNotification?: Resolver<
    Maybe<ResolversTypes['PublishNotificationResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationPublishNotificationArgs, 'data' | 'receiverId'>
  >;
  refreshToken?: Resolver<
    ResolversTypes['RefreshTokenResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationRefreshTokenArgs, 'refreshTokenInput'>
  >;
  resendVerifyEmail?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    Partial<MutationResendVerifyEmailArgs>
  >;
  resendVerifyEmailBtoC?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationResendVerifyEmailBtoCArgs, 'email'>
  >;
  sendMailForgotPassword?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationSendMailForgotPasswordArgs, 'email'>
  >;
  setUserPassword?: Resolver<
    ResolversTypes['DynamicLinkResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationSetUserPasswordArgs, 'password' | 'token'>
  >;
  signIn?: Resolver<
    ResolversTypes['SignInResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationSignInArgs, 'signInInput'>
  >;
  signUp?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationSignUpArgs, 'data'>
  >;
  syncData?: Resolver<
    ResolversTypes['SyncDataResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationSyncDataArgs, 'healthData'>
  >;
  updateAgeGlycation?: Resolver<
    Maybe<ResolversTypes['AgeGlycationRecord']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAgeGlycationArgs, 'data'>
  >;
  updateBloodGlucose?: Resolver<
    Maybe<ResolversTypes['BloodGlucoseRecord']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateBloodGlucoseArgs, 'data'>
  >;
  updateBloodPressure?: Resolver<
    ResolversTypes['BloodPressureRecord'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateBloodPressureArgs, 'data'>
  >;
  updateCapillaryType?: Resolver<
    Maybe<ResolversTypes['CapillaryTypeRecord']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCapillaryTypeArgs, 'data'>
  >;
  updateCompany?: Resolver<
    Maybe<ResolversTypes['CompanyResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCompanyArgs, 'data'>
  >;
  updateCompanyDepartment?: Resolver<
    Maybe<ResolversTypes['CompanyDepartmentResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCompanyDepartmentArgs, 'data'>
  >;
  updateEvent?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateEventArgs, 'data'>
  >;
  updateExerciseManagement?: Resolver<
    Maybe<ResolversTypes['ExerciseManagementRecord']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateExerciseManagementArgs, 'data'>
  >;
  updateGripStrength?: Resolver<
    Maybe<ResolversTypes['GripStrengthRecord']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateGripStrengthArgs, 'data'>
  >;
  updateMetType?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateMetTypeArgs, 'data' | 'id'>
  >;
  updateNotification?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateNotificationArgs, 'data' | 'id'>
  >;
  updatePulseWave?: Resolver<
    Maybe<ResolversTypes['RecordPulseWave']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdatePulseWaveArgs, 'data'>
  >;
  updateQuestionnaire?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateQuestionnaireArgs, 'data'>
  >;
  updateService?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateServiceArgs, 'data'>
  >;
  updateServiceTarget?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateServiceTargetArgs, 'data'>
  >;
  updateUser?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUserArgs, 'data'>
  >;
  updateUserServiceConfig?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUserServiceConfigArgs, 'data'>
  >;
  updateUserServiceRecord?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUserServiceRecordArgs, 'data'>
  >;
  userAnswerQuestionnaire?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationUserAnswerQuestionnaireArgs, 'data'>
  >;
  userJoinEvent?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationUserJoinEventArgs, 'eventId'>
  >;
  userUpdateProfile?: Resolver<
    ResolversTypes['UserResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUserUpdateProfileArgs, 'data'>
  >;
};

export type NotificationCompaniesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NotificationCompanies'] = ResolversParentTypes['NotificationCompanies'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationDetailResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NotificationDetailResponse'] = ResolversParentTypes['NotificationDetailResponse'],
> = {
  companies?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['NotificationCompanies']>>>,
    ParentType,
    ContextType
  >;
  companyId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isMajor?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPublishNow?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  largeImage?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  personInCharge?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  publishTime?: Resolver<
    ResolversTypes['AWSDateTime'],
    ParentType,
    ContextType
  >;
  smallImage?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['NotificationType'], ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  users?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['NotificationUsers']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NotificationResponse'] = ResolversParentTypes['NotificationResponse'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isMajor?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPublishNow?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  largeImage?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  personInCharge?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  publishTime?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  smallImage?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['NotificationType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationUsersResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NotificationUsers'] = ResolversParentTypes['NotificationUsers'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginationPageInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaginationPageInfo'] = ResolversParentTypes['PaginationPageInfo'],
> = {
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  perPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PrefectureResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PrefectureResponse'] = ResolversParentTypes['PrefectureResponse'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  prefecture?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  prefectureKana?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublishNotificationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishNotificationResponse'] = ResolversParentTypes['PublishNotificationResponse'],
> = {
  data?: Resolver<ResolversTypes['AWSJSON'], ParentType, ContextType>;
  receiverId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query'],
> = {
  detailAgeGlycation?: Resolver<
    ResolversTypes['AgeGlycationRecord'],
    ParentType,
    ContextType,
    RequireFields<QueryDetailAgeGlycationArgs, 'id'>
  >;
  detailBloodGlucose?: Resolver<
    ResolversTypes['BloodGlucoseRecord'],
    ParentType,
    ContextType,
    RequireFields<QueryDetailBloodGlucoseArgs, 'id'>
  >;
  detailBloodPressure?: Resolver<
    ResolversTypes['BloodPressureRecord'],
    ParentType,
    ContextType,
    RequireFields<QueryDetailBloodPressureArgs, 'id'>
  >;
  detailCapillaryType?: Resolver<
    ResolversTypes['CapillaryTypeRecord'],
    ParentType,
    ContextType,
    RequireFields<QueryDetailCapillaryTypeArgs, 'id'>
  >;
  detailCompany?: Resolver<
    ResolversTypes['CompanyResponse'],
    ParentType,
    ContextType,
    Partial<QueryDetailCompanyArgs>
  >;
  detailDepartment?: Resolver<
    Maybe<ResolversTypes['CompanyDepartmentResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryDetailDepartmentArgs, 'id'>
  >;
  detailEvent?: Resolver<
    Maybe<ResolversTypes['DetailEventResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryDetailEventArgs, 'eventId'>
  >;
  detailExerciseManagement?: Resolver<
    Maybe<ResolversTypes['ExerciseManagementRecord']>,
    ParentType,
    ContextType,
    RequireFields<QueryDetailExerciseManagementArgs, 'id'>
  >;
  detailGripStrength?: Resolver<
    ResolversTypes['GripStrengthRecord'],
    ParentType,
    ContextType,
    RequireFields<QueryDetailGripStrengthArgs, 'id'>
  >;
  detailNotification?: Resolver<
    ResolversTypes['NotificationDetailResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryDetailNotificationArgs, 'notificationId'>
  >;
  detailPulseWave?: Resolver<
    Maybe<ResolversTypes['RecordPulseWave']>,
    ParentType,
    ContextType,
    RequireFields<QueryDetailPulseWaveArgs, 'id'>
  >;
  detailQuestionnaire?: Resolver<
    ResolversTypes['QuestionnaireResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryDetailQuestionnaireArgs, 'id'>
  >;
  detailService?: Resolver<
    Maybe<ResolversTypes['ServiceResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryDetailServiceArgs, 'id'>
  >;
  detailServiceTarget?: Resolver<
    Maybe<ResolversTypes['ServiceTarget']>,
    ParentType,
    ContextType,
    RequireFields<QueryDetailServiceTargetArgs, 'id'>
  >;
  detailUserAnswerQuestionnaire?: Resolver<
    Maybe<ResolversTypes['QuestionnaireResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryDetailUserAnswerQuestionnaireArgs, 'data'>
  >;
  exportCSVEndUser?: Resolver<
    ResolversTypes['ExportCSVEndUserResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryExportCsvEndUserArgs, 'data'>
  >;
  exportQuestionnaire?: Resolver<
    Maybe<ResolversTypes['ExportQuestionnaireResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryExportQuestionnaireArgs, 'data'>
  >;
  getAppVersion?: Resolver<
    Maybe<ResolversTypes['AppVersionResponse']>,
    ParentType,
    ContextType
  >;
  getHomeData?: Resolver<
    Maybe<ResolversTypes['HomeResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetHomeDataArgs, 'from' | 'keys' | 'to'>
  >;
  getListFileUrl?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType,
    RequireFields<QueryGetListFileUrlArgs, 'fileNames'>
  >;
  getUploadUrl?: Resolver<
    Maybe<ResolversTypes['CreatePutPresignedUrlResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetUploadUrlArgs, 'contentType' | 'fileName'>
  >;
  getUser?: Resolver<
    ResolversTypes['UserResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetUserArgs, 'userId'>
  >;
  getUserInfo?: Resolver<
    ResolversTypes['UserResponse'],
    ParentType,
    ContextType
  >;
  graphAgeGlycation?: Resolver<
    ResolversTypes['GraphAgeGlycationResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGraphAgeGlycationArgs, 'from' | 'mode' | 'to'>
  >;
  graphBloodGlucose?: Resolver<
    ResolversTypes['GraphBloodGlucoseResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGraphBloodGlucoseArgs, 'from' | 'mode' | 'to'>
  >;
  graphBloodPressure?: Resolver<
    ResolversTypes['GraphBloodPressureResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGraphBloodPressureArgs, 'from' | 'mode' | 'to'>
  >;
  graphCalorie?: Resolver<
    ResolversTypes['GraphCalorieResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGraphCalorieArgs, 'from' | 'mode' | 'to'>
  >;
  graphCapillaryType?: Resolver<
    ResolversTypes['GraphCapillaryTypeResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGraphCapillaryTypeArgs, 'from' | 'mode' | 'to'>
  >;
  graphExercise?: Resolver<
    Maybe<ResolversTypes['GraphExerciseResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryGraphExerciseArgs, 'from' | 'mode' | 'to'>
  >;
  graphExerciseManagement?: Resolver<
    ResolversTypes['GraphExerciseManagementResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGraphExerciseManagementArgs, 'from' | 'mode' | 'to'>
  >;
  graphFatigue?: Resolver<
    Maybe<ResolversTypes['GraphFatigueResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryGraphFatigueArgs, 'from' | 'mode' | 'to'>
  >;
  graphGripStrength?: Resolver<
    ResolversTypes['GraphGripStrengthResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGraphGripStrengthArgs, 'from' | 'mode' | 'to'>
  >;
  graphHeartRate?: Resolver<
    ResolversTypes['GraphHeartRateResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGraphHeartRateArgs, 'from' | 'mode' | 'to'>
  >;
  graphPulseWave?: Resolver<
    ResolversTypes['GraphPulseWaveResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGraphPulseWaveArgs, 'from' | 'mode' | 'to'>
  >;
  graphSleep?: Resolver<
    Maybe<ResolversTypes['GraphSleepResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryGraphSleepArgs, 'from' | 'to'>
  >;
  graphStand?: Resolver<
    ResolversTypes['GraphStandResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGraphStandArgs, 'from' | 'mode' | 'to'>
  >;
  graphStep?: Resolver<
    ResolversTypes['GraphStepResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGraphStepArgs, 'data'>
  >;
  graphUserServicesRecord?: Resolver<
    Maybe<ResolversTypes['GraphUserServices']>,
    ParentType,
    ContextType,
    Partial<QueryGraphUserServicesRecordArgs>
  >;
  health?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    Partial<QueryHealthArgs>
  >;
  historyAgeGlycation?: Resolver<
    ResolversTypes['HistoryAgeGlycationResponse'],
    ParentType,
    ContextType,
    Partial<QueryHistoryAgeGlycationArgs>
  >;
  historyBloodGlucose?: Resolver<
    ResolversTypes['HistoryBloodGlucoseResponse'],
    ParentType,
    ContextType,
    Partial<QueryHistoryBloodGlucoseArgs>
  >;
  historyBloodPressure?: Resolver<
    ResolversTypes['HistoryBloodPressureResponse'],
    ParentType,
    ContextType,
    Partial<QueryHistoryBloodPressureArgs>
  >;
  historyCalorie?: Resolver<
    ResolversTypes['HistoryCalorieResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryHistoryCalorieArgs, 'from' | 'to'>
  >;
  historyCapillaryType?: Resolver<
    ResolversTypes['HistoryCapillaryTypeResponse'],
    ParentType,
    ContextType,
    Partial<QueryHistoryCapillaryTypeArgs>
  >;
  historyExercise?: Resolver<
    ResolversTypes['HistoryExerciseResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryHistoryExerciseArgs, 'from' | 'to'>
  >;
  historyExerciseManagement?: Resolver<
    ResolversTypes['HistoryExerciseManagementResponse'],
    ParentType,
    ContextType,
    Partial<QueryHistoryExerciseManagementArgs>
  >;
  historyFatigue?: Resolver<
    Maybe<ResolversTypes['HistoryFatigueResponse']>,
    ParentType,
    ContextType,
    Partial<QueryHistoryFatigueArgs>
  >;
  historyGripStrength?: Resolver<
    ResolversTypes['HistoryGripStrengthResponse'],
    ParentType,
    ContextType,
    Partial<QueryHistoryGripStrengthArgs>
  >;
  historyHeartRate?: Resolver<
    ResolversTypes['HistoryHeartRateResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryHistoryHeartRateArgs, 'from' | 'to'>
  >;
  historyPulseWave?: Resolver<
    ResolversTypes['HistoryPulseWaveResponse'],
    ParentType,
    ContextType,
    Partial<QueryHistoryPulseWaveArgs>
  >;
  historyRunning?: Resolver<
    ResolversTypes['HistoryRunningResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryHistoryRunningArgs, 'from' | 'to'>
  >;
  historySleep?: Resolver<
    Maybe<ResolversTypes['HistorySleepResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryHistorySleepArgs, 'from' | 'to'>
  >;
  historySport?: Resolver<
    ResolversTypes['HistorySportResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryHistorySportArgs, 'from' | 'sportType' | 'to'>
  >;
  historyStand?: Resolver<
    ResolversTypes['HistoryStandResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryHistoryStandArgs, 'from' | 'to'>
  >;
  historyStep?: Resolver<
    ResolversTypes['HistoryStepResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryHistoryStepArgs, 'from' | 'to'>
  >;
  historyUserServicesRecord?: Resolver<
    Maybe<ResolversTypes['HistoryUserServicesRecord']>,
    ParentType,
    ContextType,
    Partial<QueryHistoryUserServicesRecordArgs>
  >;
  historyWalking?: Resolver<
    ResolversTypes['HistoryWalkingResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryHistoryWalkingArgs, 'from' | 'to'>
  >;
  historyWeightLoss?: Resolver<
    ResolversTypes['HistoryWeightLossResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryHistoryWeightLossArgs, 'from' | 'to'>
  >;
  listCompany?: Resolver<
    ResolversTypes['ListCompanyResponse'],
    ParentType,
    ContextType,
    Partial<QueryListCompanyArgs>
  >;
  listCompanyDepartment?: Resolver<
    ResolversTypes['ListDepartmentResponse'],
    ParentType,
    ContextType,
    Partial<QueryListCompanyDepartmentArgs>
  >;
  listCompanyMember?: Resolver<
    ResolversTypes['ListCompanyMemberResponse'],
    ParentType,
    ContextType,
    Partial<QueryListCompanyMemberArgs>
  >;
  listEvents?: Resolver<
    Maybe<ResolversTypes['ListEventsResponse']>,
    ParentType,
    ContextType,
    Partial<QueryListEventsArgs>
  >;
  listMetType?: Resolver<
    Maybe<ResolversTypes['ListMetTypeResponse']>,
    ParentType,
    ContextType,
    Partial<QueryListMetTypeArgs>
  >;
  listNotification?: Resolver<
    ResolversTypes['ListNotificationResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryListNotificationArgs, 'target'>
  >;
  listPrefecture?: Resolver<
    ResolversTypes['ListPrefectureResponse'],
    ParentType,
    ContextType,
    Partial<QueryListPrefectureArgs>
  >;
  listQuestionnaire?: Resolver<
    ResolversTypes['ListQuestionnaireResponse'],
    ParentType,
    ContextType,
    Partial<QueryListQuestionnaireArgs>
  >;
  listService?: Resolver<
    ResolversTypes['ListServiceResponse'],
    ParentType,
    ContextType,
    Partial<QueryListServiceArgs>
  >;
  listServiceItem?: Resolver<
    Array<Maybe<ResolversTypes['ServiceItemResponse']>>,
    ParentType,
    ContextType,
    Partial<QueryListServiceItemArgs>
  >;
  listServiceTarget?: Resolver<
    Maybe<ResolversTypes['ListServiceTargetResponse']>,
    ParentType,
    ContextType,
    Partial<QueryListServiceTargetArgs>
  >;
  listTopUsers?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TopUsersResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<QueryListTopUsersArgs, 'eventId'>
  >;
  listUser?: Resolver<
    ResolversTypes['ListUserResponse'],
    ParentType,
    ContextType,
    Partial<QueryListUserArgs>
  >;
  listUserJoinedEvent?: Resolver<
    Maybe<ResolversTypes['UsersJoinedEventResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryListUserJoinedEventArgs, 'eventId'>
  >;
  listUserService?: Resolver<
    Maybe<ResolversTypes['ListServiceConfigResponse']>,
    ParentType,
    ContextType,
    Partial<QueryListUserServiceArgs>
  >;
  stampHistory?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['StampHistoryResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<QueryStampHistoryArgs, 'eventId' | 'from' | 'to'>
  >;
  verifyChangeEmail?: Resolver<
    ResolversTypes['DynamicLinkResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryVerifyChangeEmailArgs, 'token'>
  >;
  verifyToken?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<QueryVerifyTokenArgs, 'token'>
  >;
};

export type QuestionResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['QuestionResponse'] = ResolversParentTypes['QuestionResponse'],
> = {
  answers?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['AnswerResponse']>>>,
    ParentType,
    ContextType
  >;
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  explain?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  groupAnswers?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['GroupAnswerResponse']>>>,
    ParentType,
    ContextType
  >;
  highlyRatingLabel?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isRequired?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  isShowExplain?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  lowRatingLabel?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  numOfUser?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<
    Maybe<ResolversTypes['QuestionType']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestionnaireResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['QuestionnaireResponse'] = ResolversParentTypes['QuestionnaireResponse'],
> = {
  category?: Resolver<
    Maybe<ResolversTypes['QuestionnaireCategory']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  createdBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  finishedAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  numOfUser?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  questions?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['QuestionResponse']>>>,
    ParentType,
    ContextType
  >;
  startAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<
    ResolversTypes['QuestionnaireStatus'],
    ParentType,
    ContextType
  >;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RateTotalSleepModeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RateTotalSleepMode'] = ResolversParentTypes['RateTotalSleepMode'],
> = {
  percentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  rate?: Resolver<
    Maybe<ResolversTypes['SleepModeRate']>,
    ParentType,
    ContextType
  >;
  sleepMode?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecordCalorieResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RecordCalorie'] = ResolversParentTypes['RecordCalorie'],
> = {
  recordedAt?: Resolver<
    Maybe<ResolversTypes['RecordedAtFromTo']>,
    ParentType,
    ContextType
  >;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecordExerciseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RecordExercise'] = ResolversParentTypes['RecordExercise'],
> = {
  endTime?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  recordedAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  startTime?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecordFatigueResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RecordFatigue'] = ResolversParentTypes['RecordFatigue'],
> = {
  recordedAt?: Resolver<
    Maybe<ResolversTypes['RecordedAtFromTo']>,
    ParentType,
    ContextType
  >;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecordGraphSleepResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RecordGraphSleep'] = ResolversParentTypes['RecordGraphSleep'],
> = {
  inSleepTime?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  outSleepTime?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  sleepRanges?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RecordSleepRange']>>>,
    ParentType,
    ContextType
  >;
  sleepScore?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalBySleepMode?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TotalTimeBySleepMode']>>>,
    ParentType,
    ContextType
  >;
  totalPerDay?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecordHeartRateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RecordHeartRate'] = ResolversParentTypes['RecordHeartRate'],
> = {
  avgValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  recordedAt?: Resolver<
    Maybe<ResolversTypes['RecordedAtFromTo']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecordHistorySleepResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RecordHistorySleep'] = ResolversParentTypes['RecordHistorySleep'],
> = {
  recordedAt?: Resolver<
    Maybe<ResolversTypes['RecordedAtFromTo']>,
    ParentType,
    ContextType
  >;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecordPulseWaveResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RecordPulseWave'] = ResolversParentTypes['RecordPulseWave'],
> = {
  bulkModulus?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  closePressure?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  expansionRate?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  openPressure?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  recordedAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  userGender?: Resolver<
    Maybe<ResolversTypes['Gender']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vascularAge?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecordSleepRangeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RecordSleepRange'] = ResolversParentTypes['RecordSleepRange'],
> = {
  sleepMode?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  startTime?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  stopTime?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecordStandResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RecordStand'] = ResolversParentTypes['RecordStand'],
> = {
  recordedAt?: Resolver<
    Maybe<ResolversTypes['RecordedAtFromTo']>,
    ParentType,
    ContextType
  >;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecordedAtResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RecordedAt'] = ResolversParentTypes['RecordedAt'],
> = {
  from?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  to?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecordedAtFromToResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RecordedAtFromTo'] = ResolversParentTypes['RecordedAtFromTo'],
> = {
  from?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  to?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RefreshTokenResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RefreshTokenResponse'] = ResolversParentTypes['RefreshTokenResponse'],
> = {
  expiredAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  refreshToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RunningRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RunningRecord'] = ResolversParentTypes['RunningRecord'],
> = {
  distance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  endTime?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startTime?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceConfigResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ServiceConfigResponse'] = ResolversParentTypes['ServiceConfigResponse'],
> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isShow?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  service?: Resolver<
    Maybe<ResolversTypes['ServiceResponse']>,
    ParentType,
    ContextType
  >;
  serviceId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  user?: Resolver<
    Maybe<ResolversTypes['UserResponse']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceCountResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ServiceCountResponse'] = ResolversParentTypes['ServiceCountResponse'],
> = {
  companyServices?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceItemResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ServiceItemResponse'] = ResolversParentTypes['ServiceItemResponse'],
> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serviceId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  target?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  targets?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ServiceItemTarget']>>>,
    ParentType,
    ContextType
  >;
  unit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceItemTargetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ServiceItemTarget'] = ResolversParentTypes['ServiceItemTarget'],
> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serviceItem?: Resolver<
    Maybe<ResolversTypes['ServiceItemResponse']>,
    ParentType,
    ContextType
  >;
  serviceItemId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  serviceTarget?: Resolver<
    Maybe<ResolversTypes['ServiceTarget']>,
    ParentType,
    ContextType
  >;
  serviceTargetId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  target?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ServiceResponse'] = ResolversParentTypes['ServiceResponse'],
> = {
  _count?: Resolver<
    Maybe<ResolversTypes['ServiceCountResponse']>,
    ParentType,
    ContextType
  >;
  companyId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  contractEndAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  contractStartAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isDefault?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  isManual?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  itemsName?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  key?: Resolver<Maybe<ResolversTypes['ServiceType']>, ParentType, ContextType>;
  limit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numberUsers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  serviceItems?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ServiceItemResponse']>>>,
    ParentType,
    ContextType
  >;
  status?: Resolver<
    Maybe<ResolversTypes['ServiceStatus']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceTargetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ServiceTarget'] = ResolversParentTypes['ServiceTarget'],
> = {
  companyId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  service?: Resolver<
    Maybe<ResolversTypes['ServiceResponse']>,
    ParentType,
    ContextType
  >;
  serviceId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  serviceItemTargets?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ServiceItemTarget']>>>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SignInResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SignInResponse'] = ResolversParentTypes['SignInResponse'],
> = {
  expiredAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  groups?: Resolver<
    Array<Maybe<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  refreshToken?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SleepModeRateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SleepModeRate'] = ResolversParentTypes['SleepModeRate'],
> = {
  reference?: Resolver<
    Maybe<ResolversTypes['SleepModeReference']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<
    Maybe<ResolversTypes['SleepModeStatus']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SleepModeReferenceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SleepModeReference'] = ResolversParentTypes['SleepModeReference'],
> = {
  from?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  to?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SportRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SportRecord'] = ResolversParentTypes['SportRecord'],
> = {
  calorie?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  endTime?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startTime?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StampHistoryResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StampHistoryResponse'] = ResolversParentTypes['StampHistoryResponse'],
> = {
  isCompleted?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  recordedAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription'],
> = {
  subscribeNotification?: SubscriptionResolver<
    Maybe<ResolversTypes['PublishNotificationResponse']>,
    'subscribeNotification',
    ParentType,
    ContextType,
    RequireFields<SubscriptionSubscribeNotificationArgs, 'receiverId'>
  >;
};

export type SyncDataResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SyncDataResponse'] = ResolversParentTypes['SyncDataResponse'],
> = {
  isEmpty?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  notificationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  syncingAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TopUsersResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TopUsersResponse'] = ResolversParentTypes['TopUsersResponse'],
> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rank?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TotalTimeBySleepModeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TotalTimeBySleepMode'] = ResolversParentTypes['TotalTimeBySleepMode'],
> = {
  sleepMode?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserCompanyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserCompany'] = ResolversParentTypes['UserCompany'],
> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserJoinedEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserJoinedEvent'] = ResolversParentTypes['UserJoinedEvent'],
> = {
  birthday?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['Gender'], ParentType, ContextType>;
  joinDate?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rank?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  subId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['UserType']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserRankDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserRankData'] = ResolversParentTypes['UserRankData'],
> = {
  currentRank?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  differenceNext?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  differencePrevious?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserResponse'] = ResolversParentTypes['UserResponse'],
> = {
  address1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address1Kana?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  address2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address2Kana?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  birthday?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cityKana?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cognitoId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  company?: Resolver<
    Maybe<ResolversTypes['CompanyResponse']>,
    ParentType,
    ContextType
  >;
  companyId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  department?: Resolver<
    Maybe<ResolversTypes['CompanyDepartmentResponse']>,
    ParentType,
    ContextType
  >;
  departmentId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['Gender']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isPrimary?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  lastSignInAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  lastSyncedAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  occupation?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  postalCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  prefecture?: Resolver<
    Maybe<ResolversTypes['PrefectureResponse']>,
    ParentType,
    ContextType
  >;
  prefectureId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  registeredAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<
    Maybe<ResolversTypes['UserStatus']>,
    ParentType,
    ContextType
  >;
  statusModifiedAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  subId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['UserType']>, ParentType, ContextType>;
  userRoles?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RoleName']>>>,
    ParentType,
    ContextType
  >;
  userServices?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['UserServices']>>>,
    ParentType,
    ContextType
  >;
  weight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserServiceDetailResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserServiceDetail'] = ResolversParentTypes['UserServiceDetail'],
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recordedAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  serviceId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userServiceItemDetails?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['UserServiceItemDetails']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserServiceItemDetailResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserServiceItemDetail'] = ResolversParentTypes['UserServiceItemDetail'],
> = {
  recordedAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserServiceItemDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserServiceItemDetails'] = ResolversParentTypes['UserServiceItemDetails'],
> = {
  serviceItem?: Resolver<
    Maybe<ResolversTypes['ServiceItemResponse']>,
    ParentType,
    ContextType
  >;
  serviceItemId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserServicesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserServices'] = ResolversParentTypes['UserServices'],
> = {
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  serviceId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  serviceItems?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ServiceItemResponse']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersJoinedEventResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersJoinedEventResponse'] = ResolversParentTypes['UsersJoinedEventResponse'],
> = {
  avg?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  companyId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  companyName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  female?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  male?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  other?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pagination?: Resolver<
    Maybe<ResolversTypes['PaginationPageInfo']>,
    ParentType,
    ContextType
  >;
  participants?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sum?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['EventType']>, ParentType, ContextType>;
  users?: Resolver<
    Array<Maybe<ResolversTypes['UserJoinedEvent']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WalkingRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WalkingRecord'] = ResolversParentTypes['WalkingRecord'],
> = {
  distance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  endTime?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startTime?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WeightLossRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WeightLossRecord'] = ResolversParentTypes['WeightLossRecord'],
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  eventId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recordedAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['AWSDateTime']>,
    ParentType,
    ContextType
  >;
  weight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  AWSDate?: GraphQLScalarType;
  AWSDateTime?: GraphQLScalarType;
  AWSEmail?: GraphQLScalarType;
  AWSIPAddress?: GraphQLScalarType;
  AWSJSON?: GraphQLScalarType;
  AWSPhone?: GraphQLScalarType;
  AWSTime?: GraphQLScalarType;
  AWSTimestamp?: GraphQLScalarType;
  AWSURL?: GraphQLScalarType;
  AgeGlycationRecord?: AgeGlycationRecordResolvers<ContextType>;
  AnswerResponse?: AnswerResponseResolvers<ContextType>;
  AppVersionResponse?: AppVersionResponseResolvers<ContextType>;
  BloodGlucoseRecord?: BloodGlucoseRecordResolvers<ContextType>;
  BloodPressureRecord?: BloodPressureRecordResolvers<ContextType>;
  CapillaryTypeRecord?: CapillaryTypeRecordResolvers<ContextType>;
  CompanyDepartmentResponse?: CompanyDepartmentResponseResolvers<ContextType>;
  CompanyDepartmentUserCount?: CompanyDepartmentUserCountResolvers<ContextType>;
  CompanyResponse?: CompanyResponseResolvers<ContextType>;
  CompanyServiceResponse?: CompanyServiceResponseResolvers<ContextType>;
  CreatePutPresignedUrlResponse?: CreatePutPresignedUrlResponseResolvers<ContextType>;
  CsvListUserResponse?: CsvListUserResponseResolvers<ContextType>;
  DetailEventResponse?: DetailEventResponseResolvers<ContextType>;
  DynamicLinkResponse?: DynamicLinkResponseResolvers<ContextType>;
  Event?: EventResolvers<ContextType>;
  EventCondition?: EventConditionResolvers<ContextType>;
  EventOverallData?: EventOverallDataResolvers<ContextType>;
  EventUser?: EventUserResolvers<ContextType>;
  ExerciseManagementRecord?: ExerciseManagementRecordResolvers<ContextType>;
  ExportCSVEndUserResponse?: ExportCsvEndUserResponseResolvers<ContextType>;
  ExportQuestionnaireResponse?: ExportQuestionnaireResponseResolvers<ContextType>;
  GraphAgeGlycationResponse?: GraphAgeGlycationResponseResolvers<ContextType>;
  GraphBloodGlucoseResponse?: GraphBloodGlucoseResponseResolvers<ContextType>;
  GraphBloodPressureResponse?: GraphBloodPressureResponseResolvers<ContextType>;
  GraphCalorieResponse?: GraphCalorieResponseResolvers<ContextType>;
  GraphCapillaryTypeResponse?: GraphCapillaryTypeResponseResolvers<ContextType>;
  GraphExerciseManagementResponse?: GraphExerciseManagementResponseResolvers<ContextType>;
  GraphExerciseResponse?: GraphExerciseResponseResolvers<ContextType>;
  GraphFatigueResponse?: GraphFatigueResponseResolvers<ContextType>;
  GraphGripStrengthResponse?: GraphGripStrengthResponseResolvers<ContextType>;
  GraphHeartRateResponse?: GraphHeartRateResponseResolvers<ContextType>;
  GraphPulseWaveResponse?: GraphPulseWaveResponseResolvers<ContextType>;
  GraphSleepResponse?: GraphSleepResponseResolvers<ContextType>;
  GraphStandResponse?: GraphStandResponseResolvers<ContextType>;
  GraphStepRecord?: GraphStepRecordResolvers<ContextType>;
  GraphStepResponse?: GraphStepResponseResolvers<ContextType>;
  GraphUserServices?: GraphUserServicesResolvers<ContextType>;
  GripStrengthRecord?: GripStrengthRecordResolvers<ContextType>;
  GroupAnswerResponse?: GroupAnswerResponseResolvers<ContextType>;
  HistoryAgeGlycationResponse?: HistoryAgeGlycationResponseResolvers<ContextType>;
  HistoryBloodGlucoseResponse?: HistoryBloodGlucoseResponseResolvers<ContextType>;
  HistoryBloodPressureResponse?: HistoryBloodPressureResponseResolvers<ContextType>;
  HistoryCalorieResponse?: HistoryCalorieResponseResolvers<ContextType>;
  HistoryCapillaryTypeResponse?: HistoryCapillaryTypeResponseResolvers<ContextType>;
  HistoryExerciseManagementResponse?: HistoryExerciseManagementResponseResolvers<ContextType>;
  HistoryExerciseResponse?: HistoryExerciseResponseResolvers<ContextType>;
  HistoryFatigueResponse?: HistoryFatigueResponseResolvers<ContextType>;
  HistoryGripStrengthResponse?: HistoryGripStrengthResponseResolvers<ContextType>;
  HistoryHeartRateResponse?: HistoryHeartRateResponseResolvers<ContextType>;
  HistoryPulseWaveResponse?: HistoryPulseWaveResponseResolvers<ContextType>;
  HistoryRecordFatigue?: HistoryRecordFatigueResolvers<ContextType>;
  HistoryRunningResponse?: HistoryRunningResponseResolvers<ContextType>;
  HistorySleepResponse?: HistorySleepResponseResolvers<ContextType>;
  HistorySportResponse?: HistorySportResponseResolvers<ContextType>;
  HistoryStandResponse?: HistoryStandResponseResolvers<ContextType>;
  HistoryStepResponse?: HistoryStepResponseResolvers<ContextType>;
  HistoryUserServicesRecord?: HistoryUserServicesRecordResolvers<ContextType>;
  HistoryWalkingResponse?: HistoryWalkingResponseResolvers<ContextType>;
  HistoryWeightLossResponse?: HistoryWeightLossResponseResolvers<ContextType>;
  HomeData?: HomeDataResolvers<ContextType>;
  HomeResponse?: HomeResponseResolvers<ContextType>;
  ImportCSVEndUserError?: ImportCsvEndUserErrorResolvers<ContextType>;
  ImportCSVEndUserResponse?: ImportCsvEndUserResponseResolvers<ContextType>;
  ListCompanyMemberResponse?: ListCompanyMemberResponseResolvers<ContextType>;
  ListCompanyResponse?: ListCompanyResponseResolvers<ContextType>;
  ListDepartmentResponse?: ListDepartmentResponseResolvers<ContextType>;
  ListEventsResponse?: ListEventsResponseResolvers<ContextType>;
  ListMetTypeResponse?: ListMetTypeResponseResolvers<ContextType>;
  ListNotificationResponse?: ListNotificationResponseResolvers<ContextType>;
  ListPrefectureResponse?: ListPrefectureResponseResolvers<ContextType>;
  ListQuestionnaireResponse?: ListQuestionnaireResponseResolvers<ContextType>;
  ListServiceConfigResponse?: ListServiceConfigResponseResolvers<ContextType>;
  ListServiceResponse?: ListServiceResponseResolvers<ContextType>;
  ListServiceTargetResponse?: ListServiceTargetResponseResolvers<ContextType>;
  ListUserResponse?: ListUserResponseResolvers<ContextType>;
  MetTypeRecord?: MetTypeRecordResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  NotificationCompanies?: NotificationCompaniesResolvers<ContextType>;
  NotificationDetailResponse?: NotificationDetailResponseResolvers<ContextType>;
  NotificationResponse?: NotificationResponseResolvers<ContextType>;
  NotificationUsers?: NotificationUsersResolvers<ContextType>;
  PaginationPageInfo?: PaginationPageInfoResolvers<ContextType>;
  PrefectureResponse?: PrefectureResponseResolvers<ContextType>;
  PublishNotificationResponse?: PublishNotificationResponseResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  QuestionResponse?: QuestionResponseResolvers<ContextType>;
  QuestionnaireResponse?: QuestionnaireResponseResolvers<ContextType>;
  RateTotalSleepMode?: RateTotalSleepModeResolvers<ContextType>;
  RecordCalorie?: RecordCalorieResolvers<ContextType>;
  RecordExercise?: RecordExerciseResolvers<ContextType>;
  RecordFatigue?: RecordFatigueResolvers<ContextType>;
  RecordGraphSleep?: RecordGraphSleepResolvers<ContextType>;
  RecordHeartRate?: RecordHeartRateResolvers<ContextType>;
  RecordHistorySleep?: RecordHistorySleepResolvers<ContextType>;
  RecordPulseWave?: RecordPulseWaveResolvers<ContextType>;
  RecordSleepRange?: RecordSleepRangeResolvers<ContextType>;
  RecordStand?: RecordStandResolvers<ContextType>;
  RecordedAt?: RecordedAtResolvers<ContextType>;
  RecordedAtFromTo?: RecordedAtFromToResolvers<ContextType>;
  RefreshTokenResponse?: RefreshTokenResponseResolvers<ContextType>;
  RunningRecord?: RunningRecordResolvers<ContextType>;
  ServiceConfigResponse?: ServiceConfigResponseResolvers<ContextType>;
  ServiceCountResponse?: ServiceCountResponseResolvers<ContextType>;
  ServiceItemResponse?: ServiceItemResponseResolvers<ContextType>;
  ServiceItemTarget?: ServiceItemTargetResolvers<ContextType>;
  ServiceResponse?: ServiceResponseResolvers<ContextType>;
  ServiceTarget?: ServiceTargetResolvers<ContextType>;
  SignInResponse?: SignInResponseResolvers<ContextType>;
  SleepModeRate?: SleepModeRateResolvers<ContextType>;
  SleepModeReference?: SleepModeReferenceResolvers<ContextType>;
  SportRecord?: SportRecordResolvers<ContextType>;
  StampHistoryResponse?: StampHistoryResponseResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  SyncDataResponse?: SyncDataResponseResolvers<ContextType>;
  TopUsersResponse?: TopUsersResponseResolvers<ContextType>;
  TotalTimeBySleepMode?: TotalTimeBySleepModeResolvers<ContextType>;
  UserCompany?: UserCompanyResolvers<ContextType>;
  UserJoinedEvent?: UserJoinedEventResolvers<ContextType>;
  UserRankData?: UserRankDataResolvers<ContextType>;
  UserResponse?: UserResponseResolvers<ContextType>;
  UserServiceDetail?: UserServiceDetailResolvers<ContextType>;
  UserServiceItemDetail?: UserServiceItemDetailResolvers<ContextType>;
  UserServiceItemDetails?: UserServiceItemDetailsResolvers<ContextType>;
  UserServices?: UserServicesResolvers<ContextType>;
  UsersJoinedEventResponse?: UsersJoinedEventResponseResolvers<ContextType>;
  WalkingRecord?: WalkingRecordResolvers<ContextType>;
  WeightLossRecord?: WeightLossRecordResolvers<ContextType>;
};

export type DirectiveResolvers<ContextType = any> = {
  aws_api_key?: Aws_Api_KeyDirectiveResolver<any, any, ContextType>;
  aws_auth?: Aws_AuthDirectiveResolver<any, any, ContextType>;
  aws_cognito_user_pools?: Aws_Cognito_User_PoolsDirectiveResolver<
    any,
    any,
    ContextType
  >;
  aws_iam?: Aws_IamDirectiveResolver<any, any, ContextType>;
  aws_oidc?: Aws_OidcDirectiveResolver<any, any, ContextType>;
  aws_subscribe?: Aws_SubscribeDirectiveResolver<any, any, ContextType>;
};
